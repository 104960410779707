import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Page, QuotaHsubHmainOpDto, QuotaHsubHmainOpResponse} from "../../../../generated-model/model";
import {environment} from "../../../../environments/environment";
import {IgnoreNullHttpParams} from "../Ignore-null-http-params";

@Injectable({
  providedIn: 'root'
})
export class QuotaHsubHmainOpResource {
  http = inject(HttpClient)

  constructor() {
  }

  search(params: IgnoreNullHttpParams) {
    return this.http.get<Page<QuotaHsubHmainOpResponse>>(`${environment.serverUrl}/api/quota-hsub-hmainop/search`, {params: params.toHttpParam()})
  }

  save(quotaHsubHmainOp: QuotaHsubHmainOpDto) {
    return this.http.post(`${environment.serverUrl}/api/quota-hsub-hmainop/save`, quotaHsubHmainOp)
  }

  findById(id:number){
    return this.http.get<QuotaHsubHmainOpResponse>(`${environment.serverUrl}/api/quota-hsub-hmainop/${id}`)
  }
}
