import {Component} from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";
import {CatmDto, CatmSearchParam, Page} from "../../../../generated-model/model";
import {CatmModelComponent} from "./model/catm-model/catm-model.component";
import {CatmService} from "./catm.service";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";
import {from} from "rxjs";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-catm',
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    NgForOf,
    NgIf,
    NgbPagination,
    NotFoundComponent,
    ReactiveFormsModule
  ],
  templateUrl: './catm.component.html',
  styleUrl: './catm.component.scss'
})
export class CatmComponent {
  pageData: Page<CatmDto> = {size: 10};
  searchParam: CatmSearchParam;

  constructor(private modalService: NgbModal,
              private catmService: CatmService) {
    this.search();
  }

  search(page: number = 1) {
    let param = IgnoreNullHttpParams.fromObject(
      {...this.searchParam})
      .set("page", page - 1)
      .set("size", this.pageData.size)
    this.catmService.search(param).subscribe({
        next: e => {
          this.pageData = e
        }
      }
    )
  }

  openModelAdd(prefix: string) {
    const modalRef = this.modalService.open(CatmModelComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.prefix = prefix
  }

  openModelEdit(prefix: string, item: CatmDto) {
    const modalRef = this.modalService.open(CatmModelComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.catm = {...item}
    modalRef.componentInstance.prefix = prefix
    from(modalRef.result).pipe(filter(e => !!e))
      .subscribe(e => {
        this.search(1)
      });
  }
}
