<div class="modal-header ">
  <strong class="text-primary bold fs-5 d-inline-block">{{prefix}} ประเภทสิทธิย่อยตามช่วงอายุ</strong>
</div>
<form #form="ngForm" class="form-validate">
  <div class="modal-body">
    <div class="mb-3 row">
      <label for="inscl" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">INSCL</label>
      <div class="col-xl-3">
        <input type="text" id="inscl" name="inscl" class="form-control" [(ngModel)]="inscl.inscl" required #insclCode="ngModel">
        @if (insclCode.invalid && (insclCode.dirty || insclCode.touched)) {
          <custom-error *ngIf="insclCode.hasError('required')" error="*จำเป็น"></custom-error>
        }
      </div>
      <label for="insclName" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">INSCL_NAME</label>
      <div class="col-xl-3">
        <input type="text" id="insclName" name="insclName" class="form-control" [(ngModel)]="inscl.insclName" required #insclName="ngModel">
        @if (insclName.invalid && (insclName.dirty || insclName.touched)) {
          <custom-error *ngIf="insclName.hasError('required')" error="*จำเป็น"></custom-error>
        }
      </div>
    </div>
    <div class="mb-3 row">
      <label for="insclDateExp" class="col-xl-2 ps-xl-4 col-form-label  text-end">INSCL_DATEEXP</label>
      <div class="col-xl-3">
        <input type="text" id="insclDateExp" name="insclDateExp" class="form-control" [(ngModel)]="inscl.insclDateExp">
      </div>
      <label for="mainInscl" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">MAININSCL</label>
      <div class="col-xl-3">
        <input type="text" id="mainInscl" name="mainInscl" class="form-control" [(ngModel)]="inscl.mainInscl" required #mainInscl="ngModel">
        @if (mainInscl.invalid && (mainInscl.dirty || mainInscl.touched)) {
          <custom-error *ngIf="mainInscl.hasError('required')" error="*จำเป็น"></custom-error>
        }
      </div>
    </div>
    <div class="mb-3 row">
      <label for="status" class="col-xl-2 col-form-label text-end ui-require">สถานะ</label>
      <div class="col-xl-3">
        <div class="col-auto">
          <select class="form-select" id="status" name="status" [(ngModel)]="inscl.used" required #status="ngModel">
            <option selected [ngValue]="'Y'">ใช้งาน</option>
            <option [ngValue]="'N'">ยกเลิก</option>
          </select>
          @if (status.invalid && (status.dirty || status.touched)) {
            <custom-error *ngIf="status.hasError('required')" error="*จำเป็น"></custom-error>
          }
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3 mb-3">
    <div class="col-12 text-end">
      <button type="button" class="btn btn-primary custom-button mx-1 btn-main confirm-btn" (click)="save()">
        {{prefix}} INSCL
      </button>
      <button type="button" class="btn btn-default custom-button mx-1 btn-main confirm-btn" (click)="activeModal.close()">ปิด</button>

    </div>
  </div>
</form>

