<div class="container-fluid">
  <div class="row">
    <!--    @if (toggleSidebar()) {-->
    <div class="col-auto d-xl-block d-none p-0">
      <div id="sidebar">
        <div id="sidebar-menu" class="offcanvas-md offcanvas-end" tabindex="-1" aria-labelledby="sidebar-menu-label">
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="sidebar-menu-label">
              จัดการข้อมูลพื้นฐาน และข่าวประชาสัมพันธ์</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebar-menu"
                    aria-label="Close"></button>
          </div>
          <div class="offcanvas-body d-md-flex flex-column">
            <ul class="nav flex-column sticky-top bg-white">
              <li class="nav-item d-flex justify-content-center p-2">
                <a class="navbar-brand d-none d-md-flex">
                  <img src="/assets/images/nhso-minimize.webp" alt="Logo" class="">
                  <!--                  <div>สำนักงานหลักประกันสุขภาพแห่งชาติ</div>-->
                  <div>จัดการข้อมูลพื้นฐาน และข่าวประชาสัมพันธ์</div>
                </a>
              </li>
            </ul>
            <div id="sidebar-content">
              <ul class="nav flex-column mt-4">
                <li class="nav-item">
                  <a class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2"
                     href="{{environment.srmPortalUrl}}">
                    <i class="bi bi-grid"></i>
                    ไปยัง e-portal
                  </a>
                </li>
              </ul>
              <!--              <ul class="nav flex-column">
                              <li class="nav-item">
                                <a routerLink="landing"
                                   routerLinkActive="active"
                                   class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2">
                                  <i class="bi bi-house"></i>
                                  หน้าแรก
                                </a>
                              </li>
                            </ul>-->
              <ul class="nav flex-column">
                @if (isEnable('news')) {
                  <li class="nav-item">
                    <a routerLink="news" routerLinkActive="active"
                       class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
                      <i class="bi bi-megaphone"></i>
                      จัดการข่าวประชาสัมพันธ์
                    </a>
                  </li>
                }
                <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#master-data-collapse"
                    aria-expanded="true">
                  <a class="nav-link nav-link-collapse">
                    <i class="bi bi-database"></i>
                    <span>Master Data</span>
                    <i class="rtl:rotate-180 right-icon"
                       x-bind:style="selected.includes('menu_style') ? 'transform:  rotate(90deg)' : ''" style="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24"
                           stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                      </svg>
                    </i>
                  </a>
                </li>
                <div class="collapse show" id="master-data-collapse" style="">
                  @if (isEnable('main-inscl')) {
                    <li class="nav-item">
                      <a routerLink="main-inscl" routerLinkActive="active"
                         class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2">
                        ข้อมูลสิทธิหลัก (MAININSCL)
                      </a>
                    </li>
                  }
                  <!-- <li class="nav-item">
                     <a routerLink="inscl" routerLinkActive="active"
                        class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2">
                       ข้อมูลสิทธิย่อย (SUBINSCL)
                     </a>
                   </li>-->
                  @if (isEnable('range-sub-inscl-type')) {
                    <li class="nav-item">
                      <a routerLink="range-sub-inscl-type" routerLinkActive="active"
                         class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2">
                        ข้อมูลประเภทสิทธิย่อยตามช่วงอายุ
                      </a>
                    </li>
                  }
                  @if (isEnable('group-registration')) {
                    <li class="nav-item">
                      <a routerLink="group-registration" routerLinkActive="active"
                         class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2">
                        ข้อมูลหน่วยบริการที่ลงทะเบียนแบบกลุ่ม
                      </a>
                    </li>
                  }
                  @if (isEnable('limit-service')) {
                  <li class="nav-item">
                    <a routerLink="limit-service" routerLinkActive="active"
                       class="nav-link btn d-flex align-items-center align-middle border-0 gap-2">
                      ข้อมูลการแสดงเครือค่ายหน่วยบริการ
                    </a>
                  </li>
                  }
                  @if(isEnable('quota')){
                  <li class="nav-item">
                    <a routerLink="quota" routerLinkActive="active"
                       class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2">
                      ข้อมูลจำนวน Quota ผู้ลงทะเบียน
                    </a>
                  </li>
                  }
                  @if(isEnable('catm')){
                  <li class="nav-item">
                    <a routerLink="catm" routerLinkActive="active"
                       class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2">
                      ข้อมูล CATM
                    </a>
                  </li>
                  }
                  @if(isEnable('count-select')){
                  <li class="nav-item">
                    <a routerLink="count-select" routerLinkActive="active"
                       class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2">
                      ข้อมูลจำนวนครั้งในการลงทะเบียน
                    </a>
                  </li>
                  }
                  @if(isEnable('department-new')){
                  <li class="nav-item">
                    <a routerLink="department-new" routerLinkActive="active"
                       class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2">
                      ข้อมูลหน่วยงาน (DEPARTMENT_NEW)
                    </a>
                  </li>
                  }
                  @if(isEnable('map-inscl')){
                  <li class="nav-item">
                    <a routerLink="map-inscl" routerLinkActive="active"
                       class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2">
                      ข้อมูลการ MAP สิทธิย่อย
                    </a>
                  </li>
                  }
                  @if(isEnable('relation')){
                  <li class="nav-item">
                    <a routerLink="relation" routerLinkActive="active"
                       class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2 ">
                      <small>ข้อมูลความสัมพันธ์</small>
                    </a>
                  </li>
                  }
                  @if(isEnable('quota-per-day')){
                  <li class="nav-item">
                    <a routerLink="quota-per-day" routerLinkActive="active"
                       class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2 ">
                      <small>ข้อมูลจำนวน Quota การตรวจสอบสิทธิต่อวัน</small>
                    </a>
                  </li>
                  }
                  @if(isEnable('request-management')){
                  <li class="nav-item">
                    <a routerLink="request-management" routerLinkActive="active"
                       class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2 ">
                      <small>ข้อมูลหน่วยรับคำขอ</small>
                    </a>
                  </li>
                  }
                  @if(isEnable('unregister-mastercup')){
                  <li class="nav-item">
                    <a routerLink="unregister-mastercup" routerLinkActive="active"
                       class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2 ">
                      <small>ข้อมูลเครือข่ายที่ไม่อนุญาตให้ลงทะเบียน</small>
                    </a>
                  </li>
                  }
                  @if(isEnable('email-notification-quota')){
                    <li class="nav-item">
                      <a routerLink="email-notification-quota" routerLinkActive="active"
                         class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2 ">
                        <small>ตั้งค่าอีเมลแจ้งเตือน Quota การตรวจสอบสิทธิ</small>
                      </a>
                    </li>
                  }
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    }-->
    <div class="col overflow-y-auto p-0">
      <header id="header">
        <nav class="navbar navbar-expand-lg">
          <div class="container-fluid">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
              <i class="bi bi-layout-sidebar fs-3"></i>
            </button>
            <button class="navbar-toggler d-lg-block border-0" type="button" (click)="sidebarToggle()">
              <i class="bi bi-layout-sidebar text-white fs-3"></i>
            </button>
            <div class="collapse navbar-collapse mx-3" id="navbarSupportedContent">
              <ul id="select-font-size" class="navbar-nav me-auto mb-lg-0">
                <li class="nav-item me-1" (click)="setFontSizeTo('14px')">
                  <input type="radio" class="btn-check" name="options" id="fs14px" [value]="'14px'"
                         [(ngModel)]="selectedFontSize" autocomplete="off">
                  <label class="btn btn-outline-light py-0" for="fs14px">ก</label>
                </li>
                <li class="nav-item me-1" (click)="setFontSizeTo('16px')">
                  <input type="radio" class="btn-check" name="options" id="fs16px" [value]="'16px'"
                         [(ngModel)]="selectedFontSize" autocomplete="off">
                  <label class="btn btn-outline-light py-0" for="fs16px">ก+</label>
                </li>
                <li class="nav-item me-1" (click)="setFontSizeTo('18px')">
                  <input type="radio" class="btn-check" name="options" id="fs18px" [value]="'18px'"
                         [(ngModel)]="selectedFontSize" autocomplete="off">
                  <label class="btn btn-outline-light py-0" for="fs18px">ก++</label>
                </li>
              </ul>
              <div class="dropdown d-flex m-2">
                <div class="text-end d-block text-decoration-none me-3">
                  {{ principal?.name }} <br>
                  <small style="font-size: 0.8rem">{{ principal?.organization?.name }}</small>
                </div>
                <div class="flex-shrink-0 dropdown">
                  <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="bi-person-circle fs-2 text-white"></i>
                  </a>
                  <ul class="dropdown-menu text-small" style="left :-95px">
                    <li><a class="dropdown-item"><i class="bi bi-person me-2"></i>โปรไฟล์</a></li>
                    <li><a (click)="logout()" class="dropdown-item"><i class="bi bi-box-arrow-in-right me-2"></i>ออกจากระบบ</a>
                    </li>
                  </ul>
                </div>
              </div>

              <ul class="navbar-nav flex-row d-md-none">
                <li class="nav-item text-nowrap">
                  <button class="nav-link px-3"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#sidebar-menu" aria-controls="sidebar-menu" aria-expanded="false"
                          aria-label="Toggle navigation">
                    <span class="bi bi-list"></span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <div id="content">
        <div class="ps-3 pt-3 pe-3 pb-0">
          <app-loading></app-loading>
          <app-header></app-header>
          <div class="min-vh-80">
            <router-outlet/>
          </div>
          <div class="container-fluid">
            <!--<footer class="py-3 my-4">
              <ul class="nav justify-content-center border-bottom pb-3 mb-3">
                <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">Footer</a></li>
              </ul>
              <p class="text-center text-muted">© 2022 Company, Inc</p>
            </footer>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

