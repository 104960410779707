import {Component} from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";
import {Page} from "../../../../generated-model/model";

@Component({
  selector: 'app-quota-per-day',
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    NgForOf,
    NgIf,
    NgbPagination,
    NotFoundComponent
  ],
  templateUrl: './quota-per-day.component.html',
  styleUrl: './quota-per-day.component.scss'
})
export class QuotaPerDayComponent {
  pageData: Page<any> = {size: 10};

  constructor(private modalService: NgbModal) {
    this.search();
  }

  search(page: number = 1) {
  }

}
