import {Component, ViewEncapsulation} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {RightDto} from "../../../../../../generated-model/model";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmDialogService} from "../../../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {ToastService} from "../../../../../shared/commons/toast/toast-service";
import {catchError, filter, switchMap} from "rxjs/operators";
import {DigitOnlyModule} from "@uiowa/digit-only";
import {MainInsclService} from "../../main-inscl.service";
import {HttpErrorResponse} from "@angular/common/http";
import {throwError} from "rxjs";

@Component({
  selector: 'app-main-inscl-modal',
  standalone: true,
  imports: [
    FormsModule,
    DigitOnlyModule
  ],
  templateUrl: './main-inscl-modal.component.html',
  styleUrl: './main-inscl-modal.component.scss',
  encapsulation:ViewEncapsulation.None
})
export class MainInsclModalComponent {
  prefix = ''
  right: RightDto = {used:'Y'}
  errors: {[key: string]: string} = {};

  constructor(public activeModal: NgbActiveModal,
              private confirmDialogService: ConfirmDialogService,
              private mainInsclService: MainInsclService,
              private toastService: ToastService,
  ) {

  }

  validate(): boolean {
    this.errors = {};

    if (!this.right.rightId || this.right.rightId.length !== 3) {
      this.errors['rightId'] = 'กรุณาระบุรหัสสิทธิ 3 ตัวอักษร';
    }
    if (!this.right.rightName) {
      this.errors['rightName'] = 'กรุณาระบุชื่อสิทธิ';
    }
    if (!this.right.rightMain) {
      this.errors['rightMain'] = 'กรุณาระบุ RIGHT_MAIN';
    }

    return Object.keys(this.errors).length === 0;
  }

  save() {
    if (!this.validate()) {
      return;
    }
    this.confirmDialogService.open({content: 'ยืนยันการบันทึกข้อมูล'})
      .pipe(
        filter(e => !!e),
        switchMap(() => this.mainInsclService.save(this.right)),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 409) {
            this.toastService.showDangerWithMessage(error.error || 'รหัสสิทธินี้มีอยู่ในระบบแล้ว');
          } else {
            this.toastService.showDangerWithMessage('เกิดข้อผิดพลาดในการบันทึกข้อมูล');
          }
          return throwError(() => error);
        })
      ).subscribe({
      next: e => {
        this.activeModal.close(e);
        this.toastService.showSuccess(this.prefix + 'ข้อมูลสำเร็จ');
      }
    });
  }
}
