import {Component, inject, model, signal} from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";
import {
  McupFollowMotherRevokeDto,
  McupFollowMotherRevokeSearchParam,
  Page,
  Program,
  ProvinceDto,
  ZoneDto
} from "../../../../generated-model/model";
import {from} from "rxjs";
import {filter} from "rxjs/operators";
import {
  UnregisterMastercupModalComponent
} from "./modal/unregister-mastercup-modal/unregister-mastercup-modal.component";
import {SelectZoneComponent} from "../../../shared/components/select-zone/select-zone.component";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";
import {McupFollowMotherRevokeResourceService} from "./mcup-follow-mother-revoke-resource.service";
import {ToastService} from "../../../shared/commons/toast/toast-service";
import {ConfirmDialogService} from "../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {
  ProvinceAutocompleteComponent
} from "../../../shared/components/province-autocomplete/province-autocomplete.component";
import {HsubAutocompleteComponent} from "../../../shared/components/hsub-autocomponent/hsub-autocomplete.component";
import {
  HmainOpAutocompleteComponent
} from "../../../shared/components/hmain-op-autocomplete/hmain-op-autocomplete.component";
import {SelectMastercupIdComponent} from "../../../shared/components/select-mastercup-id/select-mastercup-id.component";

@Component({
  selector: 'app-unregister-mastercup',
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    NgForOf,
    NgIf,
    NgbPagination,
    NotFoundComponent,
    ReactiveFormsModule,
    SelectZoneComponent,
    ProvinceAutocompleteComponent,
    HsubAutocompleteComponent,
    HmainOpAutocompleteComponent,
    SelectMastercupIdComponent
  ],
  templateUrl: './unregister-mastercup.component.html',
  styleUrl: './unregister-mastercup.component.scss'
})
export class UnregisterMastercupComponent {
  pageData = signal<Page<McupFollowMotherRevokeDto>>({size: 10});
  param: McupFollowMotherRevokeSearchParam = {}
  selectedZone = model<ZoneDto>(undefined);
  selectedProvince = model<ProvinceDto>(undefined);
  mcupFollowMotherRevokeDto = signal({} as McupFollowMotherRevokeDto)

  mcupFollowMotherRevokeService = inject(McupFollowMotherRevokeResourceService)
  toastService = inject(ToastService)
  confirmDialogService = inject(ConfirmDialogService)

  constructor(private modalService: NgbModal) {
    this.search();
  }

  search(page: number = 1) {
    let param = IgnoreNullHttpParams.fromObject(
      {...this.param})
      .set("page", page - 1)
      .set("size", this.pageData().size)
      .set('sort', 'mastercupSeq')
    this.mcupFollowMotherRevokeService.search(param).subscribe(data => {
      this.pageData.set(data)
    })
  }

  openModelAdd(prefix: string) {
    const modalRef = this.modalService.open(UnregisterMastercupModalComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.prefix = prefix
    from(modalRef.result).pipe(filter(e => !!e))
      .subscribe(e => {
        console.log(e)
        if (e.id){
          this.mcupFollowMotherRevokeDto().mastercupSeq = e.id
          this.mcupFollowMotherRevokeDto().active = true
          this.mcupFollowMotherRevokeService.save(this.mcupFollowMotherRevokeDto()).subscribe(
            next => {
              this.toastService.showSuccess('เพิ่มข้อมูลสำเร็จ')
              this.search(1)
            }
          )
        }
      });
  }

  clear() {
    this.param = {}
    this.search(1)
  }

  cancel(item : McupFollowMotherRevokeDto) {
    this.confirmDialogService.open({content: 'ยืนยันการยกเลิกหน่วยบริการรหัส ' + item.mastercupSeq})
      .pipe(filter(e => !!e))
      .subscribe({
        next: e => {
          this.mcupFollowMotherRevokeService.cancel(item.mastercupSeq).subscribe(
            next => {
              this.toastService.showSuccess('ยกเลิกสำเร็จ')
              this.search(1)
            }
          )
        }
      });
  }
}
