import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {IgnoreNullHttpParams} from "../Ignore-null-http-params";

@Injectable({
  providedIn: 'root'
})
export class PersonalFundResource {
  http = inject(HttpClient)

  search(personalId: string) {
    return this.http.get(`${environment.serverUrl}/api/personal-fund/search/${personalId}`)
  }

  update(fundId: number, countSelect: number) {
    return this.http.post(`${environment.serverUrl}/api/personal-fund/update/count-select`, IgnoreNullHttpParams.fromObject({
      id: fundId,
      countSelect: countSelect
    }).toHttpParam())
  }
}
