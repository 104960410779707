import {Component, inject, OnInit, signal, ViewChild} from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";
import {MapInscl, MapInsclSearchParam, Page, RightDto} from "../../../../generated-model/model";
import {MapInsclModalComponent} from "./modal/map-inscl-model/map-inscl-modal.component";
import {MapInsclService} from "./map-inscl.service";
import {InsclAutocompleteComponent} from "../../../shared/components/inscl-autocomplete/inscl-autocomplete.component";
import {from} from "rxjs";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-map-inscl',
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    NgForOf,
    NgIf,
    NgbPagination,
    NotFoundComponent,
    ReactiveFormsModule,
    InsclAutocompleteComponent
  ],
  templateUrl: './map-inscl.component.html',
  styleUrl: './map-inscl.component.scss'
})
export class MapInsclComponent implements OnInit{

  pageData: Page<MapInscl> = {number:0 ,size: 10};
  mapInsclsearchParam=signal<MapInsclSearchParam>({})
  mapInsclService = inject(MapInsclService);
  modalService = inject(NgbModal);

  @ViewChild("mapInscl") mapInsclInput:InsclAutocompleteComponent
  @ViewChild("curInscl") curInsclInput:InsclAutocompleteComponent
  @ViewChild("ofcInscl") ofcInsclInput:InsclAutocompleteComponent


  ngOnInit(): void {
   this.search();
  }

  search(page: number = 1) {
    const pageRequest = {page:page-1,size:this.pageData.size}
    this.mapInsclService.search(this.mapInsclsearchParam(),pageRequest).subscribe({
      next: value => {
        this.pageData = value;
      }
    })
  }

  clear(){
    this.mapInsclsearchParam.set({})
    this.mapInsclInput.reset()
    this.curInsclInput.reset()
    this.ofcInsclInput.reset()
    this.search(1)
  }

  openModelAdd(prefix: string) {
    const modalRef = this.modalService.open(MapInsclModalComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.prefix = prefix
  }


  openModelEdit(prefix: string, item: MapInscl) {
    const modalRef = this.modalService.open(MapInsclModalComponent, {
      windowClass: 'set-width-modal',
    });
    console.log("mapInscl",item)
    modalRef.componentInstance.setMapInscl(item)
    modalRef.componentInstance.prefix = prefix
    from(modalRef.result).pipe(filter(e => !!e))
      .subscribe(e => {
        this.search(1)
      });
  }

}
