<div class="card custom-box mb-3 border-0">
  <div class="card-body">
    <div class="row mb-3">
      <label class="col-xl-2 col-form-label ">เขต</label>
      <div class="col-xl-3">
        <select class="form-control col-lg-5">
          <option [ngValue]="undefined">ทั้งหมด</option>
        </select>
      </div>
      <label class="col-xl-2 col-form-label ">จังหวัด</label>
      <div class="col-xl-3">
        <select class="form-control col-lg-5">
          <option [ngValue]="undefined">ทั้งหมด</option>
        </select>
      </div>
    </div>
    <div class="row my-3">
      <label class="col-xl-2 col-form-label ">หน่วยบริการประจำ</label>
      <div class="col-xl-3">
        <input type="text" class="form-control" placeholder="พิมพ์เพื่อค้นหา">
      </div>
      <label class="col-xl-2 col-form-label ">หน่วยบริการปฐมภูมิ</label>
      <div class="col-xl-3">
        <input type="text" class="form-control" placeholder="พิมพ์เพื่อค้นหา">
      </div>
    </div>
    <div class="row my-3">
      <label class="col-xl-2 col-form-label ">รหัสเครือข่ายหน่วยบริการ</label>
      <div class="col-xl-3">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row">
      <div class="text-center">
        <button type="submit" class="btn btn-primary me-2">
          <i class="bi bi-search me-1"></i>
          ค้นหา
        </button>
        <button type="reset" class="btn btn-default me-2">
          เคลียร์ข้อมูล
        </button>
      </div>
    </div>
  </div>
</div>
<div class="card custom-box mb-3 border-0">
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-striped table-borderless caption-top">
        <caption>
          แสดง {{ (pageData?.content?.length ? (pageData?.number * pageData?.size) + 1 : 0) | number }}
          ถึง {{ ((pageData?.number) * pageData?.size + pageData?.content?.length) | number }}
          จากทั้งหมด {{ pageData?.totalElements | number }} รายการ
        </caption>
        <thead class="color-black font-weight-light">
        <tr class="table-light text-center" style="background-color: #eeeeee;">
          <th nowrap scope="col" rowspan="5">#</th>
          <th nowrap scope="col" rowspan="5" class="">รหัสเครือข่ายหน่วยบริการ</th>
          <th nowrap scope="col" colspan="5">CATM</th>
          <th nowrap scope="col" colspan="2">หน่วยบริการปฐมภูมิ (HSUB)</th>
          <th nowrap scope="col" colspan="2">หน่วยบริการประจำ (HMAIN_OP)</th>
          <th nowrap scope="col" colspan="2">หน่วยบริการที่รับส่งต่อ (HMAIN)</th>
          <th nowrap scope="col" rowspan="2">ดำเนินการ</th>
        </tr>
        <tr class="color-black text-center">
          <th nowrap scope="col" class="">รหัส</th>
          <th nowrap scope="col" class="">จังหวัด</th>
          <th nowrap scope="col" class="">อำเภอ</th>
          <th nowrap scope="col" class="">ตำบล</th>
          <th nowrap scope="col" class="">หมู</th>
          <th nowrap scope="col" class="">รหัส</th>
          <th nowrap scope="col" class="">ชื่อ</th>
          <th nowrap scope="col" class="">รหัส</th>
          <th nowrap scope="col" class="">ชื่อ</th>
          <th nowrap scope="col" class="">รหัส</th>
          <th nowrap scope="col" class="">ชื่อ</th>
        </tr>

        </thead>
        <tbody class="table-borderless">
        <tr>
          <td class="text-muted text-center" colspan="14">
            <app-not-found></app-not-found>
          </td>
        </tr>
        <!--        <tr>-->
        <!--          <td class="text-center"></td>-->
        <!--          <td class=""></td>-->
        <!--          <td class=""></td>-->
        <!--          <td class=""></td>-->
        <!--          <td class=""></td>-->
        <!--          <td class=""></td>-->
        <!--          <td class=""></td>-->
        <!--          <td class=""></td>-->
        <!--          <td class=""></td>-->
        <!--          <td class=""></td>-->
        <!--          <td class=""></td>-->
        <!--          <td class=""></td>-->
        <!--          <td class=""></td>-->
        <!--          <td class=""></td>-->
        <!--        </tr>-->
        </tbody>
      </table>
    </div>
    <ngb-pagination class="d-flex justify-content-center"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    [page]="pageData?.number + 1"
                    (pageChange)="search($event)"
                    [pageSize]="pageData?.size"
                    [collectionSize]="pageData?.totalElements"></ngb-pagination>
  </div>
</div>
