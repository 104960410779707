<div class="modal-header">
  <strong class="text-primary">จัดการข้อมูลจำนวนครั้งในการลงทะเบียน (Count Select)</strong>
</div>
<form class="form-validate" #form=ngForm (ngSubmit)="save()">
  <div class="modal-body">
    <div class="container">
      <div class="row mt-3">
        <label for="personalId" class="col-form-label col-xl-4">เลขประจำตัวประชาชน</label>
        <div class="col-xl-7">
          <input type="text" class="form-control" id="personalId" name="personalId"
                 [ngModel]="item.personalData?.personalId" formDisabled>
        </div>
      </div>
      <div class="row mt-3">
        <label for="fullName" class="col-form-label col-xl-4">ชื่อ-สกุล</label>
        <div class="col-xl-7">
          <input type="text" class="form-control" id="fullName" name="fullName"
                 ngModel="{{item.personalData?.firstName}} {{item.personalData?.lastName}}"
                 formDisabled>
        </div>
      </div>
      <div class="row mt-3">
        <label for="countSelect" class="col-form-label col-xl-4 ui-require">จำนวนครั้งในการลงทะเบียนต่อปี</label>
        <div class="col-xl-7">
          <input type="text" class="form-control" id="countSelect" name="countSelect" [(ngModel)]="item.countSelect"
                 required digitOnly #countSelect=ngModel>
          @if (countSelect.invalid && (countSelect.dirty || countSelect.touched)) {
            <custom-error *ngIf="countSelect.hasError('required')" error="*จำเป็น"></custom-error>
          }
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-end">
          <button type="submit" class="btn btn-primary">บันทึก</button>
          <button type="button" class="btn btn-default custom-button mx-1 btn-main confirm-btn"
                  (click)="activeModal.close()">ปิด
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

