import { Component } from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";
import {Page} from "../../../../generated-model/model";
import {QuotaHsubHmainopModalComponent} from "./modal/quota-hsub-hmainop-modal/quota-hsub-hmainop-modal.component";

@Component({
  selector: 'app-quota-hsub-hmainop',
  standalone: true,
    imports: [
        DecimalPipe,
        FormsModule,
        NgForOf,
        NgIf,
        NgbPagination,
        NotFoundComponent,
        ReactiveFormsModule
    ],
  templateUrl: './quota-hsub-hmainop.component.html',
  styleUrl: './quota-hsub-hmainop.component.scss'
})
export class QuotaHsubHmainopComponent {
  pageData: Page<any> = {size: 10};

  constructor(private modalService: NgbModal) {
    this.search();
  }
  search(page: number = 1) {
  }

  openModelAdd(prefix: string) {
    const modalRef = this.modalService.open(QuotaHsubHmainopModalComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.prefix = prefix
  }

}
