import {Component, inject, signal} from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {
  EmailNotificationQuotaModalComponent
} from "./email-notification-quota-modal/email-notification-quota-modal.component";
import {EmailNotificationQuotaResource} from "./email-notification-quota-resource";
import {EmailNotificationQuotaDto, Page} from "../../../../generated-model/model";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";
import {from} from "rxjs";
import {filter} from "rxjs/operators";
import {ConfirmDialogService} from "../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {ToastService} from "../../../shared/commons/toast/toast-service";

@Component({
  selector: 'app-email-notification-quota',
  standalone: true,
  imports: [
    DecimalPipe,
    NgForOf,
    NgIf,
    NotFoundComponent,
    NgbPagination
  ],
  templateUrl: './email-notification-quota.component.html',
  styleUrl: './email-notification-quota.component.scss'
})
export class EmailNotificationQuotaComponent {
  searchParam:  { page?: number }
  pageData: Page<EmailNotificationQuotaDto> = {size: 10};

  modalService = inject(NgbModal)
  emailNotificationQuota = inject(EmailNotificationQuotaResource)
  confirmDialog = inject(ConfirmDialogService)
  toastService = inject(ToastService)


  constructor() {
    this.search()
  }

  search(page: number = 1) {
    let param = IgnoreNullHttpParams.fromObject(
      {...this.searchParam})
      .set("page", page - 1)
      .set("size", this.pageData.size)
    this.emailNotificationQuota.search(param).subscribe({
      next: e => {
        this.pageData = e
      }
    })
  }
  openModelAdd(prefix: string) {
    const modalRef = this.modalService.open(EmailNotificationQuotaModalComponent, {
      windowClass: 'set-width-modal',
      size: "lg",
    });
    modalRef.componentInstance.prefix = prefix
    from(modalRef.result).pipe(filter(e => !!e))
      .subscribe(e => {
        this.search(1)
      });
  }

  openModelEdit(prefix: string, item: any) {
    const modalRef = this.modalService.open(EmailNotificationQuotaModalComponent, {
      windowClass: 'set-width-modal',
      size: "lg",
    });
    modalRef.componentInstance.prefix = prefix
    modalRef.componentInstance.item = signal({...item})
    from(modalRef.result).pipe(filter(e => !!e))
      .subscribe(e => {
        this.search(1)
      });
  }

  delete(id:number) {
    this.confirmDialog.open({content: 'ยืนยันการลบข้อมูล'})
      .pipe(filter(e => !!e))
      .subscribe({
        next: e => {
          this.emailNotificationQuota.delete(id).subscribe(
            next => {
              this.toastService.showSuccess('ยกเลิกสำเร็จ')
              this.search(1)
            }
          )
        }
      });
  }
}
