import {Component, inject, signal} from '@angular/core';
import {DepartmentNewDto, DepartmentNewSearchParam, Page} from "../../../../generated-model/model";
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";
import {DepartmentNewModalComponent} from "./modal/department-new-modal/department-new-modal.component";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";
import {from} from "rxjs";
import {filter} from "rxjs/operators";
import {DepartmentNewResource} from "../../../shared/commons/services/DepartmentNewResource";
import {ToastService} from "../../../shared/commons/toast/toast-service";

@Component({
  selector: 'app-department-new',
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    NgForOf,
    NgIf,
    NgbPagination,
    NotFoundComponent,
    ReactiveFormsModule
  ],
  templateUrl: './department-new.component.html',
  styleUrl: './department-new.component.scss'
})
export class DepartmentNewComponent {
  pageData = signal<Page<DepartmentNewDto>>({size: 10});
  param = signal({} as DepartmentNewSearchParam)

  departmentNewResource = inject(DepartmentNewResource)
  toastService = inject(ToastService)

  constructor(private modalService: NgbModal) {
    this.search();
  }

  search(page: number = 1) {
    let param = IgnoreNullHttpParams.fromObject(
      {...this.param()})
      .set("page", page - 1)
      .set("size", this.pageData().size)
      .set("sort", "id,desc")
    this.departmentNewResource.search(param).subscribe(
      data => {
        this.pageData.set(data)
      }
    )
  }

  openModelAddOrEdit(prefix: string, item: DepartmentNewDto) {
    const modalRef = this.modalService.open(DepartmentNewModalComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.prefix = prefix
    modalRef.componentInstance.item.set(item)
    from(modalRef.result).pipe(filter(e => !!e))
      .subscribe(e => {
        if (e) {
          this.departmentNewResource.save(e).subscribe(
            next => {
              this.toastService.showSuccess(prefix + 'ข้อมูลสำเร็จ')
              this.search()
            }
          )
        }
      });
  }

  clear() {
    this.param.set({})
    this.search()
  }

}
