import {Component, Injectable, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmDialogService} from "../../../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {ToastService} from "../../../../../shared/commons/toast/toast-service";
import {InsclDto} from "../../../../../../generated-model/model";
import {InsclResource} from "../../inscl-resource.service";
import {filter} from "rxjs/operators";
import {FormsModule} from "@angular/forms";

@Injectable({providedIn: 'root'})
@Component({
    selector: 'app-range-sub-inscl-type-modal',
    standalone: true,
    imports: [
        FormsModule
    ],
    templateUrl: './range-sub-inscl-type-modal.component.html',
    styleUrl: './range-sub-inscl-type-modal.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class RangeSubInsclTypeModalComponent {
    prefix = ''
    inscl: InsclDto = {}

    constructor(public activeModal: NgbActiveModal,
                private confirmDialogService: ConfirmDialogService,
                private insclTypeService: InsclResource,
                private toastService: ToastService,
    ) {

    }

    save() {
        this.confirmDialogService.open({content: 'ยืนยันการบันทึกข้อมูล'})
            .pipe(filter(e => !!e))
            .subscribe({
                next: e => {
                    this.insclTypeService.save(this.inscl).subscribe({
                        next: e => {
                            this.activeModal.close(e)
                            this.toastService.showSuccess(this.prefix + 'ข้อมูลสำเร็จ')
                        }
                    })
                }
            });
    }
}
