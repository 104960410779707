<ng-select [id]="id()"
           [name]="name()"
           [items]="provinceList$ | async"
           placeholder="กรุณาเลือก"
           class="novalidate"
           [disabled]="disabled()"
           [clearable]="true"
           [compareWith]="compareWithCode"
           [loading]="typeaheadLoading"
           (ngModelChange)="valueChange($event)"
           bindLabel="name"
           [(ngModel)]="value">
</ng-select>
