import {Component, inject, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {Router, RouterModule} from "@angular/router";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {SharedCommonModule} from "./shared/commons/shared-common.module";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SharedCommonModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'web';
  public oidcSecurityService: OidcSecurityService = inject(OidcSecurityService);
  public router: Router = inject(Router);

  constructor() {

  }

  ngOnInit() {
    this.oidcSecurityService.checkAuth().subscribe(e => {
      //Should go any other place let ng oidc navigate for you
      console.log(e)
    });
  }
}
