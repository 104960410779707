<div class="modal-header">
  <strong class="text-primary bold fs-5 d-inline-block">{{ prefix }} กำหนดจำนวน Quota
    ผู้ลงทะเบียนแยกรายหน่วยบริการปฐมภูมิ ( HSUB ) ที่อยู่กับหน่วยบริาการประจำ ( HMAIN_OP ) คนละรหัส</strong>
</div>
<form class="form-validate" #form="ngForm">
  @if (mode == 'UPDATE') {
    <div class="modal-body">
      <div class="row mb-3">
        <label for="zone1" class="col-xl-2 col-form-label text-end">เขต</label>
        <div class="col-xl-3">
          <span id="zone1" class="form-control-plaintext">{{response().hsub?.province?.zone?.name}}</span>
        </div>
        <label for="province1" class="col-xl-2 col-form-label ui-require text-end">จังหวัด</label>
        <div class="col-xl-3">
          <span id="province1" class="form-control-plaintext">{{response().hsub?.province?.name}}</span>
        </div>
      </div>
      <div class="row mb-3">
        <label for="hmainOp1" class="col-xl-2 col-form-label ui-require text-end">หน่วยบริการประจำ</label>
        <div class="col-xl-3">
          <span  id="hmainOp1" class="form-control-plaintext">{{response().hmainOp?.hcode}} {{response().hmainOp?.htitleHtitleName}}{{response().hmainOp?.hname}}</span>
        </div>
        <label for="hsub1" class="col-xl-2 col-form-label ui-require text-end">หน่วยบริการปฐมภูมิ</label>
        <div class="col-xl-3">
          <span id="hsub1" class="form-control-plaintext">{{response().hsub?.hcode}}{{response().hsub?.htitleHtitleName}}{{response().hsub?.hname}}</span>
        </div>
      </div>
      <div class="row mb-3">
        <label for="maxQuota1" class="col-xl-2 col-form-label ui-require text-end">จำนวนสิทธิ์ที่ลงทะเบียนสูงสุด</label>
        <div class="col-xl-3">
          <input type="text" class="form-control" id="maxQuota1" name="maxQuota"
                 [(ngModel)]="response().maxQuota" digitOnly
                 required #maxQuota=ngModel>
          @if (maxQuota.invalid && (maxQuota.dirty || maxQuota.touched)) {
            <custom-error *ngIf="maxQuota.hasError('required')" error="*จำเป็น"></custom-error>
          }
        </div>
      </div>
    </div>
  } @else if (mode == 'CREATE') {
    <div class="modal-body">
      <div class="row mb-3">
        <label for="zone" class="col-xl-2 col-form-label ui-require text-end">เขต</label>
        <div class="col-xl-3">
          <app-select-zone id="zone" name="zone" [(ngModel)]="params.zone" #zone=ngModel required></app-select-zone>
          @if (zone.invalid && (zone.dirty || zone.touched)) {
            <custom-error *ngIf="zone.hasError('required')" error="*จำเป็น"></custom-error>
          }
        </div>
        <label for="province" class="col-xl-2 col-form-label ui-require text-end">จังหวัด</label>
        <div class="col-xl-3">
          <app-province-autocomplete id="province" name="province" [(ngModel)]="params.province"
                                     [restrictZone]="params.zone" #province=ngModel
                                     required></app-province-autocomplete>
          @if (province.invalid && (province.dirty || province.touched)) {
            <custom-error *ngIf="province.hasError('required')" error="*จำเป็น"></custom-error>
          }
        </div>
      </div>
      <div class="row mb-3">
        <label for="hmainOp" class="col-xl-2 col-form-label ui-require text-end">หน่วยบริการประจำ</label>
        <div class="col-xl-3">
          <app-hmain-op-autocomplete id="hmainOp" name="hmainOp" [(ngModel)]="params.hmainOp"
                                     [restrictZone]="params.zone"
                                     [restrictProvince]="params.province" #hmainOp=ngModel
                                     required></app-hmain-op-autocomplete>
          @if (hmainOp.invalid && (hmainOp.dirty || hmainOp.touched)) {
            <custom-error *ngIf="hmainOp.hasError('required')" error="*จำเป็น"></custom-error>
          }
        </div>
        <label for="hsub" class="col-xl-2 col-form-label ui-require text-end">หน่วยบริการปฐมภูมิ</label>
        <div class="col-xl-3">
          <app-hsub-autocomponent id="hsub" name="hsub" [(ngModel)]="params.hsub" [restrictZone]="params.zone"
                                  [restrictProvince]="params.province"
                                  [restrictHmainOp]="params.hmainOp" #hsub=ngModel required></app-hsub-autocomponent>
          @if (hsub.invalid && (hsub.dirty || hsub.touched)) {
            <custom-error *ngIf="hsub.hasError('required')" error="*จำเป็น"></custom-error>
          }
        </div>
      </div>
      <div class="row mb-3">
        <label for="maxQuota" class="col-xl-2 col-form-label ui-require text-end">จำนวนสิทธิ์ที่ลงทะเบียนสูงสุด</label>
        <div class="col-xl-3">
          <input type="text" class="form-control" id="maxQuota" name="maxQuota" [(ngModel)]="params.maxQuota" digitOnly
                 required #maxQuota=ngModel>
          @if (maxQuota.invalid && (maxQuota.dirty || maxQuota.touched)) {
            <custom-error *ngIf="maxQuota.hasError('required')" error="*จำเป็น"></custom-error>
          }
        </div>
      </div>
    </div>
  }
  <div class="row mt-3 mb-3">
    <div class="col-12 text-end">
      <button type="button" class="btn btn-primary custom-button mx-1 btn-main confirm-btn" (click)="save()">
        {{ prefix }}
      </button>
      <button type="button" class="btn btn-default custom-button mx-1 btn-main confirm-btn"
              (click)="activeModal.close()">
        ปิด
      </button>
    </div>
  </div>
</form>
