import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {OidcSecurityService} from "angular-auth-oidc-client";

@Injectable({
  providedIn: 'root',
})
export class AuthenService {

  private http = inject(HttpClient);

  oidcSecurityService: OidcSecurityService = inject(OidcSecurityService);

  constructor() {
  }

  isLoggedIn$(): Observable<boolean> {
    // return this.user$.pipe(map(user => !!user));
    return this.oidcSecurityService
      .isAuthenticated$
      .pipe(map(value => value.isAuthenticated));
  }

  user$(): Observable<any> {
    return this.oidcSecurityService
      .userData$
      .pipe(map(({userData}) => userData));
  }

  login() {
    this.oidcSecurityService.authorize();
  }

  logout() {
    this.oidcSecurityService.logoff()
      .subscribe((result) => console.log(result));

  }
}
