import {Component, inject, model, signal} from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";
import {CatmDto, CatmSearchParam, District, Page, Province, SubDistrict} from "../../../../generated-model/model";
import {CatmModelComponent} from "./model/catm-model/catm-model.component";
import {CatmService} from "./catm.service";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";
import {from} from "rxjs";
import {filter} from "rxjs/operators";
import {
  ProvinceAutocompleteComponent
} from "../../../shared/components/province-autocomplete/province-autocomplete.component";
import {
  SelectMastercupDistrictComponent
} from "../../../shared/components/select-mastercup-district/select-mastercup-district.component";
import {
  SelectMastercupSubdistrictComponent
} from "../../../shared/components/select-mastercup-subdistrict/select-mastercup-subdistrict.component";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-catm',
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    NgForOf,
    NgIf,
    NgbPagination,
    NotFoundComponent,
    ReactiveFormsModule,
    ProvinceAutocompleteComponent,
    SelectMastercupDistrictComponent,
    SelectMastercupSubdistrictComponent
  ],
  templateUrl: './catm.component.html',
  styleUrl: './catm.component.scss'
})
export class CatmComponent {
  pageData: Page<CatmDto> = {size: 10};
  searchParam: CatmSearchParam = {};
  selectProvince = model<Province>({})
  selectDistrict = model<District>({})
  selectSubDistrict = model<SubDistrict>({})

  http = inject(HttpClient)
  constructor(private modalService: NgbModal,
              private catmService: CatmService) {
    this.search();
  }

  search(page: number = 1) {
    if(this.selectProvince()?.id == '1000') {
      this.searchParam.provinceName = this.selectProvince()?.name?.split('ฯ').at(0)
    } else {
      this.searchParam.provinceName = this.selectProvince()?.name
    }
    this.searchParam.districtName = this.selectDistrict()?.name
    this.searchParam.subDistrictName = this.selectSubDistrict()?.name
    let param = IgnoreNullHttpParams.fromObject(
      {...this.searchParam})
      .set("page", page - 1)
      .set("size", this.pageData.size)
    this.catmService.search(param).subscribe({
        next: e => {
          this.pageData = e
        }
      }
    )
  }

  openModelAdd(prefix: string) {
    const modalRef = this.modalService.open(CatmModelComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.prefix = prefix
    from(modalRef.result).pipe(filter(e => !!e))
      .subscribe(e => {
        this.search(1)
      });
  }

  openModelEdit(prefix: string, item: CatmDto) {
    const modalRef = this.modalService.open(CatmModelComponent, {
      windowClass: 'set-width-modal',
    });
    this.fetchSubDistrict(item).subscribe(e => {
      modalRef.componentInstance.catm = {...item}
      modalRef.componentInstance.item = signal({...item})
      modalRef.componentInstance.prefix = prefix
      modalRef.componentInstance.selectedDistrict=  signal<District>(e?.district)
      modalRef.componentInstance.selectedSubDistrict= signal<SubDistrict>(e)
      modalRef.componentInstance.selectedProvince =signal<Province>(e?.district?.province)

      from(modalRef.result).pipe(filter(e => !!e))
        .subscribe(e => {
          this.search(1)
        });
    })
  }

  fetchSubDistrict(item: CatmDto) {
    let catmCode = item.catm.substring(0,6) + '00'
    return this.http.get<SubDistrict>(`${environment.serverUrl}/api/subdistrict/search-by-catm` , { params: IgnoreNullHttpParams.fromObject({
      catm: catmCode
    }).toHttpParam()
    })
  }

  onChangeProvince() {
    this.selectSubDistrict.set(undefined)
    this.selectDistrict.set(undefined)
  }
  changeDistrict() {
    this.selectSubDistrict.set(undefined)
  }
}
