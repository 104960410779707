import {Component} from '@angular/core';
import {Page} from "../../../../generated-model/model";
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";
import {DepartmentNewModalComponent} from "./modal/department-new-modal/department-new-modal.component";

@Component({
  selector: 'app-department-new',
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    NgForOf,
    NgIf,
    NgbPagination,
    NotFoundComponent,
    ReactiveFormsModule
  ],
  templateUrl: './department-new.component.html',
  styleUrl: './department-new.component.scss'
})
export class DepartmentNewComponent {
  pageData: Page<any> = {size: 10};

  constructor(private modalService: NgbModal) {
    this.search();
  }

  search(page: number = 1) {
  }

  openModelAdd(prefix: string) {
    const modalRef = this.modalService.open(DepartmentNewModalComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.prefix = prefix
  }
}
