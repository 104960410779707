import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {McupFollowMotherRevokeDto, Page} from "../../../../generated-model/model";
import {environment} from "../../../../environments/environment";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";

@Injectable({
  providedIn: 'root'
})
export class McupFollowMotherRevokeResourceService {
  http = inject(HttpClient)

  search(params: IgnoreNullHttpParams) {
    return this.http.get<Page<McupFollowMotherRevokeDto>>(`${environment.serverUrl}/api/mcup-follow-mother-revoke/search`, {params: params.toHttpParam()})
  }

  save(item: McupFollowMotherRevokeDto) {
    return this.http.post(`${environment.serverUrl}/api/mcup-follow-mother-revoke/save`, item)
  }

  cancel(mastercupSeq: number) {
    return this.http.delete(`${environment.serverUrl}/api/mcup-follow-mother-revoke/cancel/${mastercupSeq}`)
  }
}
