<div class="card custom-box my-3 border-0">
  <form (ngSubmit)="search()">
    <div class="card-body">
      <div class="row mb-3">
        <label for="inscl" class="col-xl-2 col-form-label ">INSCL</label>
        <div class="col-xl-3">
          <input type="text" class="form-control" id="inscl" name="inscl" [(ngModel)]="searchParam.inscl">
        </div>
        <label for="insclName" class="col-xl-2 col-form-label ">INSCL_NAME</label>
        <div class="col-xl-3">
          <input type="text" class="form-control" id="insclName" name="insclName" [(ngModel)]="searchParam.insclName">
        </div>
      </div>
      <div class="row mb-3">
        <label for="used" class="col-xl-2 col-form-label ">สถานะการใช้งาน</label>
        <div class="col-xl-3">
          <select class="form-control col-lg-5" id="used" name="used" [(ngModel)]="searchParam.used">
            <option [ngValue]="undefined">ทั้งหมด</option>
            <option [ngValue]="'Y'">ใช้งาน</option>
            <option [ngValue]="'N'">ไม่ใช้งาน</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="text-center">
          <button type="submit" class="btn btn-primary me-2">
            <i class="bi bi-search me-1"></i>
            ค้นหา
          </button>
          <button type="reset" class="btn btn-default me-2">
            เคลียร์ข้อมูล
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="card custom-box mb-3 border-0">
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-striped table-borderless caption-top">
        <caption>
          แสดง {{ (pageData?.content?.length ? (pageData?.number * pageData?.size) + 1 : 0) | number }}
          ถึง {{ ((pageData?.number) * pageData?.size + pageData?.content?.length) | number }}
          จากทั้งหมด {{ pageData?.totalElements | number }} รายการ
          <!--          <button class="btn btn-success float-end" (click)="addOrEditItem('เพิ่ม',{used:'Y'})">-->
          <!--            <i class="bi bi-plus-lg me-1"></i>-->
          <!--            เพิ่มข้อมูล-->
          <!--          </button>-->
          <button class="btn btn-info float-end" (click)="openModelAdd('เพิ่ม')">
            <i class="bi bi-plus-lg me-1"></i>
            เพิ่มข้อมูล
          </button>
        </caption>
        <thead class="color-black font-weight-light">
        <tr class="table-light text-center" style="background-color: #eeeeee;">
          <th nowrap scope="col">INSCL</th>
          <th nowrap scope="col" class="">INSCL_NAME</th>
          <th nowrap scope="col" class="">ช่วงอายุ</th>
          <th nowrap scope="col" class="">MAININSCL</th>
          <th nowrap scope="col" class="">สถานะการใช้งาน</th>
          <th nowrap scope="col" class="">ดำเนินการ</th>
        </tr>
        </thead>
        <tbody class="table-borderless">
        <tr *ngIf="!pageData?.content?.length">
          <td class="text-muted text-center" colspan="6">
            <app-not-found></app-not-found>
          </td>
        </tr>
        <tr *ngFor="let item of pageData?.content; let i= index">
          <td class="text-center">{{ item?.inscl }}</td>
          <td class="">{{ item?.insclName }}</td>
          <td class="">{{ item?.insclDateExp }}</td>
          <td class="text-center">{{ item?.mainInscl }}</td>
          <td class="text-center">{{ Used[item?.used] }}</td>
          <td class="text-center">
            <a class="btn btn-default" (click)="openModelEdit('แก้ไข',item)">
              <i class="bi bi-pencil"></i>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <ngb-pagination class="d-flex justify-content-center"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    [page]="pageData?.number + 1"
                    (pageChange)="search($event)"
                    [pageSize]="pageData?.size"
                    [collectionSize]="pageData?.totalElements"></ngb-pagination>
  </div>
</div>
