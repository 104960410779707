import {inject, Injectable} from '@angular/core';
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";
import {Observable} from "rxjs";
import {Page, RelationDto} from "../../../../generated-model/model";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class RelationResource {

  http = inject(HttpClient);



  search(params: IgnoreNullHttpParams): Observable<Page<RelationDto>> {
    return this.http.get<Page<RelationDto>>(`${environment.serverUrl}/api/relation/search`, {params: params.toHttpParam()});
  }


  save(item: RelationDto): Observable<RelationDto> {
    return this.http.post<RelationDto>(`${environment.serverUrl}/api/relation`, item);
  }
}
