import {
  AfterViewInit,
  Component,
  forwardRef,
  inject,
  input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from "@angular/forms";
import {Province, ZoneDto} from "../../../../generated-model/model";
import {debounceTime, finalize, Observable, of, Subject} from "rxjs";
import {CommonModule} from "@angular/common";
import {SharedCommonModule} from "../../commons/shared-common.module";
import {NgSelectComponent} from "@ng-select/ng-select";
import {map} from "rxjs/operators";
import {ProvinceResource} from "../../commons/services/ProvinceResource";

@Component({
  selector: 'app-province-autocomplete',
  templateUrl: './province-autocomplete.component.html',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProvinceAutocompleteComponent),
      multi: true
    }
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedCommonModule
  ]
})
export class ProvinceAutocompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {

  provinceList$: Observable<Province[]> = of([]);
  term$ = new Subject<string>();
  typeaheadLoading = false;
  value: Province = undefined;
  id = input<string>('province');
  name = input<string>('province');
  restrictZone = input<ZoneDto>();
  disabled = input<boolean>();
  touched = false;
  private provinceResource = inject(ProvinceResource);
  @ViewChild('ngSelectComponent', {static: true, read: NgSelectComponent}) ngSelectComponent: NgSelectComponent;

  onChange = (value) => {
  };
  onTouched = () => {
  };

  compareWithCode = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.id == o2.id))
  };

  constructor() {
  }

  ngAfterViewInit() {

  }

  ngOnInit(): void {
    this.term$
      .pipe(debounceTime(500))
      .subscribe(term => {
        this.typeaheadLoading = true;
        this.provinceList$ = this.provinceResource.search(term, this.restrictZone()?.id).pipe(
          map(e => {
            return e;
          }),
          finalize(() => this.typeaheadLoading = false)
        );
      });
    this.term$.next('');
  }

  writeValue(value: Province) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  onValueChange(value: Province) {
    this.onChange(value);
    this.markAsTouched();
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const restrictZoneChange = changes['restrictZone'];
    if (restrictZoneChange) {
      this.value = undefined;
      this.onValueChange(undefined);
      this.term$.next('');
      this.ngSelectComponent.searchTerm = undefined;
    }
  }
}
