<div class="card custom-box my-3 border-0">
  <form (ngSubmit)="search()">
    <div class="card-body">
      <div class="row mb-3">
        <label for="province" class="col-xl-2 col-form-label ">จังหวัด</label>
        <div class="col-xl-3">
          <app-province-autocomplete id="province" name="province" [(ngModel)]="selectProvince" (selectionchange)="onChangeProvince()"></app-province-autocomplete>
        </div>
        <label for="district" class="col-xl-2 col-form-label ">อำเภอ / เขต</label>
        <div class="col-xl-3">
          <app-select-mastercup-district id="district" name="district" [provinceId]="selectProvince()?.id" [(ngModel)]="selectDistrict" (selectionchange)="changeDistrict()"></app-select-mastercup-district>
        </div>
      </div>
      <div class="row mb-3">
        <label for="subDistrict" class="col-xl-2 col-form-label ">ตำบล / แขวง</label>
        <div class="col-xl-3">
          <app-select-mastercup-subdistrict  id="subDistrict" name="subDistrict" [districtId]="selectDistrict()?.code" [(ngModel)]="selectSubDistrict"></app-select-mastercup-subdistrict>
        </div>
        <label for="catmId" class="col-xl-2 col-form-label ">รหัส CATM</label>
        <div class="col-xl-3">
          <input type="text" class="form-control" id="catmId" name="catmId" [(ngModel)]="searchParam.catmId">
        </div>
      </div>
      <div class="row">
        <div class="text-center">
          <button type="submit" class="btn btn-primary me-2">
            <i class="bi bi-search me-1"></i>
            ค้นหา
          </button>
          <button type="reset" class="btn btn-default me-2">
            เคลียร์ข้อมูล
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="card custom-box mb-3 border-0">
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-striped table-borderless caption-top">
        <caption>
          แสดง {{ (pageData?.content?.length ? (pageData?.number * pageData?.size) + 1 : 0) | number }}
          ถึง {{ ((pageData?.number) * pageData?.size + pageData?.content?.length) | number }}
          จากทั้งหมด {{ pageData?.totalElements | number }} รายการ
          <button class="btn btn-info float-end" (click)="openModelAdd('เพิ่ม')">
            <i class="bi bi-plus-lg me-1"></i>
            เพิ่มข้อมูล
          </button>
        </caption>
        <thead class="color-black font-weight-light">
        <tr class="table-light text-center" style="background-color: #eeeeee;">
          <th nowrap scope="col" class="">รหัส CATM</th>
          <th nowrap scope="col" class="">ชื่อ</th>
          <th nowrap scope="col" class="">จังหวัด</th>
          <th nowrap scope="col" class="">อำเภอ / เขต</th>
          <th nowrap scope="col" class="">ตำบล / แขวง</th>
          <th nowrap scope="col" class="">หมู่บ้าน</th>
          <th nowrap scope="col" class="">หมู่ที่</th>
          <th nowrap scope="col" class="">ดำเนินการ</th>
        </tr>
        </thead>
        <tbody class="table-borderless">
        <tr *ngIf="!pageData?.content?.length">
          <td class="text-muted text-center" colspan="8">
            <app-not-found></app-not-found>
          </td>
        </tr>
        <tr *ngFor="let item of pageData?.content; let i= index">
          <td class="text-center">{{ item.catm }}</td>
          <td class="text-center">{{ item.name }}</td>
          <td class="text-center">{{ item.changwatName }}</td>
          <td class="text-center">{{ item.amphurName }}</td>
          <td class="text-center">{{ item.tumbonName }}</td>
          <td class="text-center">{{ item.moobanName }}</td>
          <td class="text-center">{{ item.moo }}</td>
          <td class="text-center">
            <button class="btn btn-default" (click)="openModelEdit('แก้ไข',item)">
              <i class="bi bi-pencil"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <ngb-pagination class="d-flex justify-content-center"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    [page]="pageData?.number + 1"
                    (pageChange)="search($event)"
                    [pageSize]="pageData?.size"
                    [collectionSize]="pageData?.totalElements"></ngb-pagination>
  </div>
</div>
