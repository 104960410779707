import {Component, inject, OnInit, signal, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormsModule, NgForm, ReactiveFormsModule} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmDialogService} from "../../../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {filter} from "rxjs/operators";
import {SelectZoneComponent} from "../../../../../shared/components/select-zone/select-zone.component";
import {
  ProvinceAutocompleteComponent
} from "../../../../../shared/components/province-autocomplete/province-autocomplete.component";
import {
  Mode,
  QuotaHsubHmainOpDto,
  QuotaHsubHmainOpResponse,
  QuotaHsupHmainOpSearchParams
} from "../../../../../../generated-model/model";
import {
  HmainOpAutocompleteComponent
} from "../../../../../shared/components/hmain-op-autocomplete/hmain-op-autocomplete.component";
import {
  HsubAutocompleteComponent
} from "../../../../../shared/components/hsub-autocomponent/hsub-autocomplete.component";
import {DigitOnlyModule} from "@uiowa/digit-only";
import {CustomErrorComponent} from "../../../../../shared/commons/custom-error.component";
import {NgIf} from "@angular/common";
import {QuotaHsubHmainOpResource} from "../../../../../shared/commons/services/QuotaHsubHmainOpResource";
import {SharedCommonModule} from "../../../../../shared/commons/shared-common.module";

@Component({
  selector: 'app-quota-hsub-hmainop-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SelectZoneComponent,
    ProvinceAutocompleteComponent,
    FormsModule,
    HmainOpAutocompleteComponent,
    HsubAutocompleteComponent,
    DigitOnlyModule,
    CustomErrorComponent,
    NgIf,
    SharedCommonModule,
  ],
  templateUrl: './quota-hsub-hmainop-modal.component.html',
  styleUrl: './quota-hsub-hmainop-modal.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class QuotaHsubHmainopModalComponent implements OnInit {
  prefix = '';
  params: QuotaHsupHmainOpSearchParams = {}
  itemId: number = undefined
  mode: Mode
  quotaHsupHmainOp = signal({} as QuotaHsubHmainOpDto)
  response = signal({} as QuotaHsubHmainOpResponse)
  quotaHsupHmainOpResource = inject(QuotaHsubHmainOpResource)
  @ViewChild('form', {static: true}) public form: NgForm;

  constructor(public activeModal: NgbActiveModal,
              private confirmDialogService: ConfirmDialogService) {
  }

  ngOnInit() {
    if (this.itemId && this.mode == 'UPDATE') {
      this.quotaHsupHmainOpResource.findById(this.itemId).subscribe(
        value => {
          this.response.set(value)
        }
      )
    }
  }

  save() {
    this.form.control.markAllAsTouched()
    if (this.form.valid) {
      this.confirmDialogService.open({content: 'ยืนยันการบันทึกข้อมูล'})
        .pipe(filter(e => !!e))
        .subscribe({
          next: e => {
            if (this.mode == 'CREATE') {
              this.quotaHsupHmainOp().hsub = this.params.hmainOp.hcode
              this.quotaHsupHmainOp().hmainOp = this.params.hmainOp.hcode
              this.quotaHsupHmainOp().maxQuota = this.params.maxQuota
            } else if (this.mode == "UPDATE") {
              this.quotaHsupHmainOp().id = this.response().id
              this.quotaHsupHmainOp().maxQuota = this.response().maxQuota
              this.quotaHsupHmainOp().hsub = this.response().hmainOp.hcode
              this.quotaHsupHmainOp().hmainOp = this.response().hmainOp.hcode
            }
            this.activeModal.close(this.quotaHsupHmainOp())
          }
        });
    }
  }
}
