import {Component, inject, OnInit} from '@angular/core';
import {QuillEditorComponent, QuillModule, QuillViewComponent} from "ngx-quill";
import {FormsModule} from "@angular/forms";
import {News, Program, ProgramArray} from '../../../generated-model/model';
import {CommonModule} from "@angular/common";
import {SharedCommonModule} from "../../shared/commons/shared-common.module";
import {NewsService} from "./news.service";
import {ToastService} from "../../shared/commons/toast/toast-service";
import {ConfirmDialogService} from "../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {filter} from "rxjs/operators";
import {NewsModalComponent} from "./news-modal/news-modal.component";

@Component({
  selector: 'app-news',
  standalone: true,
  imports: [
    QuillModule,
    QuillViewComponent,
    QuillEditorComponent,
    FormsModule,
    CommonModule,
    SharedCommonModule
  ],
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss'
})
export class NewsComponent implements OnInit {

  programName: string;
  news: News;
  Program = Program;
  ProgramArray = ProgramArray;
  private newsService: NewsService = inject(NewsService);
  private toastService: ToastService = inject(ToastService);
  private confirmDialogService: ConfirmDialogService = inject(ConfirmDialogService);
  private modalService: NgbModal = inject(NgbModal);

  ngOnInit(): void {
  }

  search() {
    this.newsService.findByProgram(this.programName).subscribe(res => {
      if (res) {
        this.news = res;
      } else {
        this.news = {program: ProgramArray.find(f => f == this.programName)};
      }
    });
  }

  save() {
    this.confirmDialogService.open({content: 'ยืนยันการบันทึกข้อมูล'})
      .pipe(filter(e => !!e))
      .subscribe({
        next: () => {
          this.newsService.save(this.news).subscribe(res => {
            this.news = res;
            this.toastService.showSuccess('บันทึกข้อมูลสำเร็จ');
          });
        }
      });
  }

  preview() {
    const modalRef = this.modalService.open(NewsModalComponent, {
      windowClass: 'set-width-modal', fullscreen: true
    });
    modalRef.componentInstance.textDetail = this.news.textDetail;
  }
}
