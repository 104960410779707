<div class="card custom-box mb-3 border-0">
  <div class="card-body">
    <div class="row mb-3">
      <label class="col-xl-2 col-form-label ">ประเภทที่อยู่</label>
      <div class="col-xl-3">
        <input type="text" class="form-control" placeholder="ลงทะเบียนตรงตามที่อยู่ปัจจุบัน">
      </div>
      <label class="col-xl-2 col-form-label ">สถานะการใช้งาน</label>
      <div class="col-xl-3">
        <select class="form-control col-lg-5">
          <option [ngValue]="undefined">ทั้งหมด</option>
          <option [ngValue]="undefined">ใช้งาน</option>
          <option [ngValue]="undefined">ไม่ใช้งาน</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="text-center">
        <button type="submit" class="btn btn-primary me-2">
          <i class="bi bi-search me-1"></i>
          ค้นหา
        </button>
        <button type="reset" class="btn btn-default me-2">
          เคลียร์ข้อมูล
        </button>
      </div>
    </div>
  </div>
</div>
<div class="card custom-box mb-3 border-0">
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-striped table-borderless caption-top">
        <caption>
          แสดง {{ (pageData?.content?.length ? (pageData?.number * pageData?.size) + 1 : 0) | number }}
          ถึง {{ ((pageData?.number) * pageData?.size + pageData?.content?.length) | number }}
          จากทั้งหมด {{ pageData?.totalElements | number }} รายการ
        </caption>
        <thead class="color-black font-weight-light">
        <tr class="table-light text-center">
          <th nowrap scope="col">ลำดับ</th>
          <th nowrap scope="col" class="">ประเภทที่อยู่</th>
          <th nowrap scope="col" class="">สถานะการใช้งาน</th>
          <th nowrap scope="col" class="">ดำเนินการ</th>
        </tr>
        </thead>
        <tbody class="table-borderless">
        <tr>
          <td class="text-muted text-center" colspan="4">
            <app-not-found></app-not-found>
          </td>
        </tr>
        <!--        <tr>-->
        <!--          <td class="text-center"></td>-->
        <!--          <td class=""></td>-->
        <!--          <td class=""></td>-->
        <!--          <td class=""></td>-->
        <!--        </tr>-->
        </tbody>
      </table>
      <ngb-pagination class="d-flex justify-content-center"
                      [maxSize]="5"
                      [rotate]="true"
                      [ellipses]="false"
                      [boundaryLinks]="true"
                      [page]="pageData?.number + 1"
                      (pageChange)="search($event)"
                      [pageSize]="pageData?.size"
                      [collectionSize]="pageData?.totalElements"></ngb-pagination>
    </div>
  </div>
</div>
