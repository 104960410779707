import {Component, inject, signal, ViewEncapsulation} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {RelationDto} from "../../../../../../generated-model/model";
import {RelationResource} from "../../../relation/relation-resource";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmDialogService} from "../../../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-relation-attorney-modal',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './relation-attorney-modal.component.html',
  styleUrl: './relation-attorney-modal.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class RelationAttorneyModalComponent {
  prefix = ''
  item = signal<any>({})
  activeModal = inject(NgbActiveModal)
  confirmDialogService = inject(ConfirmDialogService)

  save() {
    this.confirmDialogService.open({content: 'ยืนยันการบันทึกข้อมูล'})
      .pipe(filter(e => !!e))
      .subscribe({
        next: e => {
        }
      })
  }

}
