import {Component, ViewEncapsulation} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {RightDto} from "../../../../../../generated-model/model";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmDialogService} from "../../../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {ToastService} from "../../../../../shared/commons/toast/toast-service";
import {filter} from "rxjs/operators";
import {DigitOnlyModule} from "@uiowa/digit-only";
import {MainInsclService} from "../../main-inscl.service";

@Component({
  selector: 'app-main-inscl-modal',
  standalone: true,
  imports: [
    FormsModule,
    DigitOnlyModule
  ],
  templateUrl: './main-inscl-modal.component.html',
  styleUrl: './main-inscl-modal.component.scss',
  encapsulation:ViewEncapsulation.None
})
export class MainInsclModalComponent {
  prefix = ''
  right: RightDto = {}

  constructor(public activeModal: NgbActiveModal,
              private confirmDialogService: ConfirmDialogService,
              private mainInsclService: MainInsclService,
              private toastService: ToastService,
  ) {

  }

  save() {
    this.confirmDialogService.open({content: 'ยืนยันการบันทึกข้อมูล'})
        .pipe(filter(e => !!e))
        .subscribe({
          next: e => {
            this.mainInsclService.save(this.right).subscribe({
              next: e => {
                this.activeModal.close(e)
                this.toastService.showSuccess(this.prefix + 'ข้อมูลสำเร็จ')
              }
            })
          }
        });
  }
}
