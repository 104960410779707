/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.29.814 on 2024-11-17 06:12:43.

export interface Page<T> extends Slice<T> {
    totalPages?: number;
    totalElements?: number;
}

export interface Slice<T> extends Streamable<T> {
    size?: number;
    content?: T[];
    number?: number;
    sort?: any;
    pageable?: Pageable;
    numberOfElements?: number;
    first?: boolean;
    last?: boolean;
}

export interface Catm {
    catm?: string;
    name?: string;
    changwatName?: string;
    amphurName?: string;
    tumbonName?: string;
    moobanName?: string;
    moo?: string;
}

export interface DepartmentNew {
    id?: number;
    used?: string;
    inserted?: any;
    lastupdate?: any;
    byuser?: string;
    remark?: string;
    ministryCode?: string;
    departmentCode?: string;
    departmentDesc?: string;
    departmentAbbr?: string;
    orinalType?: string;
    orinalCode?: string;
}

export interface Hospital {
    hcode?: string;
    hname?: string;
    subtype?: string;
    htype?: string;
    provinceId?: string;
    province?: Province;
    catmCode?: string;
    displayName?: string;
}

export interface Inscl {
    inscl?: string;
    insclName?: string;
    mainInscl?: string;
    insclDateExp?: string;
    used?: Used;
    codeWithName?: string;
}

export interface News {
    id?: number;
    program?: Program;
    startDate?: any;
    endDate?: any;
    textDetail?: string;
}

export interface NewsMapper {
}

export interface NewsMapperImpl extends NewsMapper {
}

export interface Province {
    id?: string;
    name?: string;
    zone?: Zone;
}

export interface Relation {
    id?: number;
    name?: string;
    code?: string;
    used?: Used;
}

export interface Right {
    rightId?: string;
    rightName?: string;
    rightOrder?: number;
    rightMain?: string;
    rightDesc?: string;
    used?: Used;
    codeWithName?: string;
}

export interface Zone {
    id?: string;
    name?: string;
}

export interface CatmDto {
    catm?: string;
    name?: string;
    changwatName?: string;
    amphurName?: string;
    tumbonName?: string;
    moobanName?: string;
    moo?: string;
}

export interface CatmSearchParam {
    catmId?: string;
    provinceName?: string;
    districtName?: string;
    subDistrictName?: string;
}

export interface DepartmentNewDto {
    id?: number;
    used?: string;
    inserted?: any;
    lastupdate?: any;
    byuser?: string;
    remark?: string;
    ministryCode?: string;
    departmentCode?: string;
    departmentDesc?: string;
    departmentAbbr?: string;
    orinalType?: string;
    orinalCode?: string;
}

export interface DepartmentNewSearchParam {
    ministryCode?: string;
    departmentCode?: string;
    departmentDesc?: string;
    used?: Used;
}

export interface InsclDto {
    inscl?: string;
    insclName?: string;
    mainInscl?: string;
    insclDateExp?: string;
    used?: Used;
}

export interface InsclSearchParam {
    inscl?: string;
    insclName?: string;
    used?: Used;
}

export interface NewsDto {
    id?: number;
    program?: Program;
    startDate?: any;
    endDate?: any;
    textDetail?: string;
}

export interface RelationDto {
    id?: number;
    name?: string;
    code?: string;
    used?: Used;
}

export interface RelationSearchParam {
    id?: number;
    name?: string;
    used?: Used;
}

export interface RightDto {
    rightId?: string;
    rightName?: string;
    rightOrder?: number;
    rightMain?: string;
    rightDesc?: string;
    used?: Used;
}

export interface RightSearchParam {
    rightId?: string;
    rightName?: string;
    used?: Used;
}

export interface UserData extends UserDetails {
    id?: number;
    personalId?: string;
    fname?: string;
    lname?: string;
    email?: string;
    organization?: UserOrganization;
    fullName?: string;
}

export interface Pageable {
    offset?: number;
    sort?: any;
    paged?: boolean;
    unpaged?: boolean;
    pageSize?: number;
    pageNumber?: number;
}

export interface GrantedAuthority {
    authority?: string;
}

export interface UserOrganization {
    id?: string;
    zoneCode?: string;
    fromType?: FromType;
    name?: string;
}

export interface UserDetails {
    enabled?: boolean;
    password?: string;
    username?: string;
    authorities?: GrantedAuthority[];
    accountNonExpired?: boolean;
    accountNonLocked?: boolean;
    credentialsNonExpired?: boolean;
}

export interface Streamable<T> extends Supplier<Stream<T>> {
    empty?: boolean;
}

export interface Supplier<T> {
}

export interface Stream<T> extends BaseStream<T, Stream<T>> {
}

export interface BaseStream<T, S> extends AutoCloseable {
    parallel?: boolean;
}

export interface AutoCloseable {
}

export type Authority = "ADMIN" | "EXECUTIVE_AUDITOR" | "CHIEF_AUDITOR" | "STAFF_AUDITOR";

export type Program = "SRM" | "NEW_AUTHEN" | "G_ONLINE" | "ABT_REGISTER" | "G_REGISTER" | "G_RECEIPT";

export type Used = "Y" | "N";

export type FromType = "H" | "A" | "P" | "Z" | "O" | "C" | "T" | "D";


// Added by 'EnumTypeAliasExtension' extension
export type AllEnumClass = "Authority" | "Program" | "Used" | "FromType"
// Enum description
export const Authority : {[p in Authority] : string } = {
   ADMIN:'ADMIN',
   EXECUTIVE_AUDITOR:'EXECUTIVE_AUDITOR',
   CHIEF_AUDITOR:'CHIEF_AUDITOR',
   STAFF_AUDITOR:'STAFF_AUDITOR'
}
export const Program : {[p in Program] : string } = {
   SRM:'ระบบลงทะเบียนสำนักงานหลักประกันสุขภาพแห่งชาติ (SRM)',
   NEW_AUTHEN:'ระบบตรวจสอบและยืนยันการเข้ารับบริการ(New Authen Code)',
   G_ONLINE:'ระบบขอเลขอนุมัติเบิกจ่ายตรง (สำหรับกองทุนอื่น)',
   ABT_REGISTER:'ระบบบันทึกข้อมูลผู้มีสิทธิ (อปท.)',
   G_REGISTER:'ระบบบันทึกข้อมูลผู้มีสิทธิ (รัฐวิสาหกิจ)',
   G_RECEIPT:'ระบบบันทึกเบิกค่ารักษาพยาบาลด้วยใบเสร็จ (ข้าราชการ)'
}
export const Used : {[p in Used] : string } = {
   Y:'ใช้งาน',
   N:'ไม่ใช้งาน'
}
export const FromType : {[p in FromType] : string } = {
   H:'hospital',
   A:'amphur',
   P:'province',
   Z:'zone',
   O:'nhso',
   C:'c',
   T:'t',
   D:'d'
}


// Added by 'EnumTypeArrayExtension' extension
export const AuthorityArray : Authority[]  = ['ADMIN','EXECUTIVE_AUDITOR','CHIEF_AUDITOR','STAFF_AUDITOR']
export const ProgramArray : Program[]  = ['SRM','NEW_AUTHEN','G_ONLINE','ABT_REGISTER','G_REGISTER','G_RECEIPT']
export const UsedArray : Used[]  = ['Y','N']
export const FromTypeArray : FromType[]  = ['H','A','P','Z','O','C','T','D']
