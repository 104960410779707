<div class="modal-header">
  <strong class="text-primary bold fs-5 d-inline-block">{{ prefix }}เครือข่ายหน่วยบริการที่ไม่อนุญาต</strong>
  <button type="button" class="btn-close" aria-label="Close"
          (click)="activeModal.close('Cross click')"></button>
</div>
<div class="modal-body">
  <form class="form-validate" #form="ngForm" (submit)="search()">
    <div class="modal-body">
      <div class="row mb-3">
        <label for="selectedProvince" class="col-xl-3 col-form-label ui-require">จังหวัด</label>
        <div class="col-xl-9">
          <app-select-mastercup-province id="selectedProvince"
                                         name="selectedProvince"
                                         [(ngModel)]="selectedProvince"
                                         (selectionchange)="onProvinceChange()"
                                         [disabled]="!!restrictProvince()"
                                         required
                                         #province="ngModel"></app-select-mastercup-province>
          @if (province.invalid && (province.dirty || province.touched)) {
            <custom-error *ngIf="province.hasError('required')" error="*จำเป็น"></custom-error>
          }
        </div>
      </div>
      <div class="row mb-3">
        <label for="selectedDistrict" class="col-xl-3 col-form-label ui-require">อำเภอ/เขต</label>
        <div class="col-xl-9">
          <app-select-mastercup-district id="selectedDistrict"
                                         name="selectedDistrict"
                                         [(ngModel)]="selectedDistrict"
                                         (selectionchange)="onDistrictChange()"
                                         [provinceId]="selectedProvince()?.id"
                                         [disabled]="!selectedProvince()"
                                         required
                                         #district="ngModel"></app-select-mastercup-district>
          @if (district.invalid && (district.dirty || district.touched)) {
            <custom-error *ngIf="district.hasError('required')" error="*จำเป็น"></custom-error>
          }
        </div>
      </div>
      <div class="row mb-3">
        <label for="selectedSubDistrict" class="col-xl-3 col-form-label ui-require">ตำบล/แขวง</label>
        <div class="col-xl-9">
          <app-select-mastercup-subdistrict id="selectedSubDistrict"
                                            name="selectedSubDistrict"
                                            [(ngModel)]="selectedSubDistrict"
                                            [districtId]="selectedDistrict()?.code"
                                            [disabled]="!selectedDistrict()"
                                            required
                                            #subDistrict="ngModel"></app-select-mastercup-subdistrict>
          @if (subDistrict.invalid && (subDistrict.dirty || subDistrict.touched)) {
            <custom-error *ngIf="subDistrict.hasError('required')" error="*จำเป็น"></custom-error>
          }
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-custom btn-primary">ค้นหา</button>
    </div>
  </form>
</div>
@if (pageData().content) {
  <div class="modal-body overflow-hidden">
    <div class="table-responsive">
      <table class="table table-striped table-borderless table-hover caption-top">
        <caption></caption>
        <thead>
        <tr>
          <th nowrap scope="col" class="text-center">ลำดับ</th>
          <th nowrap scope="col"></th>
          <th nowrap scope="col">รหัสเครือข่าย</th>
          <th nowrap scope="col">หน่วยบริการปฐมภูมิ</th>
          <th nowrap scope="col">หน่วยบริการประจำ</th>
          <th nowrap scope="col">หน่วยบริการรับส่งต่อ</th>
        </tr>
        </thead>
        <tbody>
          @for (mastercup of pageData()?.content; let i = $index; track mastercup.id) {
            <tr>
              <td nowrap class="text-center">{{ (pageData().size * pageData().number) + (i + 1) }}</td>
              <td nowrap>
                <button type="button" class="btn btn-default" (click)="select(mastercup)">
                  <i class="bi bi-box-arrow-down"></i> เพิ่ม
                </button>
              </td>
              <td nowrap>{{ mastercup.mastercupId || '-' }}</td>
              <td nowrap>{{ mastercup.hospSubDisplayName || '-' }}</td>
              <td nowrap>{{ mastercup.hospMainOpDisplayName || '-' }}</td>
              <td nowrap>{{ mastercup.hospMainDisplayName || '-' }}</td>
            </tr>
          } @empty {
            <tr>
              <td colspan="9">
                <app-not-found></app-not-found>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
    <div class="row justify-content-between">
      <div class="col-xl-6 text-muted">
        (รายการที่พบทั้งหมด {{ pageData()?.content?.length || 0 }} รายการ , หน้า : {{ pageData()?.number + 1 }}
        / {{ pageData().totalPages || 0 }})
      </div>
      <div class="col-xl-6">
        <ngb-pagination class="d-flex justify-content-end"
                        ngbAutofocus
                        [maxSize]="5"
                        [rotate]="true"
                        [ellipses]="false"
                        [boundaryLinks]="true"
                        [page]="pageData()?.number + 1"
                        (pageChange)="search($event)"
                        [pageSize]="pageData()?.size"
                        [collectionSize]="pageData()?.totalElements"/>
      </div>
    </div>
  </div>
}
