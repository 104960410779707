import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";
import {Observable} from "rxjs";
import {Page, RightDto} from "../../../../generated-model/model";
import {environment} from "../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class MainInsclService {

    constructor(private http: HttpClient) {
    }

    search(params: IgnoreNullHttpParams): Observable<Page<RightDto>> {
        return this.http.get<Page<RightDto>>(`${environment.serverUrl}/api/right/search`, {params: params.toHttpParam()});
    }


    save(item: RightDto): Observable<RightDto> {
        return this.http.post<RightDto>(`${environment.serverUrl}/api/right`, item);
    }
}
