import {Component} from '@angular/core';
import {DecimalPipe} from "@angular/common";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {Page} from "../../../../generated-model/model";
import {ReactiveFormsModule} from "@angular/forms";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";
import {DepartmentNewModalComponent} from "../department-new/modal/department-new-modal/department-new-modal.component";
import {GroupRegistrationModalComponent} from "./modal/group-registration-modal/group-registration-modal.component";

@Component({
  selector: 'app-group-registration',
  standalone: true,
  imports: [
    DecimalPipe,
    NgbPagination,
    ReactiveFormsModule,
    NotFoundComponent
  ],
  templateUrl: './group-registration.component.html',
  styleUrl: './group-registration.component.scss'
})
export class GroupRegistrationComponent {
  pageData: Page<any> = {size: 10};

  constructor(private modalService: NgbModal) {
    this.search();
  }


  search(page: number = 1) {

  }

  openModelAdd(prefix: string) {
    const modalRef = this.modalService.open(GroupRegistrationModalComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.prefix = prefix
  }
}
