<div class="card custom-box my-3 border-0">
  <form (ngSubmit)="search()">
    <div class="card-body">
      <div class="row mb-3">
        <label for="pid" class="col-xl-2 col-form-label ">เลขประจำตัวประชาชน</label>
        <div class="col-xl-3">
          <input type="text" class="form-control" id="pid" name="pid">
        </div>
      </div>
      <div class="row mb-3">
        <label for="firstName" class="col-xl-2 col-form-label ">ชื่อ</label>
        <div class="col-xl-3">
          <input type="text" class="form-control" id="firstName" name="firstName">
        </div>
        <label for="lastName" class="col-xl-2 col-form-label ">นามสกุล</label>
        <div class="col-xl-3">
          <input type="text" class="form-control" id="lastName" name="lastName">
        </div>
      </div>
      <div class="row">
        <div class="text-center">
          <button type="submit" class="btn btn-primary me-2">
            <i class="bi bi-search me-1"></i>
            ค้นหา
          </button>
          <button type="reset" class="btn btn-default me-2">
            เคลียร์ข้อมูล
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="card custom-box mb-3 border-0">
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-striped table-borderless caption-top">
        <caption>
          แสดง {{ (pageData?.content?.length ? (pageData?.number * pageData?.size) + 1 : 0) | number }}
          ถึง {{ ((pageData?.number) * pageData?.size + pageData?.content?.length) | number }}
          จากทั้งหมด {{ pageData?.totalElements | number }} รายการ
          <button class="btn btn-info float-end" (click)="openModelAdd('เพิ่ม')">
            <i class="bi bi-plus-lg me-1"></i>
            เพิ่มข้อมูล
          </button>
        </caption>
        <thead class="color-black font-weight-light">
        <tr class="table-light text-center" style="background-color: #eeeeee;">
          <th nowrap scope="col" class="">#</th>
          <th nowrap scope="col" class="">เลขประจำตัวประชาชน</th>
          <th nowrap scope="col" class="">ชื่อ</th>
          <th nowrap scope="col" class="">นามสกุล</th>
          <th nowrap scope="col" class="">จำนวนครั้งในการลงทะเบียนต่อปี</th>
          <th nowrap scope="col" class="">ดำเนินการ</th>
        </tr>
        </thead>
        <tbody class="table-borderless">
        <tr *ngIf="!pageData?.content?.length">
          <td class="text-muted text-center" colspan="6">
            <app-not-found></app-not-found>
          </td>
        </tr>
        <tr *ngFor="let item of pageData?.content; let i= index">
          <td class="text-center"></td>
          <td class=""></td>
          <td class=""></td>
          <td class=""></td>
          <td class="text-center"></td>
          <td class="text-center">
            <button class="btn btn-default">
              <i class="bi bi-pencil"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <ngb-pagination class="d-flex justify-content-center"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    [page]="pageData?.number + 1"
                    (pageChange)="search($event)"
                    [pageSize]="pageData?.size"
                    [collectionSize]="pageData?.totalElements"></ngb-pagination>
  </div>
</div>
