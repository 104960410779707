import {LogLevel} from "angular-auth-oidc-client";

export const environment = {
  production: false,
  serverUrl: "https://srm-master.nhso.geniustree.io",
  oidcIssuerUrl: 'https://sso.nhso.geniustree.io/realms/nhso',
  clientId: 'srm-master',
  logoutUrl: 'https://srm-master.nhso.geniustree.io/logout',
  srmPortalUrl: 'https://srm-portal.nhso.geniustree.io/secure',
  ngOidcLogLevel: LogLevel.None,
  enable: ['news', 'main-inscl', 'range-sub-inscl-type', 'group-registration', 'limit-service', 'quota', 'catm','count-select',
    'department-new', 'map-inscl', 'relation', 'quota-per-day', 'request-management', 'unregister-mastercup','email-notification-quota']
};
