<div class="card custom-box mb-3 border-0">
  <form (ngSubmit)="search()">
    <div class="card-body">
      <div class="row mb-3">
        <label class="col-xl-2 col-form-label ">MAP_INSCL</label>
        <div class="col-xl-3">
                    <app-inscl-autocomplete #mapInscl [(ngModel)]="mapInsclsearchParam().mapInscl" name="mapInscl">
                    </app-inscl-autocomplete>
        </div>
        <label class="col-xl-2 col-form-label ">CUR_INSCL</label>
        <div class="col-xl-3">
          <app-inscl-autocomplete #curInscl [(ngModel)]="mapInsclsearchParam().curInscl" name="curInscl">
          </app-inscl-autocomplete>
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-xl-2 col-form-label ">OFC_INSCL</label>
        <div class="col-xl-3">
          <app-inscl-autocomplete #ofcInscl [(ngModel)]="mapInsclsearchParam().ofcInscl" name="ofcInscl">
          </app-inscl-autocomplete>
        </div>
      </div>
      <div class="row">
        <div class="text-center">
          <button type="submit" class="btn btn-primary me-2">
            <i class="bi bi-search me-1"></i>
            ค้นหา
          </button>
          <button type="reset" class="btn btn-default me-2" (click)="clear()">
            เคลียร์ข้อมูล
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="card custom-box mb-3 border-0">
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-striped table-borderless caption-top">
        <caption>
          แสดง {{ (pageData?.content?.length ? (pageData?.number * pageData?.size) + 1 : 0) | number }}
          ถึง {{ ((pageData?.number) * pageData?.size + pageData?.content?.length) | number }}
          จากทั้งหมด {{ pageData?.totalElements | number }} รายการ
          <button class="btn btn-info float-end" (click)="openModelAdd('เพิ่ม')">
            <i class="bi bi-plus-lg me-1"></i>
            เพิ่มข้อมูล
          </button>
        </caption>
        <thead class="color-black font-weight-light">
        <tr class="table-light text-center" style="background-color: #eeeeee;">
          <th nowrap scope="col">MAP_INSCL</th>
          <th nowrap scope="col">CUR_INSCL</th>
          <th nowrap scope="col">OFC_INSCL</th>
          <th nowrap scope="col" class="">ดำเนินการ</th>
        </tr>
        </thead>
        <tbody class="table-borderless">
        <tr *ngIf="!pageData?.content?.length">
          <td class="text-muted text-center" colspan="4">
            <app-not-found></app-not-found>
          </td>
        </tr>
        <tr *ngFor="let item of pageData?.content; let i= index">
          <td class="text-center">{{item?.mapInscl}}</td>
          <td class="text-center">{{item?.curInscl}}</td>
          <td class="text-center">{{item?.ofcInscl}}</td>
          <td class="text-center">
            <button class="btn btn-default" (click)="openModelEdit('แก้ไข',item)">
              <i class="bi bi-pencil"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <ngb-pagination class="d-flex justify-content-center"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    [page]="pageData?.number + 1"
                    (pageChange)="search($event)"
                    [pageSize]="pageData?.size"
                    [collectionSize]="pageData?.totalElements"></ngb-pagination>
  </div>
</div>
