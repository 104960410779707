<div class="modal-header">
 <h6 class="text-start">{{prefix}}อีเมล</h6>
  <button type="button" aria-label="Close" class="btn-close" (click)="activeModal.close()"></button>
</div>
<div class="modal-body">
  <form #form="ngForm">
    <div class="row mb-3 mt-3">
      <label class="col-form-label col-2 text-end ui-require">อีเมล</label>
      <div class="col-8">
        <input id="email" name="email" class="form-control" type="email"
               pattern="[^@\s]+@[^@\s]+\.[^@\s]+" required [(ngModel)]="item().email" appEmailUnique
               #email="ngModel">
        @if (email.invalid && (email.dirty || email.touched)) {
          <custom-error *ngIf="email.hasError('required')" error="*จำเป็น"></custom-error>
          <custom-error *ngIf="email.hasError('pattern')" error="*ตรวจสอบความถูกต้องของรูปแบบอีเมล"></custom-error>
          <custom-error *ngIf="email.hasError('existEmail')" error="*พบอีเมลนี้ในระบบแล้ว"></custom-error>
        }
      </div>
    </div>
  </form>

  <div class="text-end">
    <button class="btn btn-primary custom-button mx-1 btn-main confirm-btn" (click)="save()">บันทึก</button>
    <button class="btn btn-default custom-button mx-1 btn-main confirm-btn" (click)="activeModal.close()">ปิด</button>
  </div>
</div>
