/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.29.814 on 2025-04-03 04:03:34.

export interface Page<T> extends Slice<T> {
    totalPages?: number;
    totalElements?: number;
}

export interface Slice<T> extends Streamable<T> {
    size?: number;
    content?: T[];
    number?: number;
    sort?: any;
    first?: boolean;
    pageable?: Pageable;
    numberOfElements?: number;
    last?: boolean;
}

export interface AbstractAuditingEntity {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
}

export interface DepartmentNew {
    id?: number;
    used?: string;
    inserted?: any;
    lastupdate?: any;
    byuser?: string;
    remark?: string;
    ministryCode?: string;
    departmentCode?: string;
    departmentDesc?: string;
    departmentAbbr?: string;
    orinalType?: string;
    orinalCode?: string;
}

export interface District {
    code?: string;
    name?: string;
    province?: Province;
}

export interface EmailNotificationQuota {
    id?: number;
    email?: string;
}

export interface FundCountSelectLog extends AbstractAuditingEntity {
    id?: number;
    fundId?: number;
    countSelect?: number;
}

export interface HmainOp {
    hcode?: string;
    htitle?: Htitle;
    hname?: string;
    province?: Province;
}

export interface Hospital {
    hcode?: string;
    hname?: string;
    subtype?: string;
    htype?: string;
    provinceId?: string;
    htitle?: Htitle;
    province?: Province;
    catmCode?: string;
    latitude?: number;
    longitude?: number;
    displayName?: string;
    fullName?: string;
}

export interface Hsub {
    hcode?: string;
    htitle?: Htitle;
    hname?: string;
    hmainOp?: string;
    province?: Province;
}

export interface Htitle {
    htitleId?: number;
    htitleName?: string;
    shortName?: string;
    used?: string;
}

export interface MapInscl {
    mapInscl?: string;
    curInscl?: string;
    ofcInscl?: string;
}

export interface MapInsclPk {
    mapInscl?: string;
    curInscl?: string;
}

export interface Mastercup {
    id?: number;
    used?: string;
    inserted?: any;
    lastupdate?: any;
    byuser?: string;
    remark?: string;
    mastercupId?: string;
    provinceId?: string;
    province?: Province;
    hospMainCode?: string;
    hospMain?: Hospital;
    hospSub?: Hospital;
    hospSubCode?: string;
    hospMainOpCode?: string;
    hospMainOp?: Hospital;
    hmaintypeid?: string;
    hsubtypeid?: string;
    paidModel?: string;
    dateInCup?: any;
    dateOutCup?: any;
    quota?: string;
    flag?: string;
    bufferCount?: number;
    workdate?: string;
    crossProvince?: string;
    datePause?: any;
    dateResume?: any;
    updateSystemTag?: string;
    catmNear?: string;
    selectable?: boolean;
    maxRegPerson?: number;
    catm?: VCatm;
    hospSubDisplayName?: string;
    hospMainOpDisplayName?: string;
    hospMainDisplayName?: string;
}

export interface McupFollowMotherRevoke extends AbstractAuditingEntity {
    mastercupSeq?: number;
    mastercup?: Mastercup;
    active?: boolean;
}

export interface News {
    id?: number;
    program?: Program;
    startDate?: any;
    endDate?: any;
    textDetail?: string;
}

export interface NewsMapper {
}

export interface NewsMapperImpl extends NewsMapper {
}

export interface PersonalData {
    personalId?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
}

export interface PersonalFund {
    id?: number;
    personalId?: string;
    mainInsclId?: string;
    countSelect?: number;
    transDateEnd?: any;
    personalData?: PersonalData;
}

export interface Province {
    id?: string;
    name?: string;
    zone?: Zone;
}

export interface QuotaHsubHmainOp extends AbstractAuditingEntity {
    id?: number;
    hsub?: Hospital;
    hmainOp?: Hospital;
    maxQuota?: number;
}

export interface Relation {
    id?: number;
    name?: string;
    code?: string;
    used?: Used;
}

export interface SubDistrict {
    code?: string;
    name?: string;
    district?: District;
    catm?: string;
}

export interface VCatm {
    catm?: string;
    name?: string;
    changwatName?: string;
    amphurName?: string;
    tumbonName?: string;
    moobanName?: string;
    moo?: string;
}

export interface Zone {
    id?: string;
    name?: string;
}

export interface CatmDto {
    catm?: string;
    name?: string;
    changwatName?: string;
    amphurName?: string;
    tumbonName?: string;
    moobanName?: string;
    moo?: string;
}

export interface CatmSearchParam {
    catmId?: string;
    provinceName?: string;
    districtName?: string;
    subDistrictName?: string;
}

export interface DepartmentNewDto {
    id?: number;
    used?: string;
    inserted?: any;
    lastupdate?: any;
    byuser?: string;
    remark?: string;
    ministryCode?: string;
    departmentCode?: string;
    departmentDesc?: string;
    departmentAbbr?: string;
    orinalType?: string;
    orinalCode?: string;
}

export interface DepartmentNewSearchParam {
    ministryCode?: string;
    departmentCode?: string;
    departmentDesc?: string;
    used?: string;
}

export interface DistrictDto {
    code?: string;
    name?: string;
    province?: ProvinceDto;
}

export interface EmailNotificationQuotaDto {
    id?: number;
    email?: string;
}

export interface HospitalDto {
    hcode?: string;
    hname?: string;
    subtype?: string;
    htype?: string;
    provinceId?: string;
    htitleHtitleId?: number;
    htitleHtitleName?: string;
    provinceName?: string;
    province?: ProvinceDto;
    catmCode?: string;
    latitude?: number;
    longitude?: number;
}

export interface InsclDto {
    inscl?: string;
    insclName?: string;
    mainInscl?: string;
    codeWithName?: string;
    insclDateExp?: string;
    used?: Used;
}

export interface InsclSearchParam {
    inscl?: string;
    insclName?: string;
    used?: Used;
}

export interface MapInsclSearchParam {
    mapInscl?: Inscl;
    curInscl?: Inscl;
    ofcInscl?: Inscl;
}

export interface MastercupDto {
    id?: number;
    used?: string;
    inserted?: any;
    lastupdate?: any;
    byuser?: string;
    remark?: string;
    mastercupId?: string;
    provinceId?: string;
    hospMainCode?: string;
    hospSubCode?: string;
    hospMainOpCode?: string;
    hmaintypeid?: string;
    hsubtypeid?: string;
    paidModel?: string;
    dateInCup?: any;
    dateOutCup?: any;
    quota?: string;
    catm?: VCatm;
    flag?: string;
    bufferCount?: number;
    workdate?: string;
    crossProvince?: string;
    datePause?: any;
    dateResume?: any;
    updateSystemTag?: string;
    maxRegPerson?: number;
    hospSubDisplayName?: string;
    hospMainOpDisplayName?: string;
    hospMainDisplayName?: string;
}

export interface MastercupSearchParam {
    catm?: string;
    districtId?: string;
    moo?: string;
    hcode3Level?: boolean;
    excludeMastercupId?: string;
}

export interface McupFollowMotherRevokeDto {
    mastercupSeq?: number;
    mastercup?: MastercupDto;
    active?: boolean;
}

export interface McupFollowMotherRevokeSearchParam {
    zone?: Zone;
    province?: Province;
    hmainOp?: HmainOp;
    hsub?: Hsub;
    mastercup?: Mastercup;
}

export interface NewsDto {
    id?: number;
    program?: Program;
    startDate?: any;
    endDate?: any;
    textDetail?: string;
}

export interface PersonalDataDto {
    personalId?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
}

export interface PersonalFundDto {
    id?: number;
    countSelect?: number;
    personalData?: PersonalDataDto;
}

export interface ProvinceDto {
    id?: string;
    name?: string;
    zone?: ZoneDto;
}

export interface QuotaHsubHmainOpDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    hsub?: string;
    hmainOp?: string;
    maxQuota?: number;
}

export interface QuotaHsubHmainOpResponse {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    hsub?: HospitalDto;
    hmainOp?: HospitalDto;
    maxQuota?: number;
}

export interface QuotaHsupHmainOpSearchParams {
    zone?: Zone;
    province?: Province;
    hmainOp?: HmainOp;
    hsub?: Hsub;
    maxQuota?: number;
}

export interface RelationDto {
    id?: number;
    name?: string;
    code?: string;
    used?: Used;
}

export interface RelationSearchParam {
    id?: number;
    name?: string;
    used?: Used;
}

export interface RightDto {
    rightId?: string;
    rightName?: string;
    rightOrder?: number;
    rightMain?: string;
    rightDesc?: string;
    used?: Used;
}

export interface RightSearchParam {
    rightId?: string;
    rightName?: string;
    used?: Used;
}

export interface SubDistrictDto {
    code?: string;
    name?: string;
    district?: DistrictDto;
    catm?: string;
}

export interface ZoneDto {
    id?: string;
    name?: string;
}

export interface NewsProj {
    program?: Program;
    textDetail?: string;
}

export interface UserData extends UserDetails {
    id?: number;
    personalId?: string;
    fname?: string;
    lname?: string;
    email?: string;
    organization?: UserOrganization;
    fullName?: string;
}

export interface Pageable {
    offset?: number;
    sort?: any;
    paged?: boolean;
    unpaged?: boolean;
    pageSize?: number;
    pageNumber?: number;
}

export interface Inscl {
    inscl?: string;
    insclName?: string;
    mainInscl?: string;
    insclDateExp?: string;
    used?: Used;
    codeWithName?: string;
}

export interface GrantedAuthority {
    authority?: string;
}

export interface UserOrganization {
    id?: string;
    zoneCode?: string;
    fromType?: FromType;
    name?: string;
}

export interface UserDetails {
    enabled?: boolean;
    password?: string;
    username?: string;
    authorities?: GrantedAuthority[];
    accountNonExpired?: boolean;
    accountNonLocked?: boolean;
    credentialsNonExpired?: boolean;
}

export interface Streamable<T> extends Supplier<Stream<T>> {
    empty?: boolean;
}

export interface Supplier<T> {
}

export interface Stream<T> extends BaseStream<T, Stream<T>> {
}

export interface BaseStream<T, S> extends AutoCloseable {
    parallel?: boolean;
}

export interface AutoCloseable {
}

export type Authority = "ADMIN" | "EXECUTIVE_AUDITOR" | "CHIEF_AUDITOR" | "STAFF_AUDITOR";

export type Mode = "CREATE" | "UPDATE" | "READ";

export type Program = "SRM" | "NEW_AUTHEN" | "G_ONLINE" | "ABT_REGISTER" | "G_REGISTER" | "G_RECEIPT";

export type Used = "Y" | "N";

export type FromType = "H" | "A" | "P" | "Z" | "O" | "C" | "T" | "D";


// Added by 'EnumTypeAliasExtension' extension
export type AllEnumClass = "Authority" | "Mode" | "Program" | "Used" | "FromType"
// Enum description
export const Authority : {[p in Authority] : string } = {
   ADMIN:'ADMIN',
   EXECUTIVE_AUDITOR:'EXECUTIVE_AUDITOR',
   CHIEF_AUDITOR:'CHIEF_AUDITOR',
   STAFF_AUDITOR:'STAFF_AUDITOR'
}
//skip Mode because it not have getValue() method
export const Program : {[p in Program] : string } = {
   SRM:'ระบบลงทะเบียนสำนักงานหลักประกันสุขภาพแห่งชาติ (SRM)',
   NEW_AUTHEN:'ระบบตรวจสอบและยืนยันการเข้ารับบริการ(New Authen Code)',
   G_ONLINE:'ระบบขอเลขอนุมัติเบิกจ่ายตรง (สำหรับกองทุนอื่น)',
   ABT_REGISTER:'ระบบบันทึกข้อมูลผู้มีสิทธิ (อปท.)',
   G_REGISTER:'ระบบบันทึกข้อมูลผู้มีสิทธิ (รัฐวิสาหกิจ)',
   G_RECEIPT:'ระบบบันทึกเบิกค่ารักษาพยาบาลด้วยใบเสร็จ (ข้าราชการ)'
}
export const Used : {[p in Used] : string } = {
   Y:'ใช้งาน',
   N:'ไม่ใช้งาน'
}
export const FromType : {[p in FromType] : string } = {
   H:'hospital',
   A:'amphur',
   P:'province',
   Z:'zone',
   O:'nhso',
   C:'c',
   T:'t',
   D:'d'
}


// Added by 'EnumTypeArrayExtension' extension
export const AuthorityArray : Authority[]  = ['ADMIN','EXECUTIVE_AUDITOR','CHIEF_AUDITOR','STAFF_AUDITOR']
export const ModeArray : Mode[]  = ['CREATE','UPDATE','READ']
export const ProgramArray : Program[]  = ['SRM','NEW_AUTHEN','G_ONLINE','ABT_REGISTER','G_REGISTER','G_RECEIPT']
export const UsedArray : Used[]  = ['Y','N']
export const FromTypeArray : FromType[]  = ['H','A','P','Z','O','C','T','D']
