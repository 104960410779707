import {Component, inject, signal, ViewChild, ViewEncapsulation} from '@angular/core';
import {DepartmentNewDto} from "../../../../../../generated-model/model";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmDialogService} from "../../../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {filter} from "rxjs/operators";
import {FormsModule, NgForm, ReactiveFormsModule} from "@angular/forms";
import {CustomErrorComponent} from "../../../../../shared/commons/custom-error.component";
import {NgIf} from "@angular/common";
import {DepartmentNewResource} from "../../../../../shared/commons/services/DepartmentNewResource";

@Component({
  selector: 'app-department-new-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CustomErrorComponent,
    NgIf
  ],
  templateUrl: './department-new-modal.component.html',
  styleUrl: './department-new-modal.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class DepartmentNewModalComponent {
  prefix = '';
  activeModal = inject(NgbActiveModal)
  confirmDialogService = inject(ConfirmDialogService)
  item = signal({} as DepartmentNewDto)
  departmentNewResource = inject(DepartmentNewResource)
  orinalTypes = signal([] as string[])
  @ViewChild('form', {static: true}) public form: NgForm;

  constructor() {
    this.departmentNewResource.findAllOrinalType().subscribe({
      next: value => {
        this.orinalTypes.set(value)
      }
    })
  }

  save() {
    this.form.control.markAllAsTouched()
    if (this.form.valid) {
      this.confirmDialogService.open({content: 'ยืนยันการบันทึกข้อมูล'})
        .pipe(filter(e => !!e))
        .subscribe({
          next: e => {
            this.activeModal.close(this.item())
          }
        });
    }
  }
}
