import {Component, inject, OnInit, signal, ViewEncapsulation} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmDialogService} from "../../../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {catchError, filter, switchMap} from "rxjs/operators";
import {MapInscl, MapInsclSearchParam} from "../../../../../../generated-model/model";
import {MapInsclService} from "../../map-inscl.service";
import {
  InsclAutocompleteComponent
} from "../../../../../shared/components/inscl-autocomplete/inscl-autocomplete.component";
import {InsclResource} from "../../../sub-inscl/inscl-resource.service";
import {HttpErrorResponse} from "@angular/common/http";
import {throwError} from "rxjs";
import {ToastService} from "../../../../../shared/commons/toast/toast-service";

@Component({
  selector: 'app-map-inscl-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InsclAutocompleteComponent,
    FormsModule
  ],
  templateUrl: 'map-inscl-modal.component.html',
  styleUrl: './map-inscl-modal.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class MapInsclModalComponent implements OnInit {
  prefix = ''
  mapInscl: MapInscl = {}
  oldMapInscl:MapInscl={}
  mapInsclsearchParam = signal<MapInsclSearchParam>({})
  activeModal = inject(NgbActiveModal)
  confirmDialogService = inject(ConfirmDialogService)
  mapInsclService = inject(MapInsclService)
  insclSevice = inject(InsclResource)
  toastService = inject(ToastService)

  errors: { [key: string]: string } = {};

  validate(): boolean {
    this.errors = {};

    if (!this.mapInsclsearchParam().mapInscl) {
      this.errors['mapInscl'] = 'กรุณาระบุสิทธิ';
    }

    if (!this.mapInsclsearchParam().curInscl) {
      this.errors['curInscl'] = 'กรุณาระบุสิทธิ';
    }

    return Object.keys(this.errors).length === 0;
  }

  ngOnInit(): void {
    const insclKeys = ['mapInscl', 'curInscl', 'ofcInscl'];
    insclKeys.forEach(key => {
      if (this.mapInscl[key]) {
        if (this.mapInscl[key] === 'NULL') {
          this.mapInsclsearchParam.update(current => ({
            ...current,
            [key]: { inscl: 'NULL', insclName: 'NULL', codeWithName: 'NULL' }
          }));
        } else {
          this.insclSevice.searchAuto(this.mapInscl[key]).subscribe({
            next: value => {
              if (value && value.length > 0) {
                this.mapInsclsearchParam.update(current => ({
                  ...current,
                  [key]: value[0]
                }));
              }
            }
          });
        }
      }
    });
  }

  setMapInscl(data: MapInscl) {
    this.mapInscl = {...data};
  }


  save() {
    if (!this.validate()) {
      return;
    }
    this.confirmDialogService.open({content: 'ยืนยันการบันทึกข้อมูล'})
      .pipe(
        filter(e => !!e),
        switchMap(() => this.mapInsclService.save(this.mapInsclsearchParam())),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 409) {
            this.toastService.showDangerWithMessage(error.error || 'รหัสสิทธินี้มีอยู่ในระบบแล้ว');
          } else {
            this.toastService.showDangerWithMessage('เกิดข้อผิดพลาดในการบันทึกข้อมูล');
          }
          return throwError(() => error);
        })
      ).subscribe({
      next: e => {
        this.activeModal.close(e);
        this.toastService.showSuccess(this.prefix + 'ข้อมูลสำเร็จ');
      }
    });
  }
}
