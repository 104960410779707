<div class="modal-header">
  <strong class="text-primary bold fs-5 d-inline-block">{{prefix}} CATM</strong>
</div>
<div class="modal-body">
  <div class="mb-3 row">
    <label for="province" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">จังหวัด</label>
    <div class="col-xl-3">
      <select class="form-control col-lg-5" id="province" name="province">
        <option [ngValue]="undefined">กรุณาเลือก</option>
      </select>
    </div>
    <label for="district" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">อำเภอ / เขต</label>
    <div class="col-xl-3">
      <select class="form-control col-lg-5" id="district" name="district">
        <option [ngValue]="undefined">กรุณาเลือก</option>
      </select>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="subDistrict" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">ตำบล / แขวง</label>
    <div class="col-xl-3">
      <select class="form-control col-lg-5" id="subDistrict" name="subDistrict">
        <option [ngValue]="undefined">กรุณาเลือก</option>
      </select>
    </div>
    <label for="mooban" class="col-xl-2 ps-xl-4 col-form-label text-end">หมู่บ้าน</label>
    <div class="col-xl-3">
      <input type="text" class="form-control" id="mooban" name="mooban" [(ngModel)]="catm.moobanName">
    </div>
  </div>
  <div class="mb-3 row">
    <label for="moo" class="col-xl-2 ps-xl-4 col-form-label text-end">หมู่ที่</label>
    <div class="col-xl-3">
      <input type="text" class="form-control" id="moo" name="moo" [(ngModel)]="catm.moo">
    </div>
    <label for="name" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">ชื่อ</label>
    <div class="col-xl-3">
      <input type="text" class="form-control" id="name" name="name" [(ngModel)]="catm.name">
    </div>
  </div>
</div>
<div class="row mt-3 mb-3">
  <div class="col-12 text-end">
    <button type="button" class="btn btn-primary custom-button mx-1 btn-main confirm-btn" (click)="save()">
      {{prefix}} CATM
    </button>
    <button type="button" class="btn btn-default custom-button mx-1 btn-main confirm-btn" (click)="activeModal.close()">
      ปิด
    </button>
  </div>
</div>
