import {Component, inject, ViewChild} from '@angular/core';
import {PersonalFundDto} from "../../../../../generated-model/model";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, NgForm} from "@angular/forms";
import {SharedCommonModule} from "../../../../shared/commons/shared-common.module";
import {CustomErrorComponent} from "../../../../shared/commons/custom-error.component";
import {NgIf} from "@angular/common";
import {ConfirmDialogService} from "../../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {filter} from "rxjs/operators";
import {DigitOnlyModule} from "@uiowa/digit-only";

@Component({
  selector: 'app-count-select-modal',
  standalone: true,
  imports: [
    FormsModule,
    SharedCommonModule,
    CustomErrorComponent,
    NgIf,
    DigitOnlyModule
  ],
  templateUrl: './count-select-modal.component.html',
  styleUrl: './count-select-modal.component.scss'
})
export class CountSelectModalComponent {
  item: PersonalFundDto = {}
  activeModal = inject(NgbActiveModal)
  confirmDialogService = inject(ConfirmDialogService)
  @ViewChild('form', {static: true}) public form: NgForm;

  save() {
    this.form.control.markAllAsTouched()
    if (this.form.valid) {
      this.confirmDialogService.open({content: 'ยืนยันการเปลี่ยนจำนวนครั้งในการลงทะเบียนต่อปี'})
        .pipe(filter(e => !!e))
        .subscribe({
          next: e => {
            this.activeModal.close(this.item)
          }
        })
    }
  }
}
