import {Component, inject, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {QuillViewComponent, QuillViewHTMLComponent} from "ngx-quill";

@Component({
  selector: 'app-news-modal',
  standalone: true,
  imports: [
    QuillViewComponent,
    QuillViewHTMLComponent
  ],
  templateUrl: './news-modal.component.html',
  styleUrl: './news-modal.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class NewsModalComponent {

  textDetail: string;
  public activeModal: NgbActiveModal = inject(NgbActiveModal);
}
