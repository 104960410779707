import {Component, forwardRef, inject, input, SimpleChanges, ViewChild} from '@angular/core';
import {AsyncPipe, NgIf} from "@angular/common";
import {NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent} from "@ng-select/ng-select";
import {FormsModule, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {debounceTime, finalize, Observable, of, Subject} from "rxjs";
import {HmainOp, Hsub, Province, ZoneDto} from "../../../../generated-model/model";
import {map} from "rxjs/operators";
import {HmainOpResource} from "../../commons/services/HmainOpResource.service";

@Component({
  selector: 'app-hmain-op-autocomplete',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
    NgSelectComponent,
    FormsModule
  ],
  templateUrl: './hmain-op-autocomplete.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HmainOpAutocompleteComponent),
    multi: true,
  }]
})
export class HmainOpAutocompleteComponent {

  hmainOpList$: Observable<HmainOp[]> = of([]);
  term$ = new Subject<string>();
  typeaheadLoading = false;
  value: HmainOp = undefined;
  id = input<string>('hmainOp');
  name = input<string>('hmainOp');
  restrictZone = input<ZoneDto>();
  restrictProvince = input<Province>();
  disabled = input<boolean>();
  touched = false;
  private hmainOpResource = inject(HmainOpResource);
  @ViewChild('ngSelectComponent', {static: true, read: NgSelectComponent}) ngSelectComponent: NgSelectComponent;
  @ViewChild(NgControl) innerNgControl: NgControl;

  onChange = (value) => {
  };
  onTouched = () => {
  };

  compareWithHmainOp = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.hcode == o2.hcode))
  };

  constructor() {
  }

  ngAfterViewInit() {

  }

  ngOnInit(): void {
    this.term$
      .pipe(debounceTime(500))
      .subscribe(term => {
        this.typeaheadLoading = true;
        this.hmainOpList$ = this.hmainOpResource.search(term, this.restrictZone(), this.restrictProvince()).pipe(
          map(e => {
            return e;
          }),
          finalize(() => this.typeaheadLoading = false)
        );
      });
    this.term$.next('');
  }

  writeValue(value: Hsub) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  onValueChange(value: Hsub) {
    this.onChange(value);
    this.markAsTouched();
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const restrictZoneChange = changes['restrictZone'];
    const restrictProvinceChange = changes['restrictProvince'];
    if (restrictZoneChange || restrictProvinceChange) {
      this.value = undefined;
      this.onValueChange(undefined);
      this.term$.next('');
      this.ngSelectComponent.searchTerm = undefined;
    }
  }
}
