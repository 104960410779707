<div class="modal-header">
  <strong class="text-primary bold fs-5 d-inline-block">{{prefix}} CATM</strong>
  <button type="button" aria-label="Close" class="btn-close" (click)="activeModal.close()"></button>
</div>
<form #form="ngForm" class="form-validate">
  <div class="modal-body">
    <div class="mb-3 row">
      <label for="catm" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">รหัส CATM</label>
      <div class="col-xl-3">
       <input id="catm" name="catm" class="form-control" [(ngModel)]="item().catm" required #catmId="ngModel" appCatmUnique [originalValue]="catm.catm">
        @if (catmId.invalid && (catmId.dirty || catmId.touched)) {
          <custom-error *ngIf="catmId.hasError('required')" error="*จำเป็น"></custom-error>
          <custom-error *ngIf="catmId.hasError('existCatm')" error="*พบ catm นี้ในระบบแล้ว"></custom-error>
        }
      </div>
    </div>
    <div class="mb-3 row">
      <label for="province" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">จังหวัด</label>
      <div class="col-xl-3">
        <app-province-autocomplete id="province" name="province" [(ngModel)]="selectedProvince" required #province="ngModel"
        (selectionchange)="changeProvince()"></app-province-autocomplete>
        @if (province.invalid && (province.dirty || province.touched)) {
          <custom-error *ngIf="province.hasError('required')" error="*จำเป็น"></custom-error>
        }
      </div>
      <label for="district" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">อำเภอ / เขต</label>
      <div class="col-xl-3">
        <app-select-mastercup-district  id="district" name="district" [provinceId]="selectedProvince()?.id" [(ngModel)]="selectedDistrict"
                                        [disabled]="!selectedProvince()" (selectionchange)="changeDistrict()"
                                        required #districtModel="ngModel"></app-select-mastercup-district>
        @if (districtModel.invalid && (districtModel.dirty || districtModel.touched)) {
          <custom-error *ngIf="districtModel.hasError('required')" error="*จำเป็น"></custom-error>
        }
      </div>
    </div>
    <div class="mb-3 row">
      <label for="subDistrict" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">ตำบล / แขวง</label>
      <div class="col-xl-3">
        <app-select-mastercup-subdistrict id="subDistrict" name="subDistrict" [districtId]="selectedDistrict()?.code" [(ngModel)]="selectedSubDistrict"
                                          [disabled]="!selectedDistrict()"
                                          required #subDistrictNg="ngModel"></app-select-mastercup-subdistrict>
        @if (subDistrictNg.invalid && (subDistrictNg.dirty || subDistrictNg.touched)) {
          <custom-error *ngIf="subDistrictNg.hasError('required')" error="*จำเป็น"></custom-error>
        }
      </div>
      <label for="mooban" class="col-xl-2 ps-xl-4 col-form-label text-end">หมู่บ้าน</label>
      <div class="col-xl-3">
        <input type="text" class="form-control" id="mooban" name="mooban" [(ngModel)]="item().moobanName">
      </div>
    </div>
    <div class="mb-3 row">
      <label for="moo" class="col-xl-2 ps-xl-4 col-form-label text-end">หมู่ที่</label>
      <div class="col-xl-3">
        <input type="text" class="form-control" id="moo" name="moo" [(ngModel)]="item().moo">
      </div>
      <label for="name" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">ชื่อ</label>
      <div class="col-xl-3">
        <input type="text" class="form-control" id="name" name="name" [(ngModel)]="item().name" required #name="ngModel">
        @if (name.invalid && (name.dirty || name.touched)) {
          <custom-error *ngIf="name.hasError('required')" error="*จำเป็น"></custom-error>
        }
      </div>
    </div>
  </div>
  <div class="row mt-3 mb-3">
    <div class="col-12 text-end">
      <button type="button" class="btn btn-primary custom-button mx-1 btn-main confirm-btn" (click)="save()">
        {{prefix}} CATM
      </button>
      <button type="button" class="btn btn-default custom-button mx-1 btn-main confirm-btn" (click)="activeModal.close()">
        ปิด
      </button>
    </div>
  </div>
</form>

