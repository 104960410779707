<div class="card custom-box my-3 border-0">
  <form (ngSubmit)="search()">
    <div class="card-body">
      <div class="row mb-3">
        <label for="departmentCode" class="col-xl-2 col-form-label ">DEPARTMENT_CODE</label>
        <div class="col-xl-3">
          <input type="text" class="form-control" id="departmentCode" name="departmentCode"
                 [(ngModel)]="param().departmentCode">
        </div>
        <label for="departmentDesc" class="col-xl-2 col-form-label ">DEPARTMENT_DESC</label>
        <div class="col-xl-3">
          <input type="text" class="form-control" id="departmentDesc" name="departmentDesc"
                 [(ngModel)]="param().departmentDesc">
        </div>
      </div>
      <div class="row mb-3">
        <label for="ministryCode" class="col-xl-2 col-form-label ">MINISTRY_CODE</label>
        <div class="col-xl-3">
          <input type="text" class="form-control" id="ministryCode" name="ministryCode"
                 [(ngModel)]="param().ministryCode">
        </div>
        <label class="col-xl-2 col-form-label">สถานะการใช้งาน</label>
        <div class="col-xl-3">
          <select class="form-control col-lg-5" name="used" [(ngModel)]="param().used">
            <option [ngValue]="undefined">ทั้งหมด</option>
            <option [ngValue]="'Y'">ใช้งาน</option>
            <option [ngValue]="'N'">ไม่ใช้งาน</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="text-center">
          <button type="submit" class="btn btn-primary me-2">
            <i class="bi bi-search me-1"></i>
            ค้นหา
          </button>
          <button type="reset" class="btn btn-default me-2" (click)="clear()">
            เคลียร์ข้อมูล
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="card custom-box mb-3 border-0">
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-striped table-borderless caption-top">
        <caption>
          แสดง {{ (pageData()?.content?.length ? (pageData()?.number * pageData()?.size) + 1 : 0) | number }}
          ถึง {{ ((pageData()?.number) * pageData()?.size + pageData()?.content?.length) | number }}
          จากทั้งหมด {{ pageData()?.totalElements | number }} รายการ
          <button class="btn btn-info float-end" (click)="openModelAddOrEdit('เพิ่ม',{})">
            <i class="bi bi-plus-lg me-1"></i>
            เพิ่มข้อมูล
          </button>
        </caption>
        <thead class="color-black font-weight-light">
        <tr class="table-light text-center" style="background-color: #eeeeee;">
          <th nowrap scope="col" class="">DEPARTMENT_CODE</th>
          <th nowrap scope="col" class="">DEPARTMENT_DESC</th>
          <th nowrap scope="col" class="">MINISTRY_CODE</th>
          <th nowrap scope="col" class="">สถานะการใช้งาน</th>
          <th nowrap scope="col" class="">ดำเนินการ</th>
        </tr>
        </thead>
        <tbody class="table-borderless">
        <tr *ngIf="!pageData()?.content?.length">
          <td class="text-muted text-center" colspan="5">
            <app-not-found></app-not-found>
          </td>
        </tr>
          @for (item of pageData()?.content; let i = $index; track item.id) {
            <tr>
              <td class="text-center">{{ item.departmentCode }}</td>
              <td class="">{{ item.departmentDesc }}</td>
              <td class="text-center">{{ item.ministryCode }}</td>
              <td class="text-center">{{ item.used == 'Y' ? 'ใช้งาน' : 'ไม่ใช้งาน' }}</td>
              <td class="text-center">
                <button class="btn btn-default" (click)="openModelAddOrEdit('แก้ไข',item)">
                  <i class="bi bi-pencil"></i> แก้ไข
                </button>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
    <ngb-pagination class="d-flex justify-content-center"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    [page]="pageData()?.number + 1"
                    (pageChange)="search($event)"
                    [pageSize]="pageData()?.size"
                    [collectionSize]="pageData()?.totalElements"></ngb-pagination>
  </div>
</div>
