import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";
import {Observable} from "rxjs";
import {Page, EmailNotificationQuotaDto} from "../../../../generated-model/model";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class EmailNotificationQuotaResource {

  http = inject(HttpClient);


  search(params: IgnoreNullHttpParams): Observable<Page<EmailNotificationQuotaDto>> {
    return this.http.get<Page<EmailNotificationQuotaDto>>(`${environment.serverUrl}/api/email-notification-quota/search`, {params: params.toHttpParam()});
  }


  save(item: EmailNotificationQuotaDto): Observable<EmailNotificationQuotaDto> {
    return this.http.post<EmailNotificationQuotaDto>(`${environment.serverUrl}/api/email-notification-quota`, item);
  }

  delete(id: number) {
    return this.http.delete(`${environment.serverUrl}/api/email-notification-quota/delete/${id}`);
  }
  existByEmail(email: string) {
    let param = IgnoreNullHttpParams.fromObject({email: email}).toHttpParam()
    return this.http.get<boolean>(`${environment.serverUrl}/api/email-notification-quota/exist/email` , {params: param})
  }


}
