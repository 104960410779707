import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {HmainOp, Province, ZoneDto} from "../../../../generated-model/model";
import {environment} from "../../../../environments/environment";
import {IgnoreNullHttpParams} from "../Ignore-null-http-params";

@Injectable({
  providedIn: 'root'
})
export class HmainOpResource {
  http = inject(HttpClient)

  constructor() {
  }

  public search(term: string, zone?: ZoneDto, province?: Province): Observable<HmainOp[]> {
    return this.http.get<HmainOp[]>(`${environment.serverUrl}/api/hmain-op`, {
      params: IgnoreNullHttpParams.fromObject({
        term: term,
        zoneId: zone?.id,
        provinceId: province?.id,
      }).toHttpParam()
    });
  }
}
