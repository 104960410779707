<div class="card custom-box mb-3 border-0">
  <div class="card-body">
    <div class="row justify-content-center mt-4">
      <img src="/assets/images/nhso.png" class="logo-top">
    </div>
    <div class="row mt-4">
      <span class="text-center">
         <h3>จัดการข้อมูลพื้นฐาน และข่าวประชาสัมพันธ์</h3>
      </span>
    </div>
    <div class="row justify-content-center mt-4">
      <h5>
        โปรแกรมสำหรับการจัดการพื้นฐานต่างๆ อาทิ
      </h5>
      <span>
        <ul>
          <li>
            <h5>ข้อมูลสิทธิหลัก (MAININSCL)</h5>
          </li>
          <li>
            <h5>ข้อมูลประเภทสิทธิย่อยตามช่วงอายุ</h5>
          </li>
          <li>
            <h5>ข้อมูล CATM</h5>
          </li>
          <li>
            <h5>ข้อมูลหน่วยงาน (DEPARTMENT_NEW)</h5>
          </li>
        </ul>
      </span>
    </div>
    <div class="row justify-content-center mt-4">
      <h5>
        และข่าวประชาสัมพันธ์ที่ปรากฏอยู่ในระบบต่าง ๆ หน้าข่าวประชาสัมพันธ์ อาทิ
      </h5>
      <span>
        <ul>
          <li>
            <h5>ระบบลงทะเบียนสำนักงานหลักประกันสุขภาพแห่งชาติ(SRM)</h5>
          </li>
          <li>
            <h5>ระบบตรวจสอบและยืนยันการเข้ารับบริการ(New Authen Code)</h5>
          </li>
        </ul>
      </span>
    </div>
  </div>
</div>
