import {Directive, forwardRef, inject} from '@angular/core';
import {AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors} from "@angular/forms";
import {Observable, of} from "rxjs";
import {EmailNotificationQuotaResource} from "./email-notification-quota-resource";
import {map} from "rxjs/operators";

@Directive({
  selector: '[appEmailUnique]',
  standalone: true,
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => EmailUniqueDirective), multi: true}],

})
export class EmailUniqueDirective implements AsyncValidator {

  emailNotificationQuotaResource = inject(EmailNotificationQuotaResource)

  validate(control: AbstractControl): Observable<ValidationErrors | null>  {
    if (!control.value) {
      return of(null)
    }
    return this.emailNotificationQuotaResource.existByEmail(control.value).pipe(map(e => {
      if (!e) {
        return null
      } else {
        return {existEmail: 'exist email'}
      }
    }))
  }

}
