<div class="modal-header ">
  <strong class="text-primary bold fs-5 d-inline-block">{{ prefix }}ข้อมูล</strong>
</div>
<div class="modal-body">
  <div class="mb-3 row">
    <label for="mapInscl" class="col-xl-3 ps-xl-4 col-form-label ui-require text-end">MAP_INSCL</label>
    <div class="col-xl-6">
      <select class="form-select" id="mapInscl" name="mapInscl">
        <option [ngValue]="undefined">กรุณาเลือก</option>
      </select>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="curInscl" class="col-xl-3 ps-xl-4 col-form-label ui-require text-end">CUR_INSCL</label>
    <div class="col-xl-6">
      <select class="form-select" id="curInscl" name="curInscl">
        <option [ngValue]="undefined">กรุณาเลือก</option>
      </select>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="ofcInscl" class="col-xl-3 col-form-label ui-require text-end">OFC_INSCL</label>
    <div class="col-xl-6">
      <select class="form-select" id="ofcInscl" name="ofcInscl">
        <option [ngValue]="undefined">กรุณาเลือก</option>
      </select>
    </div>
  </div>
</div>
<div class="row mt-3 mb-3">
  <div class="col-12 text-end">
    <button type="button" class="btn btn-primary custom-button mx-1 btn-main confirm-btn" (click)="save()">
      {{ prefix }}ข้อมูล
    </button>
    <button type="button" class="btn btn-default custom-button mx-1 btn-main confirm-btn" (click)="activeModal.close()">
      ปิด
    </button>
  </div>
</div>
