import {Component, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmDialogService} from "../../../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {ToastService} from "../../../../../shared/commons/toast/toast-service";
import {filter} from "rxjs/operators";
import {FormsModule} from "@angular/forms";
import {DigitOnlyModule} from "@uiowa/digit-only";
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {NotFoundComponent} from "../../../../../shared/not-found/not-found.component";
import {Page} from "../../../../../../generated-model/model";

@Component({
  selector: 'app-request-management-modal',
  standalone: true,
  imports: [
    DigitOnlyModule,
    FormsModule,
    DecimalPipe,
    NgForOf,
    NgIf,
    NgbPagination,
    NotFoundComponent
  ],
  templateUrl: './request-management-modal.component.html',
  styleUrl: './request-management-modal.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class RequestManagementModalComponent {
  prefix = '';
  pageData: Page<any> = {size: 10};

  constructor(public activeModal: NgbActiveModal,
              private confirmDialogService: ConfirmDialogService,
              private toastService: ToastService,
  ) {

  }

  save() {
    this.confirmDialogService.open({content: 'ยืนยันการบันทึกข้อมูล'})
      .pipe(filter(e => !!e))
      .subscribe({
        next: e => {
        }
      });
  }
}
