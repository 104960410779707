import {Routes} from "@angular/router";
import {LayoutComponent} from "./pages/layout/layout.component";
import {P403Component} from "./shared/commons/interceptor/error/403.component";
import {P404Component} from "./shared/commons/interceptor/error/404.component";
import {GroupRegistrationComponent} from "./pages/master/group-registration/group-registration.component";
import {LimitServiceComponent} from "./pages/master/limit-service/limit-service.component";
import {QuotaHsubHmainopComponent} from "./pages/master/quota-hsub-hmainop/quota-hsub-hmainop.component";
import {CountSelectComponent} from "./pages/master/count-select/count-select.component";
import {CatmComponent} from "./pages/master/catm/catm.component";
import {DepartmentNewComponent} from "./pages/master/department-new/department-new.component";
import {MainInsclComponent} from "./pages/master/main-inscl/main-inscl.component";
import {SubInsclComponent} from "./pages/master/sub-inscl/sub-inscl.component";
import {NewsComponent} from "./pages/news/news.component";
import {AutoLoginPartialRoutesGuard} from "angular-auth-oidc-client";
import {LandingComponent} from "./pages/landing/landing.component";
import {RelationComponent} from "./pages/master/relation/relation.component";
import {QuotaPerDayComponent} from "./pages/master/quota-per-day/quota-per-day.component";
import {RequestManagementComponent} from "./pages/master/request-management/request-management.component";
import {UnregisterMastercupComponent} from "./pages/master/unregister-mastercup/unregister-mastercup.component";
import {MapInsclComponent} from "./pages/master/map-inscl/map-inscl.component";
import {CallbackComponent} from "./security/callback/callback.component";
import {EmailNotificationQuotaComponent} from "./pages/master/email-notification-quota/email-notification-quota.component";


export const routes: Routes = [
  {
    path: '', pathMatch:'full', redirectTo:'master'
  },
  {
    path: 'callback', component: CallbackComponent,
  },
  {
    path: 'master',
    component: LayoutComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'news'
      },
      {
        path: 'landing',
        component: LandingComponent
      },
      {
        path: 'news',
        component: NewsComponent,
        data: {title: 'จัดการข่าวประชาสัมพันธ์'}
      },
      {
        path: 'main-inscl',
        component: MainInsclComponent,
        data: {title: 'ข้อมูลสิทธิหลัก (MAININSCL)'}
      },
      /* {
        path: 'inscl',
        component: InsclComponent,
        data: {title: 'ข้อมูลสิทธิย่อย (SUBINSCL)'}
      },*/
      {
        path: 'group-registration',
        component: GroupRegistrationComponent,
        data: {title: 'หน่วยบริการที่ลงทะเบียนแบบกลุ่ม'}
      },
      {
        path: 'limit-service',
        component: LimitServiceComponent,
        data: {title: 'เครือข่ายหน่วยบริการที่ไม่ต้องการให้เห็นในช่องทางลงทะเบียนที่กำหนด'}
      },
      {
        path: 'range-sub-inscl-type',
        component: SubInsclComponent,
        data: {title: 'ประเภทสิทธิย่อยตามช่วงอายุ'}
      },
      {
        path: 'quota',
        component: QuotaHsubHmainopComponent,
        data: {title: 'กำหนดจำนวน Quota ผู้ลงทะเบียนแยกรายหน่วยบริการปฐมภูมิ ( HSUB ) ที่อยู่กับหน่วยบริาการประจำ ( HMAIN_OP ) คนละรหัส'}
      },
      {
        path: 'catm',
        component: CatmComponent,
        data: {title: 'จัดการข้อมูล CATM'}
      },
      {
        path: 'count-select',
        component: CountSelectComponent,
        data: {title: 'จัดการข้อมูลจำนวนครั้งในการลงทะเบียน (Count Select)'}
      },
      {
        path: 'department-new',
        component: DepartmentNewComponent,
        data: {title: 'จัดการข้อมูลหน่วยงาน (DEPARTMENT_NEW)'}
      },
      {
        path: 'relation',
        component: RelationComponent,
        data: {title: 'จัดการความสัมพันธ์ผู้ลงทะเบียนกับผู้ปกครองหรือ ผู้รับรอง'}
      },
      {
        path: 'quota-per-day',
        component: QuotaPerDayComponent,
        data: {title: 'จัดการจำนวน Quota การตรวจสอบสิทธิต่อวัน ตามประเภทของหน่วย'}
      },
      {
        path: 'request-management',
        component: RequestManagementComponent,
        data: {title: 'จัดการหน่วยรับคำขอ'}
      },
      {
        path: 'unregister-mastercup',
        component: UnregisterMastercupComponent,
        data: {title: 'จัดการเครือข่ายหน่วยบริการที่ไม่อนุญาตให้ลงทะเบียนตามมารดา'}
      },
      {
        path: 'map-inscl',
        component: MapInsclComponent,
        data: {title: 'จัดการข้อมูลการ MAP สิทธิย่อย'}
      },
      {
        path: 'email-notification-quota',
        component: EmailNotificationQuotaComponent,
        data: {title: 'ตั้งค่าอีเมลแจ้งเตือน Quota การตรวจสอบสิทธิ'}
      },
    ]
  },
  {
    path: '403',
    component: P403Component,
  },
  {
    path: '**',
    component: P404Component,
  }
];
