import {Component} from '@angular/core';
import {Page, RightDto, RightSearchParam, Used} from "../../../../generated-model/model";
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";
import {from} from "rxjs";
import {filter} from "rxjs/operators";
import {MainInsclModalComponent} from "./modal/main-inscl-modal/main-inscl-modal.component";
import {MainInsclService} from "./main-inscl.service";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";

@Component({
  selector: 'app-main-inscl',
  standalone: true,
  imports: [
    DecimalPipe,
    NgbPagination,
    ReactiveFormsModule,
    NgIf,
    NgForOf,
    FormsModule,
    NotFoundComponent
  ],
  templateUrl: './main-inscl.component.html',
  styleUrl: './main-inscl.component.scss'
})
export class MainInsclComponent {
  searchParam: RightSearchParam & { page?: number } = {}
  pageData: Page<RightDto> = {size: 10};
  protected readonly Used = Used;

  constructor(private mainInsclService: MainInsclService,
              private modalService: NgbModal) {
    this.search()
  }

  search(page: number = 1) {
    let param = IgnoreNullHttpParams.fromObject(
      {...this.searchParam})
      .set("page", page - 1)
      .set("size", this.pageData.size)
    this.mainInsclService.search(param).subscribe({
        next: e => {
          this.pageData = e
        }
      }
    )
  }

  openModelAdd(prefix: string) {
    const modalRef = this.modalService.open(MainInsclModalComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.prefix = prefix
  }

  openModelEdit(prefix: string, item: RightDto) {
    const modalRef = this.modalService.open(MainInsclModalComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.right = {...item}
    modalRef.componentInstance.prefix = prefix
    from(modalRef.result).pipe(filter(e => !!e))
      .subscribe(e => {
        this.search(1)
      });
  }
}
