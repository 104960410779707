import {Component, ViewEncapsulation} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmDialogService} from "../../../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {ToastService} from "../../../../../shared/commons/toast/toast-service";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-group-registration-modal',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './group-registration-modal.component.html',
  styleUrl: './group-registration-modal.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class GroupRegistrationModalComponent {
  prefix = '';

  constructor(public activeModal: NgbActiveModal,
              private confirmDialogService: ConfirmDialogService,
              private toastService: ToastService,
  ) {
  }

  save() {
    this.confirmDialogService.open({content: 'ยืนยันการบันทึกข้อมูล'})
      .pipe(filter(e => !!e))
      .subscribe({
        next: e => {
        }
      });
  }

}
