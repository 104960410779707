import {Component} from '@angular/core';
import {DecimalPipe} from "@angular/common";
import {NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {Page} from "../../../../generated-model/model";
import {ReactiveFormsModule} from "@angular/forms";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";

@Component({
  selector: 'app-address-type',
  standalone: true,
  imports: [
    DecimalPipe,
    NgbPagination,
    ReactiveFormsModule,
    NotFoundComponent
  ],
  templateUrl: './address-type.component.html',
  styleUrl: './address-type.component.scss'
})
export class AddressTypeComponent {
  pageData: Page<any> = {size: 10};

  search(page: number = 1) {

  }
}
