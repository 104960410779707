<div class="modal-header">
  <strong class="text-primary bold fs-5 d-inline-block">{{ prefix }} DEPARTMENT_NEW</strong>
</div>
<div class="modal-body">
  <form class="form-validate" #form="ngForm">
    <div class="row mb-3">
      <label for="departmentCode" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">DEPARTMENT_CODE</label>
      <div class="col-xl-3">
        <input type="text" class="form-control" id="departmentCode" name="departmentCode"
               [(ngModel)]="item().departmentCode" required #code="ngModel">
        @if (code.invalid && (code.dirty || code.touched)) {
          <custom-error *ngIf="code.hasError('required')" error="*จำเป็น"></custom-error>
        }
      </div>
      <label for="departmentDesc" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">DEPARTMENT_DESC</label>
      <div class="col-xl-3">
        <input type="text" class="form-control" id="departmentDesc" name="departmentDesc"
               [(ngModel)]="item().departmentDesc" required #dese="ngModel">
        @if (dese.invalid && (dese.dirty || dese.touched)) {
          <custom-error *ngIf="dese.hasError('required')" error="*จำเป็น"></custom-error>
        }
      </div>
    </div>
    <div class="row mb-3">
      <label for="ministryCode" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">MINISTRY_CODE</label>
      <div class="col-xl-3">
        <input type="text" class="form-control" id="ministryCode" name="ministryCode" [(ngModel)]="item().ministryCode"
               required #ministryCode="ngModel">
        @if (ministryCode.invalid && (ministryCode.dirty || ministryCode.touched)) {
          <custom-error *ngIf="ministryCode.hasError('required')" error="*จำเป็น"></custom-error>
        }
      </div>
      <label for="departmentAbbr" class="col-xl-2 ps-xl-4 col-form-label text-end">DEPARTMENT_ABBR</label>
      <div class="col-xl-3">
        <input type="text" class="form-control" id="departmentAbbr" name="departmentAbbr"
               [(ngModel)]="item().departmentAbbr">
      </div>
    </div>
    <div class="row mb-3">
      <label for="orinalCode" class="col-xl-2 ps-xl-4 col-form-label text-end">ORINAL_CODE</label>
      <div class="col-xl-3">
        <input type="text" class="form-control" id="orinalCode" name="orinalCode"
               [(ngModel)]="item().orinalCode" maxlength="5" #orinalCode=ngModel>
        @if (orinalCode.invalid && (orinalCode.dirty || orinalCode.touched)) {
          <custom-error *ngIf="orinalCode.hasError('maxlength')" error="*ความยาวต้องไม่เกิน 5"></custom-error>
        }
      </div>
      <label for="orinalType" class="col-xl-2 ps-xl-4 col-form-label text-end" >ORINAL_TYPE</label>
      <div class="col-xl-3">
        <select class="form-select" id="orinalType" name="orinalType" [(ngModel)]="item().orinalType">
          @for (item of orinalTypes(); let i = $index ;track item){
            <option [ngValue]="item">{{item}}</option>
          }
        </select>
      </div>
    </div>
    <div class="row mb-3">
      <label class="col-xl-2 col-form-label ui-require text-end">สถานะการใช้งาน</label>
      <div class="col-xl-3">
        <select class="form-select col-lg-5" name="used" [(ngModel)]="item().used" required #used="ngModel">
          <option [ngValue]="undefined">กรุณาเลือก</option>
          <option [ngValue]="'Y'">ใช้งาน</option>
          <option [ngValue]="'N'">ไม่ใช้งาน</option>
        </select>
        @if (used.invalid && (used.dirty || dese.touched)) {
          <custom-error *ngIf="used.hasError('required')" error="*จำเป็น"></custom-error>
        }
      </div>
    </div>
    <div class="row mt-3 mb-3">
      <div class="col-12 text-end">
        <button type="button" class="btn btn-primary custom-button mx-1 btn-main confirm-btn" (click)="save()">
          {{ prefix }} DEPARTMENT_NEW
        </button>
        <button type="button" class="btn btn-default custom-button mx-1 btn-main confirm-btn"
                (click)="activeModal.close()">
          ปิด
        </button>
      </div>
    </div>
  </form>
</div>

