<div class="modal-header">
  <strong class="text-primary bold fs-5 d-inline-block">{{prefix}}หน่วยรับคำขอ</strong>
</div>
<div class="modal-body">
  <div class="mb-3 row">
    <label for="type" class="col-xl-4 ps-xl-4 col-form-label ui-require text-end">ประเภทหน่วยรับคำขอ</label>
    <div class="col-xl-4">
      <select class="form-control col-lg-4" id="type" name="type">
        <option [ngValue]="undefined">กรุณาเลือก</option>
      </select>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="province" class="col-xl-4 ps-xl-4 col-form-label ui-require text-end">จังหวัด</label>
    <div class="col-xl-4">
      <select class="form-control col-lg-4" id="province" name="province">
        <option [ngValue]="undefined">กรุณาเลือก</option>
      </select>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="district" class="col-xl-4 ps-xl-4 col-form-label ui-require text-end">อำเภอ / เขต</label>
    <div class="col-xl-4">
      <select class="form-control col-lg-4" id="district" name="district">
        <option [ngValue]="undefined">กรุณาเลือก</option>
      </select>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="subDistrict" class="col-xl-4 ps-xl-4 col-form-label ui-require text-end">ตำบล / แขวง</label>
    <div class="col-xl-4">
      <select class="form-control col-lg-4" id="subDistrict" name="subDistrict">
        <option [ngValue]="undefined">กรุณาเลือก</option>
      </select>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="input" class="col-xl-4 ps-xl-4 col-form-label text-end">รหัส/ชื่อ</label>
    <div class="col-xl-4">
      <input type="text" class="form-control" id="input" name="input">
    </div>
  </div>
  <div class="mb-3 row">
    <div class="col-12 text-center">
      <button type="submit" class="btn btn-primary me-2">
        <i class="bi bi-search me-1"></i>
        ค้นหา
      </button>
      <button type="reset" class="btn btn-default me-2">
        เคลียร์ข้อมูล
      </button>
    </div>
  </div>
  <div class="mb-3 row">
    <div class="table-responsive">
      <table class="table table-striped table-borderless caption-top">
        <caption>
          แสดง {{ (pageData?.content?.length ? (pageData?.number * pageData?.size) + 1 : 0) | number }}
          ถึง {{ ((pageData?.number) * pageData?.size + pageData?.content?.length) | number }}
          จากทั้งหมด {{ pageData?.totalElements | number }} รายการ
        </caption>
        <thead class="color-black font-weight-light">
        <tr class="table-light text-center" style="background-color: #eeeeee;">
          <th nowrap scope="col" class="">ลำดับ</th>
          <th nowrap scope="col" class="">รหัส/ชื่อ</th>
          <th nowrap scope="col" class="">ตำบล/แขวง</th>
          <th nowrap scope="col" class="">อำเภอ/เขต</th>
          <th nowrap scope="col" class="">จังหวัด</th>
        </tr>
        </thead>
        <tbody class="table-borderless">
        <tr *ngIf="!pageData?.content?.length">
          <td class="text-muted text-center" colspan="5">
            <app-not-found></app-not-found>
          </td>
        </tr>
        <tr *ngFor="let item of pageData?.content; let i= index">
          <td class="text-center"></td>
          <td class="text-center"></td>
          <td class="text-center"></td>
          <td class="text-center"></td>
          <td class="text-center">
            <button class="btn btn-default">
              <i class="bi bi-pencil"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <ngb-pagination class="d-flex justify-content-center"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    [page]="pageData?.number + 1"
                    
                    [pageSize]="pageData?.size"
                    [collectionSize]="pageData?.totalElements"></ngb-pagination>
  </div>
</div>
<div class="row mt-3 mb-3">
  <div class="col-12 text-end">
    <button type="button" class="btn btn-primary custom-button mx-1 btn-main confirm-btn" (click)="save()">
      {{prefix}}หน่วยรับคำขอ
    </button>
    <button type="button" class="btn btn-default custom-button mx-1 btn-main confirm-btn" (click)="activeModal.close()">
      ปิด
    </button>
  </div>
</div>
