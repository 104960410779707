<div class="modal-header ">
  <strong class="text-primary bold fs-5 d-inline-block">{{ prefix }}ข้อมูลความสัมพันธ์ผู้ลงทะเบียนกับผู้รับมอบอำนาจ</strong>
</div>
<div class="modal-body">
  <div class="mb-3 row">
    <label for="name" class="col-xl-3 ps-xl-4 col-form-label ui-require text-end">RELATION_NAME</label>
    <div class="col-xl-6">
      <input type="text" id="name" name="name" class="form-control">
    </div>
  </div>
  <div class="mb-3 row">
    <label for="code" class="col-xl-3 ps-xl-4 col-form-label text-end">CODE</label>
    <div class="col-xl-3">
      <input type="text" id="code" name="code" class="form-control">
    </div>
  </div>
  <div class="mb-3 row">
    <label for="used" class="col-xl-3 col-form-label text-end">สถานะ</label>
    <div class="col-xl-3">
      <div class="col-auto">
        <select class="form-select" id="used" name="used">
          <option selected [ngValue]="'Y'">ใช้งาน</option>
          <option [ngValue]="'N'">ยกเลิก</option>
        </select>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3 mb-3">
  <div class="col-12 text-end">
    <button type="button" class="btn btn-primary custom-button mx-1 btn-main confirm-btn" (click)="save()">
      {{ prefix }}ข้อมูล
    </button>
    <button type="button" class="btn btn-default custom-button mx-1 btn-main confirm-btn" (click)="activeModal.close()">ปิด</button>

  </div>
</div>
