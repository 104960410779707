import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {MapInscl, MapInsclSearchParam, Page} from "../../../../generated-model/model";
import {environment} from "../../../../environments/environment";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";
import {PageRequest} from "../../../app.constants";

@Injectable({
  providedIn: 'root'
})
export class MapInsclService {

  http = inject(HttpClient)

  search(param:MapInsclSearchParam,pageRequest: PageRequest){
    let httpParams = IgnoreNullHttpParams.fromObject({
      ...param,
      ...pageRequest
    }).toHttpParam();
    return this.http.get<Page<MapInscl>>(`${environment.serverUrl}/api/map-inscl/search`,{
      params:httpParams
    })
  }

  save(mapInscl:MapInsclSearchParam){
    return this.http.post(`${environment.serverUrl}/api/map-inscl`,{
      mapInscl: mapInscl.mapInscl.inscl,
      curInscl: mapInscl.curInscl.inscl,
      ofcInscl: mapInscl.ofcInscl.inscl,
    })
  }
}
