import {Component} from '@angular/core';
import {Page} from "../../../../generated-model/model";
import {NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {DecimalPipe} from "@angular/common";
import {SharedCommonModule} from "../../../shared/commons/shared-common.module";
import {FormsModule} from "@angular/forms";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";

@Component({
  selector: 'app-limit-service',
  standalone: true,
  imports: [
    NgbPagination,
    DecimalPipe,
    SharedCommonModule,
    FormsModule,
    NotFoundComponent
  ],
  templateUrl: './limit-service.component.html',
  styleUrl: './limit-service.component.scss'
})
export class LimitServiceComponent {
  pageData: Page<any> = {size: 10};

  search(page: number = 1) {

  }
}
