<div>

<ng-select
  #ngSelectComponent
  [id]="id()"
  [name]="name()"
  [items]="insclList$ | async"
  [placeholder]="placeholder()"
  [disabled]="disabled()"
  [clearable]="true"
  [compareWith]="compareWithInscl"
  [editableSearchTerm]="true"
  [typeahead]="term$"
  [loading]="typeaheadLoading"
  (clear)="reset()"
  (ngModelChange)="onValueChange($event)"
  bindLabel="codeWithName"
  [(ngModel)]="value">
  <ng-template ng-option-tmp let-item="item">
    ({{item.inscl}}) {{item.insclName}}
  </ng-template>
</ng-select>
  <div *ngIf="error" class="text-danger small mt-1">
    {{ error }}
  </div>
</div>
