import {Component} from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";
import {CatmDto, Page} from "../../../../generated-model/model";
import {CatmModelComponent} from "../catm/model/catm-model/catm-model.component";
import {from} from "rxjs";
import {filter} from "rxjs/operators";
import {RequestManagementModalComponent} from "./modal/request-management-modal/request-management-modal.component";

@Component({
  selector: 'app-request-management',
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    NgForOf,
    NgIf,
    NgbPagination,
    NotFoundComponent
  ],
  templateUrl: './request-management.component.html',
  styleUrl: './request-management.component.scss'
})
export class RequestManagementComponent {
  pageData: Page<any> = {size: 10};

  constructor(private modalService: NgbModal) {
    this.search();
  }

  search(page: number = 1) {
  }

  openModelAdd(prefix: string) {
    const modalRef = this.modalService.open(RequestManagementModalComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.prefix = prefix
  }

  openModelEdit(prefix: string, item: any) {
    const modalRef = this.modalService.open(RequestManagementModalComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.catm = {...item}
    modalRef.componentInstance.prefix = prefix
    from(modalRef.result).pipe(filter(e => !!e))
      .subscribe(e => {
        this.search(1)
      });
  }

}
