<div class="card custom-box my-3 border-0">
  <form (ngSubmit)="search()">
    <div class="card-body">
      <div class="row my-3">
        <div class="col-xl-5 row">
          <label for="personalId" class="col-xl-5 col-form-label ">เลขประจำตัวประชาชน</label>
          <div class="col-xl-7">
            <input type="text" class="form-control" id="personalId" name="personalId" [(ngModel)]="personalId">
          </div>
        </div>
        <div class="col-xl-6 mt-3 mt-xl-0 ">
          <div class="text-end text-xl-start">
            <button type="submit" class="btn btn-primary me-2">
              <i class="bi bi-search me-1"></i>
              ค้นหา
            </button>
            <button type="reset" class="btn btn-default me-4" (click)="clear()">
              เคลียร์ข้อมูล
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="card custom-box mb-3 border-0">
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-striped table-borderless caption-top">
        <thead class="color-black font-weight-light">
        <tr class="table-light text-center" style="background-color: #eeeeee;">
          <th nowrap scope="col" class="">เลขประจำตัวประชาชน</th>
          <th nowrap scope="col" class="">ชื่อ</th>
          <th nowrap scope="col" class="">นามสกุล</th>
          <th nowrap scope="col" class="">จำนวนครั้งในการลงทะเบียนต่อปี</th>
          <th nowrap scope="col" class="">ดำเนินการ</th>
        </tr>
        </thead>
        <tbody class="table-borderless">
        <tr *ngIf="!personalFundDto()">
          <td class="text-muted text-center" colspan="6">
            <app-not-found></app-not-found>
          </td>
        </tr>
        <tr *ngIf="personalFundDto()">
          <td class="text-center">{{ personalFundDto().personalData?.personalId }}</td>
          <td class="text-center">{{ personalFundDto().personalData?.firstName }}</td>
          <td class="text-center">{{ personalFundDto().personalData?.lastName }}</td>
          <td class="text-center">{{ personalFundDto().countSelect }}</td>
          <td class="text-center">
            <button class="btn btn-default" (click)="openModalEditCountSelect(personalFundDto())">
              <i class="bi bi-pencil"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
