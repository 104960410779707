import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideRouter, withComponentInputBinding, withHashLocation, withInMemoryScrolling} from '@angular/router';

import {routes} from './app.routes';
import {provideClientHydration} from "@angular/platform-browser";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi} from "@angular/common/http";
import {AbstractLoggerService, AuthInterceptor, provideAuth} from "angular-auth-oidc-client";
import {AuthLoggerService} from "./security/auth-logger.service";
import {environment} from "../environments/environment";
import {FeatherModule} from "angular-feather";
import {allIcons} from "angular-feather/icons";
import {loadingInterceptor} from "./interceptors/loading_interceptor";
import {provideAnimations} from "@angular/platform-browser/animations";
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {Http5xxInterceptor} from "./shared/commons/interceptor/http5xx-interceptor";
import {Http404Interceptor} from "./shared/commons/interceptor/http404-interceptor";
import {provideQuillConfig} from "ngx-quill/config";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withHashLocation(),
      withInMemoryScrolling({scrollPositionRestoration: 'top'}),
      withComponentInputBinding()
    ),
    provideClientHydration(),
    provideHttpClient(
      // withFetch(),
      withInterceptors([
        loadingInterceptor,
      ]),
      withInterceptorsFromDi()
    ),
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: Http5xxInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: Http404Interceptor, multi: true},
    provideAnimations(),
    provideAnimationsAsync(),
    provideAuth({
      config: {
        secureRoutes: [environment.serverUrl, ''],
        authority: environment.oidcIssuerUrl,
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        clientId: environment.clientId,
        scope: 'openid profile email', // 'openid profile offline_access ' + your scopes
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        autoUserInfo: true,
        renewUserInfoAfterTokenRenew: true,
        ignoreNonceAfterRefresh: true,
        renewTimeBeforeTokenExpiresInSeconds: 60,
        startCheckSession: true,
        triggerRefreshWhenIdTokenExpired: true,
        logLevel: environment.ngOidcLogLevel,
      },
    }),
    provideQuillConfig({
      modules: {
        syntax: false,
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],

          [{'header': 1}, {'header': 2}],               // custom button values
          [{'list': 'ordered'}, {'list': 'bullet'}],
          [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
          [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
          [{'direction': 'rtl'}],                         // text direction

          [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
          [{'header': [1, 2, 3, 4, 5, 6, false]}],

          [{'color': []}, {'background': []}],          // dropdown with defaults from theme
          [{'font': []}],
          [{'align': []}],

          ['clean'],                                         // remove formatting button

          ['link', 'image']                         // link and image, video
        ]
      }
    }),
    {
      provide: AbstractLoggerService,
      useClass: AuthLoggerService
    },
    importProvidersFrom(FeatherModule.pick(allIcons))
  ]
};

