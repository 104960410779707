import {Component, inject, signal} from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";
import {Mode, Page, QuotaHsubHmainOpResponse, QuotaHsupHmainOpSearchParams} from "../../../../generated-model/model";
import {QuotaHsubHmainopModalComponent} from "./modal/quota-hsub-hmainop-modal/quota-hsub-hmainop-modal.component";
import {SelectZoneComponent} from "../../../shared/components/select-zone/select-zone.component";
import {
  ProvinceAutocompleteComponent
} from "../../../shared/components/province-autocomplete/province-autocomplete.component";
import {from} from "rxjs";
import {filter} from "rxjs/operators";
import {QuotaHsubHmainOpResource} from "../../../shared/commons/services/QuotaHsubHmainOpResource";
import {ToastService} from "../../../shared/commons/toast/toast-service";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";
import {
  HmainOpAutocompleteComponent
} from "../../../shared/components/hmain-op-autocomplete/hmain-op-autocomplete.component";
import {HsubAutocompleteComponent} from "../../../shared/components/hsub-autocomponent/hsub-autocomplete.component";

@Component({
  selector: 'app-quota-hsub-hmainop',
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    NgForOf,
    NgIf,
    NgbPagination,
    NotFoundComponent,
    ReactiveFormsModule,
    SelectZoneComponent,
    ProvinceAutocompleteComponent,
    HmainOpAutocompleteComponent,
    HsubAutocompleteComponent
  ],
  templateUrl: './quota-hsub-hmainop.component.html',
  styleUrl: './quota-hsub-hmainop.component.scss'
})
export class QuotaHsubHmainopComponent {
  pageData = signal<Page<QuotaHsubHmainOpResponse>>({size: 10});
  searchParams: QuotaHsupHmainOpSearchParams = {}
  quotaHsupHmainOpResource = inject(QuotaHsubHmainOpResource)
  toastService = inject(ToastService)

  constructor(private modalService: NgbModal) {
    this.search();
  }

  search(page: number = 1) {
    let params = IgnoreNullHttpParams.fromObject({...this.searchParams})
      .set("page", page - 1)
      .set("size", this.pageData().size)
      .set("sort", "createdDate,desc")
    this.quotaHsupHmainOpResource.search(params).subscribe(resp => {
      this.pageData.set(resp)
    })
  }

  openModelAdd(prefix: string,mode:Mode, id: number) {
    const modalRef = this.modalService.open(QuotaHsubHmainopModalComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.prefix = prefix
    modalRef.componentInstance.itemId = id
    modalRef.componentInstance.mode = mode
    from(modalRef.result).pipe(filter(e => !!e))
      .subscribe(e => {
        if (e) {
          this.quotaHsupHmainOpResource.save(e).subscribe(
            next => {
              this.toastService.showSuccess(prefix + 'ข้อมูลสำเร็จ')
              this.searchParams = {}
              this.search(1)
            }
          )
        }
      });
  }

  clear() {
    this.searchParams = {}
    this.search(1)
  }
}
