<div class="card custom-box my-3 border-0">
  <form (ngSubmit)="search()">
    <div class="card-body">
      <div class="row mb-3">
        <label for="zone" class="col-xl-2 col-form-label ">เขต</label>
        <div class="col-xl-3">
          <app-select-zone id="zone" name="zone" [(ngModel)]="searchParams.zone"></app-select-zone>
        </div>
        <label for="province" class="col-xl-2 col-form-label ">จังหวัด</label>
        <div class="col-xl-3">
          <app-province-autocomplete id="province" name="province"
                                     [(ngModel)]="searchParams.province"
                                     [restrictZone]="searchParams.zone">

          </app-province-autocomplete>
        </div>
      </div>
      <div class="row mb-3">
        <label for="hmainOp" class="col-xl-2 col-form-label ">หน่วยบริการประจำ</label>
        <div class="col-xl-3">
          <app-hmain-op-autocomplete id="hmainOp" name="hmainOp" [(ngModel)]="searchParams.hmainOp"
                                     [restrictZone]="searchParams.zone"
                                     [restrictProvince]="searchParams.province">

          </app-hmain-op-autocomplete>
        </div>
        <label for="hsub" class="col-xl-2 col-form-label ">หน่วยบริการปฐมภูมิ</label>
        <div class="col-xl-3">
          <app-hsub-autocomponent id="hsub" name="hsub" [(ngModel)]="searchParams.hsub"
                                  [restrictZone]="searchParams.zone"
                                  [restrictProvince]="searchParams.province"
                                  [restrictHmainOp]="searchParams.hmainOp">

          </app-hsub-autocomponent>
        </div>
      </div>
      <div class="row">
        <div class="text-center">
          <button type="submit" class="btn btn-primary me-2">
            <i class="bi bi-search me-1"></i>
            ค้นหา
          </button>
          <button type="reset" class="btn btn-default me-2" (click)="clear()">
            เคลียร์ข้อมูล
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="card custom-box mb-3 border-0">
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-striped table-borderless caption-top">
        <caption>
          แสดง {{ (pageData()?.content?.length ? (pageData()?.number * pageData()?.size) + 1 : 0) | number }}
          ถึง {{ ((pageData()?.number) * pageData()?.size + pageData()?.content?.length) | number }}
          จากทั้งหมด {{ pageData()?.totalElements | number }} รายการ
          <button class="btn btn-info float-end" (click)="openModelAdd('เพิ่ม','CREATE',undefined)">
            <i class="bi bi-plus-lg me-1"></i>
            เพิ่มข้อมูล
          </button>
        </caption>
        <thead class="color-black font-weight-light">
        <tr class="table-light text-center" style="background-color: #eeeeee;">
          <th nowrap scope="col" class="">หน่วยบริการปฐมภูมิ</th>
          <th nowrap scope="col" class="">หน่วยบริการประจำ</th>
          <th nowrap scope="col" class="">เขต</th>
          <th nowrap scope="col" class="">จังหวัด</th>
          <th nowrap scope="col" class="">จำนวนสิทธิ์ที่ลงทะเบียนสูงสุด</th>
          <th nowrap scope="col" class="">ดำเนินการ</th>
        </tr>
        </thead>
        <tbody class="table-borderless">
          @for (item of pageData()?.content; let i = $index; track item.id) {
            <tr>
              <td class=""> {{ item.hsub?.hcode }} {{ item.hsub?.htitleHtitleName }}{{ item.hsub?.hname }}</td>
              <td class=""> {{ item.hmainOp?.hcode }} {{ item.hmainOp?.htitleHtitleName }}{{ item.hmainOp?.hname }}</td>
              <td class="text-center">{{ item.hsub?.province?.zone?.name }}</td>
              <td class="text-center">{{ item.hsub?.provinceName }}</td>
              <td class="text-center">{{ item.maxQuota }}</td>
              <td class="text-center">
                <button class="btn btn-default" (click)="openModelAdd('แก้ไข','UPDATE',item.id)">
                  <i class="bi bi-pencil"></i> แก้ไข
                </button>
              </td>
            </tr>
          } @empty {
            <tr>
              <td class="text-muted text-center" colspan="6">
                <app-not-found></app-not-found>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
    <ngb-pagination class="d-flex justify-content-center"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    [page]="pageData()?.number + 1"
                    (pageChange)="search($event)"
                    [pageSize]="pageData()?.size"
                    [collectionSize]="pageData()?.totalElements"></ngb-pagination>
  </div>
</div>


