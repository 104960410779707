import { Component } from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";
import {Page} from "../../../../generated-model/model";
import {MapInsclModalComponent} from "./modal/map-inscl-model/map-inscl-modal.component";

@Component({
  selector: 'app-map-inscl',
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    NgForOf,
    NgIf,
    NgbPagination,
    NotFoundComponent,
    ReactiveFormsModule
  ],
  templateUrl: './map-inscl.component.html',
  styleUrl: './map-inscl.component.scss'
})
export class MapInsclComponent {

  pageData: Page<any> = {size: 10};

  constructor(private modalService: NgbModal) {
    this.search();
  }

  search(page: number = 1) {
  }

  openModelAdd(prefix: string) {
    const modalRef = this.modalService.open(MapInsclModalComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.prefix = prefix
  }

}
