<div class="d-flex align-items-center justify-content-center vh-100">
  <div class="text-center">
    <h1 class="display-1 fw-bold">404</h1>
    <p class="fs-3">Page Not found.</p>
    <p class="lead">
      ไม่พบข้อมูลที่ค้นหา หรือหน้าจอที่ร้องขอไม่มีอยู่จริง
    </p>
    <button class="btn btn-light border" (click)="redirect()">
      กลับสู่หน้าหลัก
    </button>
  </div>
</div>