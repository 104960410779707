import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";
import {Observable} from "rxjs";
import {CatmDto, Page} from "../../../../generated-model/model";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CatmService {
  constructor(private http: HttpClient) {
  }

  search(params: IgnoreNullHttpParams): Observable<Page<CatmDto>> {
    return this.http.get<Page<CatmDto>>(`${environment.serverUrl}/api/catm/search`, {params: params.toHttpParam()});
  }


  save(item: CatmDto): Observable<CatmDto> {
    return this.http.post<CatmDto>(`${environment.serverUrl}/api/catm`, item);
  }

}
