import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ZoneDto} from "../../../../generated-model/model";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SelectZoneService {
  http = inject(HttpClient)

  findAll(){
    return this.http.get<ZoneDto[]>(`${environment.serverUrl}/api/zone`)
  }
}
