import {Component, inject, model, signal} from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";
import {PersonalFundResource} from "../../../shared/commons/services/PersonalFundResource";
import {PersonalFundDto} from "../../../../generated-model/model";
import {from} from "rxjs";
import {filter} from "rxjs/operators";
import {CountSelectModalComponent} from "./count-select-modal/count-select-modal.component";
import {ToastService} from "../../../shared/commons/toast/toast-service";

@Component({
  selector: 'app-count-select',
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    NgForOf,
    NgIf,
    NgbPagination,
    NotFoundComponent,
    ReactiveFormsModule
  ],
  templateUrl: './count-select.component.html',
  styleUrl: './count-select.component.scss'
})
export class CountSelectComponent {
  personalFundResource = inject(PersonalFundResource)
  personalId = model<string>()
  personalFundDto = signal(undefined as PersonalFundDto)
  toastService = inject(ToastService)

  constructor(private modalService: NgbModal) {
    this.search();
  }

  search() {
    console.log('search')
    this.personalFundResource.search(this.personalId()).subscribe(
      data => {
        this.personalFundDto.set(data)
      }
    )
  }

  clear() {
    this.personalId.set(undefined)
    this.search()
  }

  openModalEditCountSelect(item: PersonalFundDto) {
    const modalRef = this.modalService.open(CountSelectModalComponent, {
      windowClass: 'set-width-modal',
      size: 'lg'
    });
    modalRef.componentInstance.item = item
    from(modalRef.result).pipe(filter(e => !!e))
      .subscribe(e => {
        if (e) {
          this.personalFundResource.update(e.id, e.countSelect).subscribe(value => {
            this.toastService.showSuccess('บันทึกสำเร็จ')
          })
        }
      });
  }
}
