import {
  AfterViewInit,
  Component,
  effect,
  inject,
  input,
  model,
  OnDestroy,
  OnInit,
  Renderer2,
  signal,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule, NgForm} from "@angular/forms";
import {NgbActiveModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../../../../shared/not-found/not-found.component";

import {
  DistrictDto,
  MastercupDto,
  Page,
  Province,
  ProvinceDto,
  SubDistrict
} from "../../../../../../generated-model/model";
import {ConfirmDialogService} from "../../../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {ToastService} from "../../../../../shared/commons/toast/toast-service";
import {filter} from "rxjs/operators";
import {MastercupResource} from "../../../../../shared/service/MastercupResource";
import {BKK_PROVINCE_ID, markInvalidControlsAsDirtyAndTouched, SN, SP} from "../../../../../app.constants";
import {
  SelectMastercupDistrictComponent
} from "../../../../../shared/components/select-mastercup-district/select-mastercup-district.component";
import {
  SelectMastercupProvinceComponent
} from "../../../../../shared/components/select-mastercup-province/select-mastercup-province.component";
import {
  SelectMastercupSubdistrictComponent
} from "../../../../../shared/components/select-mastercup-subdistrict/select-mastercup-subdistrict.component";
import {SelectMooComponent} from "../../../../../shared/components/select-moo/select-moo.component";
import {CustomErrorComponent} from "../../../../../shared/commons/custom-error.component";

@Component({
  selector: 'app-unregister-mastercup-modal',
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    NgForOf,
    NgIf,
    NgbPagination,
    NotFoundComponent,
    SelectMastercupDistrictComponent,
    SelectMastercupProvinceComponent,
    SelectMastercupSubdistrictComponent,
    SelectMooComponent,
    CustomErrorComponent
  ],
  templateUrl: './unregister-mastercup-modal.component.html',
  styleUrl: './unregister-mastercup-modal.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class UnregisterMastercupModalComponent implements OnInit, AfterViewInit, OnDestroy {
  prefix = '';
  @ViewChild('form', {static: true}) public form: NgForm;
  restrictProvince = input<Province>(undefined);
  selectedSubDistrict = model<SubDistrict>(undefined);
  selectedDistrict = model<DistrictDto>(undefined);
  selectedProvince = model<ProvinceDto>(undefined);
  selectedMoo = model<string>(undefined);
  pageData = signal<Page<MastercupDto>>({size: 10});
  renderer = inject(Renderer2);
  mastercupResource = inject(MastercupResource)

  constructor(public activeModal: NgbActiveModal,
              private confirmDialogService: ConfirmDialogService,
              private toastService: ToastService,
  ) {
    effect(() => {
      this.selectedProvince.set(this.restrictProvince());
    }, {allowSignalWrites: true});
  }

  ngOnInit(): void {
  }

  updateFormAndValidity(form: NgForm): boolean {
    if (!form.valid) {
      markInvalidControlsAsDirtyAndTouched(form)
    }
    return form.valid
  }

  search(page: number = 1) {
    let formValid = this.updateFormAndValidity(this.form);
    if (formValid) {
      this.mastercupResource.searchByCatm(this.selectedSubDistrict().catm, page - 1, this.pageData().size,).subscribe(
        data => this.pageData.set(data)
      )
    } else {
      console.log('invalid form');
    }
  }

  ngAfterViewInit(): void {
  }

  onProvinceChange() {
    this.selectedDistrict.set(undefined);
    this.selectedSubDistrict.set(undefined);
  }

  onDistrictChange() {
    this.selectedSubDistrict.set(undefined);
  }

  provinceBkk() {
    let provinceId = this.selectedProvince()?.id;
    if (!provinceId) {
      return false;
    }
    return provinceId == BKK_PROVINCE_ID || provinceId.startsWith(SN) || provinceId.startsWith(SP);
  }

  ngOnDestroy(): void {
  }

  select(mastercup: MastercupDto) {
    this.confirmDialogService.open({content: 'ยืนยันการเพิ่มข้อมูล'})
      .pipe(filter(e => !!e))
      .subscribe({
        next: e => {
          this.activeModal.close(mastercup);
        }
      });
  }
}
