import {Directive, forwardRef, inject, Input} from '@angular/core';
import {AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors} from "@angular/forms";
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {CatmService} from "./catm.service";

@Directive({
  selector: '[appCatmUnique]',
  standalone: true,
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => CatmUniqueDirective), multi: true}],

})
export class CatmUniqueDirective implements AsyncValidator {

  catmService = inject(CatmService)
  @Input() originalValue : string = ''

  validate(control: AbstractControl): Observable<ValidationErrors | null>  {

    if(!control.value || control.value === this.originalValue) {
      return of(null)
    }
    return this.catmService.existCatm(control.value).pipe(map(e => {
      if (!e) {
        return null
      } else {
        return {existCatm: 'exist catm'}
      }
    }))
  }

}
