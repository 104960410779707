<div class="card mb-3">
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-striped table-borderless caption-top">
        <caption>
          แสดง {{ (pageData?.content?.length ? (pageData?.number * pageData?.size) + 1 : 0) | number }}
          ถึง {{ ((pageData?.number) * pageData?.size + pageData?.content?.length) | number }}
          จากทั้งหมด {{ pageData?.totalElements | number }} รายการ
          <button class="btn btn-info float-end" (click)="openModelAdd('เพิ่ม')">
            <i class="bi bi-plus-lg me-1"></i>
            เพิ่มข้อมูล
          </button>
        </caption>
        <thead>
        <tr class="text-center">
          <th>ลำดับ</th>
          <th>อีเมล</th>
          <th>ดำเนินการ</th>
        </tr>
        </thead>
        <tbody>
        <tbody class="table-borderless">
        <tr *ngIf="!pageData?.content?.length">
          <td class="text-muted text-center" colspan="4">
            <app-not-found></app-not-found>
          </td>
        </tr>
        <tr *ngFor="let item of pageData?.content; let i= index">
          <td class="text-center">{{ i + 1 }}</td>
          <td class="">{{ item.email }}</td>
          <td class="text-center">
            <button class="btn btn-default" (click)="openModelEdit('แก้ไข',item)">
              <i class="bi bi-pencil"></i>
            </button>
            <button class="btn btn-default ms-1" (click)="delete(item.id)">
              <i class="bi bi-trash"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <ngb-pagination class="d-flex justify-content-center"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    [page]="pageData?.number + 1"
                    (pageChange)="search($event)"
                    [pageSize]="pageData?.size"
                    [collectionSize]="pageData?.totalElements"></ngb-pagination>
  </div>
</div>

