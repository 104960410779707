import {Component, inject, ViewEncapsulation} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmDialogService} from "../../../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-map-inscl-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: 'map-inscl-modal.component.html',
  styleUrl: './map-inscl-modal.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class MapInsclModalComponent {
  prefix = ''
  activeModal = inject(NgbActiveModal)
  confirmDialogService = inject(ConfirmDialogService)

  save() {
    this.confirmDialogService.open({content: 'ยืนยันการบันทึกข้อมูล'})
      .pipe(filter(e => !!e))
      .subscribe({
        next: e => {
        }
      })
  }
}
