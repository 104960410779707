<div class="modal-header ">
  <strong class="text-primary bold fs-5 d-inline-block">{{prefix}} ประเภทสิทธิย่อยตามช่วงอายุ</strong>
</div>
<div class="modal-body">
  <div class="mb-3 row">
    <label for="inscl" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">INSCL</label>
    <div class="col-xl-3">
      <input type="text" id="inscl" name="inscl" class="form-control" [(ngModel)]="inscl.inscl">
    </div>
    <label for="insclName" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">INSCL_NAME</label>
    <div class="col-xl-3">
      <input type="text" id="insclName" name="insclName" class="form-control" [(ngModel)]="inscl.insclName">
    </div>
  </div>
  <div class="mb-3 row">
    <label for="insclDateExp" class="col-xl-2 ps-xl-4 col-form-label  text-end">INSCL_DATEEXP</label>
    <div class="col-xl-3">
      <input type="text" id="insclDateExp" name="insclDateExp" class="form-control" [(ngModel)]="inscl.insclDateExp">
    </div>
    <label for="mainInscl" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">MAININSCL</label>
    <div class="col-xl-3">
      <input type="text" id="mainInscl" name="mainInscl" class="form-control" [(ngModel)]="inscl.mainInscl">
    </div>
  </div>
  <div class="mb-3 row">
    <label for="status" class="col-xl-2 col-form-label text-end">สถานะ</label>
    <div class="col-xl-3">
      <div class="col-auto">
        <select class="form-select" id="status" name="status" [(ngModel)]="inscl.used">
          <option selected [ngValue]="'Y'">ใช้งาน</option>
          <option [ngValue]="'N'">ยกเลิก</option>
        </select>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3 mb-3">
  <div class="col-12 text-end">
    <button type="button" class="btn btn-primary custom-button mx-1 btn-main confirm-btn" (click)="save()">
      {{prefix}} INSCL
    </button>
    <button type="button" class="btn btn-default custom-button mx-1 btn-main confirm-btn" (click)="activeModal.close()">ปิด</button>

  </div>
</div>
