import {LogLevel} from "angular-auth-oidc-client";

export const environment = {
  production: false,
  serverUrl: "https://srm-master.nhso.geniustree.io",
  oidcIssuerUrl: 'https://sso.nhso.geniustree.io/realms/nhso',
  clientId: 'srm-master',
  logoutUrl: 'https://srm-master.nhso.geniustree.io/logout',
  srmPortalUrl: 'https://srm-portal.nhso.geniustree.io/secure',
  ngOidcLogLevel: LogLevel.None,
};
