<div class="card custom-box my-3 border-0">
  <form (ngSubmit)="search()">
    <div class="card-body">
      <div class="row mb-3">
        <label for="zone" class="col-xl-2 col-form-label ">เขต</label>
        <div class="col-xl-3">
          <app-select-zone id="zone" name="zone" [(ngModel)]="param.zone"></app-select-zone>
        </div>
        <label for="province" class="col-xl-2 col-form-label ">จังหวัด</label>
        <div class="col-xl-3">
          <app-province-autocomplete id="province" name="province" [(ngModel)]="param.province"
                                     [restrictZone]="param.zone"></app-province-autocomplete>
        </div>
      </div>
      <div class="row mb-3">
        <label for="hmainOp" class="col-xl-2 col-form-label ">หน่วยบริการประจำ</label>
        <div class="col-xl-3">
          <app-hmain-op-autocomplete id="hmainOp" name="hmainOp" [(ngModel)]="param.hmainOp"
                                     [restrictZone]="param.zone"
                                     [restrictProvince]="param.province"></app-hmain-op-autocomplete>
        </div>
        <label for="hsub" class="col-xl-2 col-form-label ">หน่วยบริการปฐมภมิ</label>
        <div class="col-xl-3">
          <app-hsub-autocomponent id="hsub" name="hsub" [(ngModel)]="param.hsub"
                                  [restrictZone]="param.zone"
                                  [restrictProvince]="param.province"
                                  [restrictHmainOp]="param.hmainOp"></app-hsub-autocomponent>
        </div>
      </div>
      <div class="row mb-3">
        <label for="mastercupId" class="col-xl-2 col-form-label ">รหัสเครือข่ายหน่วยบริการ</label>
        <div class="col-xl-3">
          <app-select-mastercup-id id="mastercupId" name="mastercupId" [(ngModel)]="param.mastercup"
                                   [restrictZone]="param.zone"
                                   [restrictProvince]="param.province"
                                   [restrictHmainOp]="param.hmainOp"
                                   [restrictHsub]="param.hsub"></app-select-mastercup-id>
        </div>
      </div>
      <div class="row">
        <div class="text-center">
          <button type="submit" class="btn btn-primary me-2">
            <i class="bi bi-search me-1"></i>
            ค้นหา
          </button>
          <button type="reset" class="btn btn-default me-2" (click)="clear()">
            เคลียร์ข้อมูล
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="card custom-box mb-3 border-0">
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-striped table-borderless caption-top">
        <caption>
          แสดง {{ (pageData()?.content?.length ? (pageData()?.number * pageData()?.size) + 1 : 0) | number }}
          ถึง {{ ((pageData()?.number) * pageData()?.size + pageData()?.content?.length) | number }}
          จากทั้งหมด {{ pageData()?.totalElements | number }} รายการ
          <button class="btn btn-info float-end" (click)="openModelAdd('เพิ่ม')">
            <i class="bi bi-plus-lg me-1"></i>
            เพิ่มข้อมูล
          </button>
        </caption>
        <thead class="color-black font-weight-light">
        <tr class="table-light text-center" style="background-color: #eeeeee;">
          <th nowrap scope="col" rowspan="5">#</th>
          <th nowrap scope="col" rowspan="5" class="">รหัสเครือข่ายหน่วยบริการ</th>
          <th nowrap scope="col" colspan="5">CATM</th>
          <th nowrap scope="col" colspan="2">หน่วยบริการปฐมภูมิ (HSUB)</th>
          <th nowrap scope="col" colspan="2">หน่วยบริการประจำ (HMAIN_OP)</th>
          <th nowrap scope="col" colspan="2">หน่วยบริการที่รับส่งต่อ (HMAIN)</th>
          <th nowrap scope="col" rowspan="2">ดำเนินการ</th>
        </tr>
        <tr class="color-black text-center">
          <th nowrap scope="col" class="">รหัส</th>
          <th nowrap scope="col" class="">จังหวัด</th>
          <th nowrap scope="col" class="">อำเภอ</th>
          <th nowrap scope="col" class="">ตำบล</th>
          <th nowrap scope="col" class="">หมู่</th>
          <th nowrap scope="col" class="">รหัส</th>
          <th nowrap scope="col" class="">ชื่อ</th>
          <th nowrap scope="col" class="">รหัส</th>
          <th nowrap scope="col" class="">ชื่อ</th>
          <th nowrap scope="col" class="">รหัส</th>
          <th nowrap scope="col" class="">ชื่อ</th>
        </tr>

        </thead>
        <tbody class="table-borderless">
          @for (item of pageData()?.content; track item; let i = $index; ) {
            <tr>
              <td class="text-center">{{ i + 1 }}</td>
              <td class="text-center">{{ item.mastercup?.mastercupId }}</td>
              <td class="text-center">{{ item.mastercup?.catm?.catm }}</td>
              <td class="text-center">{{ item.mastercup?.catm?.changwatName }}</td>
              <td class="text-center">{{ item.mastercup?.catm?.amphurName }}</td>
              <td class="text-center">{{ item.mastercup?.catm?.tumbonName }}</td>
              <td class="text-center">{{ item.mastercup?.catm?.moo }}</td>
              <td class="text-center">{{ item.mastercup.hospSubCode }}</td>
              <td class="text-center">{{ item.mastercup.hospSubDisplayName }}</td>
              <td class="text-center">{{ item.mastercup.hospMainOpCode }}</td>
              <td class="text-center">{{ item.mastercup.hospMainOpDisplayName }}</td>
              <td class="text-center">{{ item.mastercup.hospMainCode }}</td>
              <td class="text-center">{{ item.mastercup.hospMainDisplayName }}</td>
              <td class="text-center">
                <button class="btn btn-default" (click)="cancel(item)">
                  ยกเลิก
                </button>
              </td>
            </tr>
          } @empty {
            <tr>
              <td colspan="14">
                <app-not-found></app-not-found>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
    <ngb-pagination class="d-flex justify-content-center"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    [page]="pageData()?.number + 1"
                    (pageChange)="search($event)"
                    [pageSize]="pageData()?.size"
                    [collectionSize]="pageData()?.totalElements"></ngb-pagination>
  </div>
</div>
