import {Component, inject, signal, ViewChild} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmDialogService} from "../../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {ToastService} from "../../../../shared/commons/toast/toast-service";
import {filter} from "rxjs/operators";
import {EmailNotificationQuotaResource} from "../email-notification-quota-resource";
import {EmailNotificationQuotaDto} from "../../../../../generated-model/model";
import {FormsModule, NgForm} from "@angular/forms";
import {CustomErrorComponent} from "../../../../shared/commons/custom-error.component";
import {NgIf} from "@angular/common";
import {updateFormAndValidity} from "../../../../app.constants";
import {EmailUniqueDirective} from "../email-unique.directive";

@Component({
  selector: 'app-email-notification-quota-modal',
  standalone: true,
  imports: [
    FormsModule,
    CustomErrorComponent,
    NgIf,
    EmailUniqueDirective
  ],
  templateUrl: './email-notification-quota-modal.component.html',
  styleUrl: './email-notification-quota-modal.component.scss'
})
export class EmailNotificationQuotaModalComponent {
  @ViewChild("form", {read: NgForm, static: false}) form: NgForm;

  prefix = ''
  item = signal<EmailNotificationQuotaDto>({})


  emailNotificationQuotaResource = inject(EmailNotificationQuotaResource)
  activeModal = inject(NgbActiveModal)
  confirmDialogService = inject(ConfirmDialogService)
  toastService = inject(ToastService)


  save() {
    let valid = updateFormAndValidity(this.form);
    if (!valid) {
      console.log('invalid')
      this.toastService.showWarning("กรุณากรอกข้อมูลให้ครบถ้วน");
    } else {
      this.confirmDialogService.open({content: 'ยืนยันการบันทึกข้อมูล'})
        .pipe(filter(e => !!e))
        .subscribe({
          next: e => {
            this.emailNotificationQuotaResource.save(this.item()).subscribe(e => {
              this.item.set(e)
              this.activeModal.close(this.item())
              this.toastService.showSuccess('บันทึกข้อมูลสำเร็จ')
            })
          }
        })
    }

  }
}
