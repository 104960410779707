import {Component, forwardRef, inject, input, Input, signal} from '@angular/core';
import {SelectZoneService} from "./select-zone.service";
import {ZoneDto} from "../../../../generated-model/model";
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from "@angular/forms";
import {NgSelectComponent} from "@ng-select/ng-select";

@Component({
  selector: 'app-select-zone',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    NgSelectComponent
  ],
  templateUrl: './select-zone.component.html',
  styleUrl: './select-zone.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectZoneComponent)
    }
  ]
})
export class SelectZoneComponent implements ControlValueAccessor{
  id = input<string>('zone');
  name = input<string>('zone');
  @Input() value: ZoneDto;
  zoneService = inject(SelectZoneService)
  zones = signal<ZoneDto[]>([])

  constructor() {
    this.zoneService.findAll().subscribe(zones => this.zones.set(zones))
  }

  onChange = (value) => {
  };
  onTouched = () => {
  };

  writeValue(value: ZoneDto) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  onValueChange(value: ZoneDto) {
    this.onChange(value);
  }
}
