import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {DepartmentNewDto, Page} from "../../../../generated-model/model";
import {environment} from "../../../../environments/environment";
import {IgnoreNullHttpParams} from "../Ignore-null-http-params";

@Injectable({
  providedIn: 'root'
})
export class DepartmentNewResource {
  http = inject(HttpClient)

  search(params:IgnoreNullHttpParams){
   return this.http.get<Page<DepartmentNewDto>>(`${environment.serverUrl}/api/departmentnew/search`,{params:params.toHttpParam()})
  }

  save(item:DepartmentNewDto){
    return this.http.post(`${environment.serverUrl}/api/departmentnew/save`,item)
  }

  findAllOrinalType(){
    return this.http.get<string[]>(`${environment.serverUrl}/api/departmentnew/orinal-Type/all`)
  }
}
