import {Component, inject, signal} from '@angular/core';
import {DigitOnlyModule} from "@uiowa/digit-only";
import {FormsModule} from "@angular/forms";
import {RelationDto} from "../../../../../generated-model/model";
import {RelationResource} from "../relation-resource";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {SharedCommonModule} from "../../../../shared/commons/shared-common.module";
import {ConfirmDialogService} from "../../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-relation-modal',
  standalone: true,
  imports: [
    DigitOnlyModule,
    FormsModule,
    SharedCommonModule
  ],
  templateUrl: './relation-modal.component.html',
  styleUrl: './relation-modal.component.scss'
})
export class RelationModalComponent {
  prefix = ''
  item = signal<RelationDto>({})
  relationService = inject(RelationResource)
  activeModal = inject(NgbActiveModal)
  confirmDialogService = inject(ConfirmDialogService)

  save() {
    this.confirmDialogService.open({content: 'ยืนยันการบันทึกข้อมูล'})
      .pipe(filter(e => !!e))
      .subscribe({
        next: e => {
          this.relationService.save(this.item()).subscribe(e => {
            this.item.set(e)
            this.activeModal.close(this.item())
          })
        }
      })
  }
}
