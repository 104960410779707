<div class="modal-header">
  <strong class="text-primary bold fs-5 d-inline-block">{{prefix}} DEPARTMENT_NEW</strong>
</div>
<div class="modal-body">
  <div class="row mb-3">
    <label for="departmentCode" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">DEPARTMENT_CODE</label>
    <div class="col-xl-3">
      <input type="text" class="form-control" id="departmentCode" name="departmentCode">
    </div>
    <label for="departmentDesc" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">DEPARTMENT_DESC</label>
    <div class="col-xl-3">
      <input type="text" class="form-control" id="departmentDesc" name="departmentDesc">
    </div>
  </div>
  <div class="row mb-3">
    <label for="ministryCode" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">MINISTRY_CODE</label>
    <div class="col-xl-3">
      <input type="text" class="form-control" id="ministryCode" name="ministryCode">
    </div>
    <label class="col-xl-2 col-form-label ui-require text-end">สถานะการใช้งาน</label>
    <div class="col-xl-3">
      <select class="form-control col-lg-5">
        <option [ngValue]="undefined">กรุณาเลือก</option>
        <option [ngValue]="'Y'">ใช้งาน</option>
        <option [ngValue]="'N'">ไม่ใช้งาน</option>
      </select>
    </div>
  </div>

</div>
<div class="row mt-3 mb-3">
  <div class="col-12 text-end">
    <button type="button" class="btn btn-primary custom-button mx-1 btn-main confirm-btn" (click)="save()">
      {{prefix}} DEPARTMENT_NEW
    </button>
    <button type="button" class="btn btn-default custom-button mx-1 btn-main confirm-btn" (click)="activeModal.close()">
      ปิด
    </button>
  </div>
</div>
