import {
  AfterViewInit,
  Component,
  forwardRef,
  inject,
  input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {debounceTime, finalize, Observable, of, Subject} from "rxjs";
import {HmainOp, Hsub, MastercupDto, Province, ZoneDto} from "../../../../generated-model/model";
import {NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent} from "@ng-select/ng-select";
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {map} from "rxjs/operators";
import {AsyncPipe, NgIf} from "@angular/common";
import {MastercupResource} from "../../service/MastercupResource";

@Component({
  selector: 'app-select-mastercup-id',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
    NgSelectComponent,
    FormsModule
  ],
  templateUrl: './select-mastercup-id.component.html',
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectMastercupIdComponent),
    multi: true,
  }]
})
export class SelectMastercupIdComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {
  mastercupList$: Observable<MastercupDto[]> = of([]);
  term$ = new Subject<string>();
  typeaheadLoading = false;
  value: Hsub = undefined;
  id = input<string>('mastercupId');
  name = input<string>('mastercupId');
  restrictZone = input<ZoneDto>();
  restrictProvince = input<Province>();
  restrictHmainOp = input<HmainOp>();
  restrictHsub = input<Hsub>();
  disabled = input<boolean>();
  touched = false;
  private mastercupResource = inject(MastercupResource);
  @ViewChild('ngSelectComponent', {static: true, read: NgSelectComponent}) ngSelectComponent: NgSelectComponent;
  @ViewChild(NgControl) innerNgControl: NgControl;

  onChange = (value) => {
  };
  onTouched = () => {
  };
  provinceId
  compareWithHsub = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.id == o2.id))
  };

  constructor() {
  }

  ngAfterViewInit() {

  }

  ngOnInit(): void {
    this.term$
      .pipe(debounceTime(500))
      .subscribe(term => {
        this.typeaheadLoading = true;
        this.mastercupList$ = this.mastercupResource.search(term, this.restrictZone(),this.restrictProvince(),this.restrictHmainOp(),this.restrictHsub()).pipe(
          map(e => {
            return e;
          }),
          finalize(() => this.typeaheadLoading = false)
        );
      });
    this.term$.next('');
  }

  writeValue(value: Hsub) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  onValueChange(value: Hsub) {
    this.onChange(value);
    this.markAsTouched();
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const restrictZoneChange = changes['restrictZone'];
    const restrictProvinceChange = changes['restrictProvince'];
    const restrictHmainOpChange = changes['restrictHmainOp'];
    const restrictHsubChange = changes['restrictHsub'];
    if (restrictZoneChange || restrictProvinceChange || restrictHmainOpChange || restrictHsubChange) {
      this.value = undefined;
      this.onValueChange(undefined);
      this.term$.next('');
      this.ngSelectComponent.searchTerm = undefined;
    }
  }
}
