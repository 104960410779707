import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {NewsDto} from "../../../generated-model/model";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient) {
  }

  findByProgram(program: string): Observable<NewsDto> {
    return this.http.get<NewsDto>(`${environment.serverUrl}/api/news/program/${program}`);
  }

  save(dto: NewsDto): Observable<NewsDto> {
    return this.http.post<NewsDto>(`${environment.serverUrl}/api/news`, dto);
  }

}
