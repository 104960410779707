<ng-select [id]="id()"
           [name]="name()"
           [items]="mastercupList$ | async"
           placeholder="กรุณาเลือก"
           class="novalidate"
           [disabled]="disabled()"
           [clearable]="true"
           [compareWith]="compareWithHsub"
           [editableSearchTerm]="true"
           [typeahead]="term$"
           (click)="term$.next('')"
           [loading]="typeaheadLoading"
           (ngModelChange)="onValueChange($event)"
           [(ngModel)]="value" #ngSelectComponent>
  <ng-template ng-label-tmp let-item="item">
    <ng-container *ngIf="item.mastercupId">
      {{ item.mastercupId }}
    </ng-container>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
    <ng-container *ngIf="item.mastercupId">
      {{ item.mastercupId }}
    </ng-container>
  </ng-template>
</ng-select>
