<div class="modal-header">
  <strong class="text-primary bold fs-5 d-inline-block">{{prefix}} กำหนดจำนวน Quota ผู้ลงทะเบียนแยกรายหน่วยบริการปฐมภูมิ ( HSUB ) ที่อยู่กับหน่วยบริาการประจำ ( HMAIN_OP ) คนละรหัส</strong>
</div>
<div class="modal-body">
  <div class="row mb-3">
    <label for="zone" class="col-xl-2 col-form-label ui-require text-end">เขต</label>
    <div class="col-xl-3">
      <select class="form-control col-lg-5" id="zone" name="zone">
        <option [ngValue]="undefined">กรุณาเลือก</option>
      </select>
    </div>
    <label for="province" class="col-xl-2 col-form-label ui-require text-end">จังหวัด</label>
    <div class="col-xl-3">
      <select class="form-control col-lg-5" id="province" name="province">
        <option [ngValue]="undefined">กรุณาเลือก</option>
      </select>
    </div>
  </div>
  <div class="row mb-3">
    <label for="hmain" class="col-xl-2 col-form-label ui-require text-end">หน่วยบริการประจำ</label>
    <div class="col-xl-3">
      <input type="text" class="form-control" id="hmain" name="hmain">
    </div>
    <label for="hsub" class="col-xl-2 col-form-label ui-require text-end">หน่วยบริการปฐมภูมิ</label>
    <div class="col-xl-3">
      <input type="text" class="form-control" id="hsub" name="hsub">
    </div>
  </div>
  <div class="row mb-3">
    <label for="amount" class="col-xl-2 col-form-label ui-require text-end">จำนวนสิทธิ์ที่ลงทะเบียนสูงสุด</label>
    <div class="col-xl-3">
      <input type="text" class="form-control" id="amount" name="amount">
    </div>
  </div>
</div>
<div class="row mt-3 mb-3">
  <div class="col-12 text-end">
    <button type="button" class="btn btn-primary custom-button mx-1 btn-main confirm-btn" (click)="save()">
      {{prefix}}
    </button>
    <button type="button" class="btn btn-default custom-button mx-1 btn-main confirm-btn" (click)="activeModal.close()">
      ปิด
    </button>
  </div>
</div>
