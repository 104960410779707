<div class="card custom-box my-3 border-0">
  <div class="card-body mb-3">
    <div class="card-title text-muted">
      <i class="bi bi-search me-2"></i>
      ค้นหาข้อมูล
    </div>
    <div class="container">
      <div class="row d-flex mt-xl-3 mt-sm-3 mb-3">
        <label for="status"
               class="col-form-label text-xl-end text-sm-start col-sm-4 col-xl-3">โปรแกรม</label>
        <div class="col-xl-6 col-sm-10">
          <select name="program" id="status" class="form-select" [(ngModel)]="programName">
            <option [ngValue]="undefined">เลือก</option>
            <option *ngFor="let program of ProgramArray" [value]="program">{{ Program[program] }}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="text-center">
          <button type="submit" class="btn btn-primary me-2" (click)="search()">
            <i class="bi bi-search me-1"></i>
            ค้นหา
          </button>
          <button type="reset" class="btn btn-default me-2">
            เคลียร์ข้อมูล
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card custom-box border-0 mt-3 mb-4" *ngIf="news">
  <div class="card-body mb-3" style="margin-bottom: 5.5rem !important;">
    <div class="row mt-2 mb-4">
      <label for="startDate"
             class="col-form-label text-xl-end text-sm-start col-sm-4 col-xl-1">วันที่เริ่ม</label>
      <div class="col-xl-4 col-sm-8">
        <gt-ng-datepicker id="startDate" name="startDate" [(ngModel)]="news.startDate"></gt-ng-datepicker>
      </div>
      <label for="endDate"
             class="col-form-label text-xl-end text-sm-start col-sm-4 col-xl-1">วันที่สิ้นสุด</label>
      <div class="col-xl-4 col-sm-8">
        <gt-ng-datepicker id="endDate" name="endDate" [(ngModel)]="news.endDate"></gt-ng-datepicker>
      </div>
    </div>
    <div class="row mt-2 mb-4">
      <div class="col-xl-12 col-sm-12">
        <quill-editor class="minw-100" [(ngModel)]="news.textDetail"></quill-editor>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center mt-2">
        <button type="submit" class="btn btn-primary btn-custom me-2" (click)="save()">
          บันทึก
        </button>
        <button type="button" class="btn btn-outline-primary btn-custom me-2" (click)="preview()">
          แสดงตัวอย่าง
        </button>
      </div>
    </div>
  </div>
</div>
