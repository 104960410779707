<div class="loading-container" [hidden]="!loading()">
  <div class="fixed-bottom">
    <div class="decorator float-end">
      <div id="fountainG">
        <div id="fountainG_1" class="fountainG"></div>
        <div id="fountainG_2" class="fountainG"></div>
        <div id="fountainG_3" class="fountainG"></div>
        <div id="fountainG_4" class="fountainG"></div>
        <div id="fountainG_5" class="fountainG"></div>
        <div id="fountainG_6" class="fountainG"></div>
        <div id="fountainG_7" class="fountainG"></div>
      </div>
      <div class="text-dark me-2 ms-2 d-inline-block text-muted" style="font-size: 0.75rem;">
        กำลังโหลด...
      </div>
    </div>
  </div>
</div>
