import { Component } from '@angular/core';
import {Page} from "../../../../generated-model/model";
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";
import {CountSelectModalComponent} from "./modal/count-select-modal/count-select-modal.component";

@Component({
  selector: 'app-count-select',
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    NgForOf,
    NgIf,
    NgbPagination,
    NotFoundComponent,
    ReactiveFormsModule
  ],
  templateUrl: './count-select.component.html',
  styleUrl: './count-select.component.scss'
})
export class CountSelectComponent {
  pageData: Page<any> = {size: 10};

  constructor(private modalService: NgbModal) {
    this.search();
  }

  search(page: number = 1) {
  }

  openModelAdd(prefix: string) {
    const modalRef = this.modalService.open(CountSelectModalComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.prefix = prefix
  }

}
