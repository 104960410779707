import {Component, inject, signal} from '@angular/core';
import {DecimalPipe} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";
import {from} from "rxjs";
import {filter} from "rxjs/operators";
import {RelationAttorneyModalComponent} from "./modal/relation-attorney-modal/relation-attorney-modal.component";

@Component({
  selector: 'app-relation-attorney',
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    NgbPagination,
    NotFoundComponent
  ],
  templateUrl: './relation-attorney.component.html',
  styleUrl: './relation-attorney.component.scss'
})
export class RelationAttorneyComponent {

  pageData = signal<any>({size: 10});
  private modalService = inject(NgbModal);

  ngOnInit(): void {
    this.search()
  }

  search(page: number = 1) {
  }


  openModelAdd(prefix: string) {
    const modalRef = this.modalService.open(RelationAttorneyModalComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.prefix = prefix
  }

  openModelEdit(prefix: string, item: any) {
    const modalRef = this.modalService.open(RelationAttorneyModalComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.catm = {...item}
    modalRef.componentInstance.prefix = prefix
    from(modalRef.result).pipe(filter(e => !!e))
      .subscribe(e => {
        this.search(1)
      });
  }

}
