import {Component, ViewEncapsulation} from '@angular/core';
import {DigitOnlyModule} from "@uiowa/digit-only";
import {FormsModule} from "@angular/forms";
import {filter} from "rxjs/operators";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmDialogService} from "../../../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {ToastService} from "../../../../../shared/commons/toast/toast-service";
import {CatmDto} from "../../../../../../generated-model/model";

@Component({
  selector: 'app-catm-model',
  standalone: true,
  imports: [
    DigitOnlyModule,
    FormsModule
  ],
  templateUrl: './catm-model.component.html',
  styleUrl: './catm-model.component.scss',
  encapsulation:ViewEncapsulation.None
})
export class CatmModelComponent {
  prefix = '';
  catm: CatmDto = {}
  constructor(public activeModal: NgbActiveModal,
              private confirmDialogService: ConfirmDialogService,
              private toastService: ToastService,
  ) {

  }

  save() {
    this.confirmDialogService.open({content: 'ยืนยันการบันทึกข้อมูล'})
      .pipe(filter(e => !!e))
      .subscribe({
        next: e => {
        }
      });
  }

}
