import {Component, inject, model, OnInit, signal, ViewChild, ViewEncapsulation} from '@angular/core';
import {DigitOnlyModule} from "@uiowa/digit-only";
import {FormsModule, NgForm} from "@angular/forms";
import {filter} from "rxjs/operators";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmDialogService} from "../../../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {ToastService} from "../../../../../shared/commons/toast/toast-service";
import {CatmDto, District, ProvinceDto, SubDistrictDto} from "../../../../../../generated-model/model";
import {CustomErrorComponent} from "../../../../../shared/commons/custom-error.component";
import {NgIf} from "@angular/common";
import {updateFormAndValidity} from "../../../../../app.constants";
import {
  SelectMastercupSubdistrictComponent
} from "../../../../../shared/components/select-mastercup-subdistrict/select-mastercup-subdistrict.component";
import {
  SelectMastercupDistrictComponent
} from "../../../../../shared/components/select-mastercup-district/select-mastercup-district.component";
import {CatmService} from "../../catm.service";
import {CatmUniqueDirective} from "../../catm-unique.directive";
import {
  ProvinceAutocompleteComponent
} from "../../../../../shared/components/province-autocomplete/province-autocomplete.component";

@Component({
  selector: 'app-catm-model',
  standalone: true,
  imports: [
    DigitOnlyModule,
    FormsModule,
    CustomErrorComponent,
    NgIf,
    SelectMastercupSubdistrictComponent,
    SelectMastercupDistrictComponent,
    CatmUniqueDirective,
    ProvinceAutocompleteComponent
  ],
  templateUrl: './catm-model.component.html',
  styleUrl: './catm-model.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class CatmModelComponent implements OnInit {
  @ViewChild('form') ngForm: NgForm
  prefix = '';
  catm: CatmDto = {}
  selectedSubDistrict = model<SubDistrictDto>();
  selectedDistrict = model<District>()
  selectedProvince = model<ProvinceDto>();
  item = signal<CatmDto>({})

  catmService = inject(CatmService)

  constructor(public activeModal: NgbActiveModal,
              private confirmDialogService: ConfirmDialogService,
              private toastService: ToastService
  ) {
    this.catm.catm = this.item().catm
  }

  ngOnInit(): void {
  }

  save() {
    let valid = updateFormAndValidity(this.ngForm)
    if (!valid) {
      this.toastService.showWarning('กรุณากรอกข้อมูลให้ครบถ้วน')
    } else {
      this.confirmDialogService.open({content: 'ยืนยันการบันทึกข้อมูล'})
        .pipe(filter(e => !!e))
        .subscribe({
          next: e => {
            this.item().changwatName = this.selectedProvince()?.name
            this.item().amphurName = this.selectedDistrict()?.name
            this.item().tumbonName = this.selectedSubDistrict()?.name
            this.catmService.save(this.item()).subscribe({
              next: e => {
                this.item.set(e)
                this.activeModal.close(this.item())
                this.toastService.showSuccess('บันทึกข้อมูลสำเร็จ')
              }
            })
          }
        });
    }
  }

  changeProvince() {
    this.selectedDistrict.set(undefined)
    this.selectedSubDistrict.set(undefined)
  }
  changeDistrict() {
    this.selectedSubDistrict.set(undefined)
  }
}
