<div class="modal-header ">
    <strong class="text-primary bold fs-5 d-inline-block">{{prefix}} สิทธิหลัก (MAININSCL)</strong>
</div>
<div class="modal-body">
    <div class="mb-3 row">
        <label for="rightId" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">RIGHT_ID</label>
        <div class="col-xl-3">
            <input type="text" id="rightId" name="rightId" class="form-control" [(ngModel)]="right.rightId">
        </div>
        <label for="rightName" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">RIGHT_NAME</label>
        <div class="col-xl-3">
            <input type="text" id="rightName" name="rightName" class="form-control" [(ngModel)]="right.rightName">
        </div>
    </div>
    <div class="mb-3 row">
        <label for="rightOrder" class="col-xl-2 ps-xl-4 col-form-label  text-end">RIGHT_ORDER</label>
        <div class="col-xl-3">
            <input type="text" id="rightOrder" name="rightOrder" class="form-control" digitOnly [(ngModel)]="right.rightOrder">
        </div>
        <label for="rightMain" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">RIGHT_MAIN</label>
        <div class="col-xl-3">
            <input type="text" id="rightMain" name="rightMain" class="form-control" maxlength="1" [(ngModel)]="right.rightMain">
        </div>
    </div>
    <div class="mb-3 row">
        <label for="rightDesc" class="col-xl-2 ps-xl-4 col-form-label  text-end">RIGHT_DESC</label>
        <div class="col">
            <textarea id="rightDesc" name="rightDesc" class="form-control" rows="5" maxlength="200" [(ngModel)]="right.rightDesc"></textarea>
        </div>
    </div>
    <div class="mb-3 row">
        <label for="status" class="col-xl-2 col-form-label text-end">สถานะ</label>
        <div class="col-xl-3">
            <div class="col-auto">
                <select class="form-select" id="status" name="status" [(ngModel)]="right.used">
                    <option selected [ngValue]="'Y'">ใช้งาน</option>
                    <option [ngValue]="'N'">ยกเลิก</option>
                </select>
            </div>
        </div>
    </div>
</div>
<div class="row mt-3 mb-3">
    <div class="col-12 text-end">
        <button type="button" class="btn btn-primary custom-button mx-1 btn-main confirm-btn" (click)="save()">
            {{prefix}} สิทธิหลัก (MAININSCL)
        </button>
        <button type="button" class="btn btn-default custom-button mx-1 btn-main confirm-btn" (click)="activeModal.close()">ปิด</button>

    </div>
</div>
