import {
  AfterViewInit,
  Component,
  forwardRef,
  inject,
  input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {debounceTime, finalize, Observable, of, Subject} from "rxjs";
import {HmainOp, Hsub, Province, ZoneDto} from "../../../../generated-model/model";
import {NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent} from "@ng-select/ng-select";
import {AsyncPipe, NgIf} from "@angular/common";
import {HsubResource} from "../../commons/services/HsubResource";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-hsub-autocomponent',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
    NgSelectComponent,
    FormsModule
  ],
  templateUrl: './hsub-autocomplete.component.html',
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HsubAutocompleteComponent),
    multi: true,
  }]
})
export class HsubAutocompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {

  hsubList$: Observable<Hsub[]> = of([]);
  term$ = new Subject<string>();
  typeaheadLoading = false;
  value: Hsub = undefined;
  id = input<string>('hsub');
  name = input<string>('hsub');
  restrictZone = input<ZoneDto>();
  restrictProvince = input<Province>();
  restrictHmainOp = input<HmainOp>();
  disabled = input<boolean>();
  touched = false;
  private hsubResource = inject(HsubResource);
  @ViewChild('ngSelectComponent', {static: true, read: NgSelectComponent}) ngSelectComponent: NgSelectComponent;
  @ViewChild(NgControl) innerNgControl: NgControl;

  onChange = (value) => {
  };
  onTouched = () => {
  };

  compareWithHsub = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.hcode == o2.hcode))
  };

  constructor() {
  }

  ngAfterViewInit() {

  }

  ngOnInit(): void {
    this.term$
      .pipe(debounceTime(500))
      .subscribe(term => {
        this.typeaheadLoading = true;
        this.hsubList$ = this.hsubResource.search(term, this.restrictZone(),this.restrictProvince(),this.restrictHmainOp()).pipe(
          map(e => {
            return e;
          }),
          finalize(() => this.typeaheadLoading = false)
        );
      });
    this.term$.next('');
  }

  writeValue(value: Hsub) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  onValueChange(value: Hsub) {
    this.onChange(value);
    this.markAsTouched();
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const restrictZoneChange = changes['restrictZone'];
    const restrictProvinceChange = changes['restrictProvince'];
    const restrictHmainOpChange = changes['restrictHmainOp'];
    if (restrictZoneChange || restrictProvinceChange || restrictHmainOpChange) {
      this.value = undefined;
      this.onValueChange(undefined);
      this.term$.next('');
      this.ngSelectComponent.searchTerm = undefined;
    }
  }
}
