import {FormGroup, NgForm} from "@angular/forms";

export function checkScreenSize() {
  const screenWidth = window.innerWidth;

  if (screenWidth < 576) {
    console.log('Current screen size: Extra Small (xs)');
  } else if (screenWidth >= 576 && screenWidth < 768) {
    console.log('Current screen size: Small (sm)');
  } else if (screenWidth >= 768 && screenWidth < 992) {
    console.log('Current screen size: Medium (md)');
  } else if (screenWidth >= 992 && screenWidth < 1200) {
    console.log('Current screen size: Large (lg)');
  } else {
    console.log('Current screen size: Extra Large (xl)');
  }
}

export function updateFormAndValidity(form: NgForm): boolean {
  console.log('updateFormAndValidity form ', form);
  if (!form.valid) {
    markInvalidControlsAsDirtyAndTouched(form)
  }
  return form.valid;
}

export function scrollToInvalidElement(formId: string) {
  //for more info aboute the measure see https://stackoverflow.com/questions/24665602/scrollintoview-scrolls-just-too-far
  let y = document.querySelector(`#${formId} .ng-invalid`)?.getBoundingClientRect().top + window.pageYOffset + -80;
  window.scrollTo({top: y});
}

export function markInvalidControlsAsDirtyAndTouched(form: NgForm): void {
  Object.keys(form.controls)
    // .map(key => {
    //   console.log(`form.controls[${key}] invalid? `, form.controls[key].invalid);
    //   return key;
    // })
    .filter(key => form.controls[key].invalid)
    .forEach((key, index) => {
      console.log('form.controls[', key, '], invalid', form.controls[key], form.controls[key]?.invalid)
      if (form.controls[key] instanceof FormGroup) {
        let formGroup = form.controls[key] as FormGroup;
        console.log('formGroup= ', formGroup);
        Object.keys(formGroup.controls)
          .map(k => {
            console.log(`formGroup.controls[${k}] invalid? `, form.controls[k]?.invalid);
            return k;
          })
          .filter(k => formGroup.controls[k].invalid)
          .forEach((groupKey, index) => {
            console.log('form.formGroup[', groupKey, '], invalid', formGroup.controls[groupKey], formGroup.controls[groupKey]?.invalid)
            formGroup.controls[groupKey].markAsDirty({onlySelf: false})
            formGroup.controls[groupKey].markAsTouched({onlySelf: false})
            formGroup.controls[groupKey].updateValueAndValidity({emitEvent: true});
          });
      } else {
        form.controls[key].markAsDirty({onlySelf: false})
        form.controls[key].markAsTouched({onlySelf: false})
        form.controls[key].updateValueAndValidity({emitEvent: true});
      }
    });
}

export type CRUD = 'CREATE' | 'READ' | 'UPDATE' | 'DELETE';

export const DayOfWeekThai: { [id: string]: string } = {
  MONDAY: 'จันทร์',
  TUESDAY: 'อังคาร',
  WEDNESDAY: 'พุธ',
  THURSDAY: 'พฤหัสบดี',
  FRIDAY: 'ศุกร์',
  SATURDAY: 'เสาร์',
  SUNDAY: 'อาทิตย์'
};

export function cloneObject(object: any): any {
  if (!object) {
    return undefined;
  }
  return JSON.parse(JSON.stringify(object))
}

export function mergeObject(obj1: any, obj2: any): any {
  Object.keys(obj2).filter(key => key in obj1).forEach(key => obj1[key] = obj2[key]);
  return obj1;
}

export type PageRequest = { size?: number, page?: number, sort?: string | string[] }
export const compareWithId = (o1: { id: any }, o2: {
  id: any
}): boolean => (o1 == o2) || ((!!o1 && !!o2) && (o1.id === o2.id));
export const compareWithCode = (o1: { code: any }, o2: {
  code: any
}): boolean => (o1 == o2) || ((!!o1 && !!o2) && (o1.code === o2.code));

export type GeoLocation = { lat: number, lon: number };

export function notImplementedYet() {
  alert("not implemented yet.");
}

export const BKK_PROVINCE_ID = "1000";
export const SN = "SN";
export const SP = "SP";
