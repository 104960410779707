import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {
  DistrictDto,
  HmainOp,
  Hsub,
  Mastercup,
  Page,
  Province,
  SubDistrictDto,
  ZoneDto
} from "../../../generated-model/model";
import {IgnoreNullHttpParams} from "../commons/Ignore-null-http-params";


@Injectable({providedIn: "root"})
export class MastercupResource {
  http = inject(HttpClient);

  findByMastercupId(mastercupId: string): Observable<Mastercup> {
    return this.http.get<Mastercup>(`${environment.serverUrl}/api/mastercup/${mastercupId}`);
  }

  searchByCatm(catm: String, page: number, size: number): Observable<Page<Mastercup>> {
    return this.http.get<Page<Mastercup>>(`${environment.serverUrl}/api/mastercup/catm`, {
      params: IgnoreNullHttpParams.fromObject({
        catm: catm,
        page: page,
        size: size
      }).toHttpParam()
    });
  }

  public search(term: string, zone?: ZoneDto, province?: Province, hmainOp?: HmainOp,hsub?:Hsub): Observable<Mastercup[]> {
    return this.http.get<Mastercup[]>(`${environment.serverUrl}/api/mastercup/search`, {
      params: IgnoreNullHttpParams.fromObject({
        term: term,
        zoneId: zone?.id,
        provinceId: province?.id,
        hmainOp: hmainOp?.hcode,
        hcode: hsub?.hcode
      }).toHttpParam()
    });
  }

  findProvinceAll(): Observable<Province[]> {
    return this.http.get<Province[]>(`${environment.serverUrl}/api/mastercup/province/all`, {});
  }

  findDistrictAllByProvinceId(provinceId: String): Observable<DistrictDto[]> {
    return this.http.get<DistrictDto[]>(`${environment.serverUrl}/api/mastercup/province/${provinceId}/district/all`, {});
  }

  findSubDistrictAllByDistrictId(districtId: String): Observable<SubDistrictDto[]> {
    return this.http.get<SubDistrictDto[]>(`${environment.serverUrl}/api/mastercup/district/${districtId}/subdistrict/all`, {});
  }

  findMooBySubDistrictId(subDistrictId: String): Observable<string[]> {
    return this.http.get<string[]>(`${environment.serverUrl}/api/mastercup/subdistrict/${subDistrictId}/moo`, {});
  }
}
