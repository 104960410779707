import {
  AfterViewInit,
  Component,
  forwardRef,
  inject,
  Input,
  input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from "@angular/forms";
import {Province, SubDistrict} from "../../../../generated-model/model";
import {Observable, of} from "rxjs";
import {CommonModule} from "@angular/common";
import {SharedCommonModule} from "../../commons/shared-common.module";
import {tap} from "rxjs/operators";
import {MastercupResource} from "../../service/MastercupResource";

@Component({
  selector: 'app-select-mastercup-province',
  templateUrl: './select-mastercup-province.component.html',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectMastercupProvinceComponent),
      multi: true
    }
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedCommonModule
  ]
})
export class SelectMastercupProvinceComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {

  @Input() defaultFirstVal: boolean = true;
  provinceList$: Observable<Province[]> = of([]);
  typeaheadLoading = false;
  value: SubDistrict = undefined;
  id = input<string>('mastercup-province');
  name = input<string>('mastercup-province');
  disabled = input<boolean>();
  options = input<{ fetchAll?: boolean }>({fetchAll: false});
  @Input() touched = false;
  private mastercupResource = inject(MastercupResource);
  onChange = (value) => {
  };
  onTouched = () => {
  };

  compareWithCode = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.code == o2.code))
  };

  constructor() {
  }

  ngAfterViewInit() {

  }

  ngOnInit(): void {
    this.provinceList$ = this.mastercupResource.findProvinceAll()
      .pipe(
        tap(e => {
          if (this.defaultFirstVal) {
            this.value = e?.[0];
          }
          this.onChange(this.value);
        })
      )
  }

  writeValue(value: SubDistrict) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  valueChange(value: SubDistrict) {
    this.onChange(value);
    this.markAsTouched();
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

  }
}
