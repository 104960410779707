<div class="modal-header">
  <strong class="text-primary bold fs-5 d-inline-block">แสดงตัวอย่าง</strong>
</div>
<div class="modal-body">
<!--  <div class="mb-3 row">-->
    <quill-view-html [content]="textDetail" theme="snow"></quill-view-html>
<!--  </div>-->
</div>

<div class="row mt-3 mb-3">
  <div class="col-12 text-end">
    <button type="button" class="btn btn-default custom-button mx-1 btn-main confirm-btn" (click)="activeModal.close()">
      ปิด
    </button>
  </div>
</div>
