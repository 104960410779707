import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Province} from "../../../../generated-model/model";
import {environment} from "../../../../environments/environment";
import {IgnoreNullHttpParams} from "../Ignore-null-http-params";

@Injectable({providedIn: "root"})
export class ProvinceResource {
  http = inject(HttpClient);

  public search(term: string, zoneId?: string): Observable<Province[]> {
    return this.http.get<Province[]>(`${environment.serverUrl}/api/province`, {
      params: IgnoreNullHttpParams.fromObject({term: term, zoneId: zoneId}).toHttpParam()
    });
  }
}
