import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {InsclDto, Page} from "../../../../generated-model/model";
import {Observable} from "rxjs";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";

@Injectable({
  providedIn: 'root'
})
export class InsclResource {

  constructor(private http: HttpClient) {
  }

  search(params: IgnoreNullHttpParams): Observable<Page<InsclDto>> {
    return this.http.get<Page<InsclDto>>(`${environment.serverUrl}/api/inscl/search`, {params: params.toHttpParam()});
  }

  searchAuto(term: string) {
    return this.http.get<InsclDto[]>(`${environment.serverUrl}/api/inscl/auto-complete`, {
      params: { term }
    });
  }

  save(item: InsclDto): Observable<InsclDto> {
    return this.http.post<InsclDto>(`${environment.serverUrl}/api/inscl`, item);
  }
}
