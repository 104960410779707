import {Component} from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";
import {CatmDto, Page} from "../../../../generated-model/model";
import {from} from "rxjs";
import {filter} from "rxjs/operators";
import {
  UnregisterMastercupModalComponent
} from "./modal/unregister-mastercup-modal/unregister-mastercup-modal.component";

@Component({
  selector: 'app-unregister-mastercup',
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    NgForOf,
    NgIf,
    NgbPagination,
    NotFoundComponent,
    ReactiveFormsModule
  ],
  templateUrl: './unregister-mastercup.component.html',
  styleUrl: './unregister-mastercup.component.scss'
})
export class UnregisterMastercupComponent {
  pageData: Page<any> = {size: 10};

  constructor(private modalService: NgbModal) {
    this.search();
  }

  search(page: number = 1) {
  }

  openModelAdd(prefix: string) {
    const modalRef = this.modalService.open(UnregisterMastercupModalComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.prefix = prefix
  }

  openModelEdit(prefix: string, item: CatmDto) {
    const modalRef = this.modalService.open(UnregisterMastercupModalComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.catm = {...item}
    modalRef.componentInstance.prefix = prefix
    from(modalRef.result).pipe(filter(e => !!e))
      .subscribe(e => {
        this.search(1)
      });
  }
}
