<div class="card custom-box mb-3 border-0">
  <form (ngSubmit)="search()">
    <div class="card-body">
      <div class="row mb-3">
        <label for="id" class="col-xl-2 col-form-label ">RELATION_ID</label>
        <div class="col-xl-3">
          <input id="id" name="id" type="text" class="form-control"  [(ngModel)]="searchParam.id">
        </div>
        <label for="name" class="col-xl-2 col-form-label ">RELATION_NAME</label>
        <div class="col-xl-3">
          <input id="name" name="name" type="text" class="form-control"   [(ngModel)]="searchParam.name">
        </div>
      </div>
      <div class="row mb-3">
        <label for="status" class="col-xl-2 col-form-label ">สถานะการใช้งาน</label>
        <div class="col-xl-3">
          <select id="status" name="status"  class="form-select col-lg-5" [(ngModel)]="searchParam.used">
            <option [ngValue]="undefined">ทั้งหมด</option>
            <option [ngValue]="'Y'">ใช้งาน</option>
            <option [ngValue]="'N'">ไม่ใช้งาน</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="text-center">
          <button type="submit" class="btn btn-primary me-2">
            <i class="bi bi-search me-1"></i>
            ค้นหา
          </button>
          <button type="reset" class="btn btn-default me-2">
            เคลียร์ข้อมูล
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="card custom-box mb-3 border-0">
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-striped table-borderless caption-top">
        <caption>
          แสดง {{ (pageData()?.content?.length ? (pageData()?.number * pageData()?.size) + 1 : 0) | number }}
          ถึง {{ ((pageData()?.number) * pageData()?.size + pageData()?.content?.length) | number }}
          จากทั้งหมด {{ pageData()?.totalElements | number }} รายการ
          <button class="btn btn-default float-end" (click)="addOrEditItem('เพิ่ม',{used:'Y'})">
            <i class="bi bi-plus-lg me-1"></i>
            เพิ่มข้อมูล
          </button>
        </caption>
        <thead class="color-black font-weight-light">
        <tr class="table-light text-center" style="background-color: #eeeeee;">
          <th nowrap scope="col">RELATION_ID</th>
          <th nowrap scope="col" class="text-start">RELATION_NAME</th>
          <th nowrap scope="col" class="">สถานะการใช้งาน</th>
          <th nowrap scope="col" class="">ดำเนินการ</th>
        </tr>
        </thead>
        <tbody class="table-borderless">
          @for (item of pageData()?.content; track item; let i = $index; ) {
            <tr>
              <td class="text-center">{{ item.id }}</td>
              <td class="">{{ item.name }}</td>
              <td class="text-center">{{ Used[item.used] }}</td>
              <td class="text-center">
                <button class="btn btn-default" (click)="addOrEditItem('แก้ไข',item)">
                  <i class="bi bi-pencil"></i>
                </button>
              </td>
            </tr>
          } @empty {
            <tr>
              <td colspan="4">
                <app-not-found></app-not-found>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
    <ngb-pagination class="d-flex justify-content-center"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    [page]="pageData()?.number + 1"
                    (pageChange)="search($event)"
                    [pageSize]="pageData()?.size"
                    [collectionSize]="pageData()?.totalElements"></ngb-pagination>
  </div>
</div>
