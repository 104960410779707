import {Component} from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {NgbActiveModal, NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {InsclDto, InsclSearchParam, Page, Used} from "../../../../generated-model/model";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";
import {InsclResource} from "./inscl-resource.service";
import {filter} from "rxjs/operators";
import {from} from "rxjs";
import {RangeSubInsclTypeModalComponent} from "./modal/range-sub-inscl-type-modal/range-sub-inscl-type-modal.component";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";

@Component({
  selector: 'app-sub-inscl',
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    NgbPagination,
    NgIf,
    NgForOf,
    NotFoundComponent,
  ],
  templateUrl: './sub-inscl.component.html',
  styleUrl: './sub-inscl.component.scss',
  providers: [NgbActiveModal]

})

export class SubInsclComponent {
  searchParam: InsclSearchParam & { page?: number } = {}
  pageData: Page<InsclDto> = {size: 10};
  protected readonly Used = Used;

  constructor(private insclService: InsclResource,
              private modalService: NgbModal) {
    this.search()
  }

  search(page: number = 1) {
    let param = IgnoreNullHttpParams.fromObject(
      {...this.searchParam})
      .set("page", page - 1)
      .set("size", this.pageData.size)
    this.insclService.search(param).subscribe({
        next: e => {
          this.pageData = e
        }
      }
    )
  }

  openModelAdd(prefix: string) {
    const modalRef = this.modalService.open(RangeSubInsclTypeModalComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.prefix = prefix
    from(modalRef.result).pipe(filter(e => !!e))
      .subscribe(e => {
        this.search(1)
      });
  }

  openModelEdit(prefix: string, item: InsclDto) {
    const modalRef = this.modalService.open(RangeSubInsclTypeModalComponent, {
      windowClass: 'set-width-modal',
    });
    modalRef.componentInstance.inscl = {...item}
    modalRef.componentInstance.prefix = prefix
    from(modalRef.result).pipe(filter(e => !!e))
      .subscribe(e => {
        this.search(1)
      });
  }
}
