import {Component, Injectable, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmDialogService} from "../../../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {ToastService} from "../../../../../shared/commons/toast/toast-service";
import {InsclDto} from "../../../../../../generated-model/model";
import {InsclResource} from "../../inscl-resource.service";
import {filter} from "rxjs/operators";
import {FormsModule, NgForm} from "@angular/forms";
import {updateFormAndValidity} from "../../../../../app.constants";
import {CustomErrorComponent} from "../../../../../shared/commons/custom-error.component";
import {NgIf} from "@angular/common";

@Injectable({providedIn: 'root'})
@Component({
    selector: 'app-range-sub-inscl-type-modal',
    standalone: true,
  imports: [
    FormsModule,
    CustomErrorComponent,
    NgIf
  ],
    templateUrl: './range-sub-inscl-type-modal.component.html',
    styleUrl: './range-sub-inscl-type-modal.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class RangeSubInsclTypeModalComponent {
  @ViewChild('form') ngForm : NgForm
    prefix = ''
    inscl: InsclDto = {}

    constructor(public activeModal: NgbActiveModal,
                private confirmDialogService: ConfirmDialogService,
                private insclTypeService: InsclResource,
                private toastService: ToastService,
    ) {

    }

    save() {
    let valid = updateFormAndValidity(this.ngForm)
      if(!valid) {
        this.toastService.showWarning('กรุณากรอกข้อมูลให้ครบถ้วน')
      } else {
        this.confirmDialogService.open({content: 'ยืนยันการบันทึกข้อมูล'})
          .pipe(filter(e => !!e))
          .subscribe({
            next: e => {
              this.insclTypeService.save(this.inscl).subscribe({
                next: e => {
                  this.activeModal.close(e)
                  this.toastService.showSuccess(this.prefix + 'ข้อมูลสำเร็จ')
                }
              })
            }
          });
      }

    }
}
