import {
  Component,
  EventEmitter,
  forwardRef,
  inject,
  input,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild
} from '@angular/core';
import {debounceTime, finalize, Observable, of, Subject} from "rxjs";
import {FormsModule, NG_VALUE_ACCESSOR} from "@angular/forms";
import {map} from "rxjs/operators";
import {NgOptionTemplateDirective, NgSelectComponent} from "@ng-select/ng-select";
import {InsclResource} from "../../../pages/master/sub-inscl/inscl-resource.service";
import {AsyncPipe, CommonModule} from "@angular/common";
import {InsclDto} from "../../../../generated-model/model";
@Component({
  selector: 'app-inscl-autocomplete',
  standalone: true,
  imports: [
    NgSelectComponent,
    FormsModule,
    AsyncPipe,
    NgOptionTemplateDirective,
    CommonModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InsclAutocompleteComponent),
      multi: true
    }
    ],
  templateUrl: './inscl-autocomplete.component.html',
  styleUrl: './inscl-autocomplete.component.scss'
})
export class InsclAutocompleteComponent implements OnInit{

  id = input<string>('inscl');
  name = input<string>('inscl');
  placeholder = input<string>('กรุณาเลือก');
  disabled = input<boolean>(false);
  @Input() preSelectedValue: string;
  @Input() error?:  string;

  value: InsclDto = undefined
  insclList$: Observable<InsclDto[]> = of([]);
  typeaheadLoading = false;
  term$ = new Subject<string>();
  touched = false;

  private insclService = inject(InsclResource);

  @ViewChild('ngSelectComponent', {static: true})
  ngSelectComponent!: NgSelectComponent;


  onChange = (value: InsclDto | null) => {};
  onTouched = () => {};

  compareWithInscl = (item1: InsclDto, item2: InsclDto): boolean => {
    return item1?.inscl === item2?.inscl;
  };


  ngOnInit(): void {
    this.term$.pipe(debounceTime(300))
      .subscribe(term => {
        this.typeaheadLoading = true;
        this.insclList$ = this.insclService.searchAuto(term).pipe(
          map(result => {
            const nullOption: InsclDto = {
              inscl: 'NULL',
              insclName: 'NULL',
              codeWithName: 'NULL'
            } as InsclDto;
            return [nullOption, ...result];
          }),
          finalize(() => this.typeaheadLoading = false)
        );
      });
    this.term$.next('')
  }

  writeValue(value: InsclDto | null): void {
    this.value = value;
  }

  registerOnChange(fn: (value: InsclDto | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onValueChange(value: InsclDto | null) {
    this.value = value;
    console.log(value)
    this.onChange(value);
    this.markAsTouched();
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }


  reset() {
    this.value = null;
    this.onValueChange(null);
    if (this.ngSelectComponent) {
      this.ngSelectComponent.searchTerm = null;
      this.term$.next('')
      this.ngSelectComponent.clearModel();
    }
  }

}

