<div class="card custom-box my-3 border-0">
  <form (ngSubmit)="search()">
    <div class="card-body">
      <div class="row mb-3">
        <label for="zone" class="col-xl-2 col-form-label ">เขต</label>
        <div class="col-xl-3">
          <select class="form-control col-lg-5" id="zone" name="zone">
            <option [ngValue]="undefined">ทั้งหมด</option>
          </select>
        </div>
        <label for="province" class="col-xl-2 col-form-label ">จังหวัด</label>
        <div class="col-xl-3">
          <select class="form-control col-lg-5" id="province" name="province">
            <option [ngValue]="undefined">ทั้งหมด</option>
          </select>
        </div>
      </div>
      <div class="row mb-3">
        <label for="inputHcode" class="col-xl-2 col-form-label ">รหัสหน่วยรับคำขอ</label>
        <div class="col-xl-3">
          <input type="text" class="form-control" id="inputHcode" name="inputHcode">
        </div>
        <label for="inputHname" class="col-xl-2 col-form-label ">ชื่อหน่วยรับคำขอ</label>
        <div class="col-xl-3">
          <input type="text" class="form-control" id="inputHname" name="inputHname">
        </div>
      </div>
      <div class="row mb-3">
        <label for="type" class="col-xl-2 col-form-label ">ประเภทหน่วยรับคำขอ</label>
        <div class="col-xl-3">
          <select class="form-control col-lg-5" id="type" name="type">
            <option [ngValue]="undefined">ทั้งหมด</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="text-center">
          <button type="submit" class="btn btn-primary me-2">
            <i class="bi bi-search me-1"></i>
            ค้นหา
          </button>
          <button type="reset" class="btn btn-default me-2">
            เคลียร์ข้อมูล
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="card custom-box mb-3 border-0">
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-striped table-borderless caption-top">
        <caption>
          แสดง {{ (pageData?.content?.length ? (pageData?.number * pageData?.size) + 1 : 0) | number }}
          ถึง {{ ((pageData?.number) * pageData?.size + pageData?.content?.length) | number }}
          จากทั้งหมด {{ pageData?.totalElements | number }} รายการ
          <button class="btn btn-info float-end" (click)="openModelAdd('เพิ่ม')">
            <i class="bi bi-plus-lg me-1"></i>
            เพิ่มข้อมูล
          </button>
        </caption>
        <thead class="color-black font-weight-light">
        <tr class="table-light text-center" style="background-color: #eeeeee;">
          <th nowrap scope="col" class="">รหัสหน่วยรับคำขอ</th>
          <th nowrap scope="col" class="">ชื่อหน่วยรับคำขอ</th>
          <th nowrap scope="col" class="">เขต</th>
          <th nowrap scope="col" class="">จังหวัด</th>
          <th nowrap scope="col" class="">วันที่บันทึก</th>
          <th nowrap scope="col" class="">ผู้บันทึกข้อมูล</th>
          <th nowrap scope="col" class="">ดำเนินการ</th>
        </tr>
        </thead>
        <tbody class="table-borderless">
        <tr *ngIf="!pageData?.content?.length">
          <td class="text-muted text-center" colspan="7">
            <app-not-found></app-not-found>
          </td>
        </tr>
        <tr *ngFor="let item of pageData?.content; let i= index">
          <td class="text-center"></td>
          <td class="text-center"></td>
          <td class="text-center"></td>
          <td class="text-center"></td>
          <td class="text-center"></td>
          <td class="text-center"></td>
          <td class="text-center">
            <button class="btn btn-default">
              <i class="bi bi-pencil"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <ngb-pagination class="d-flex justify-content-center"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    [page]="pageData?.number + 1"
                    (pageChange)="search($event)"
                    [pageSize]="pageData?.size"
                    [collectionSize]="pageData?.totalElements"></ngb-pagination>
  </div>
</div>
