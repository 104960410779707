<div class="modal-header">
  <strong class="text-primary bold fs-5 d-inline-block">{{ prefix }}ข้อมูลหน่วยบริการที่ลงทะเบียนแบบกลุ่ม</strong>
</div>
<div class="modal-body">
  <div class="row mb-3">
    <label for="zone" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">เขต</label>
    <div class="col-xl-3">
      <select class="form-control col-lg-5" id="zone" name="zone">
        <option [ngValue]="undefined">กรุณาเลือก</option>
      </select>
    </div>
    <label for="province" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">จังหวัด</label>
    <div class="col-xl-3">
      <select class="form-control col-lg-5" id="province" name="province">
        <option [ngValue]="undefined">กรุณาเลือก</option>
      </select>
    </div>
  </div>
  <div class="row mb-3">
    <label for="hcode" class="col-xl-2 ps-xl-4 col-form-label ui-require text-end">หน่วยบริการ</label>
    <div class="col-xl-3">
      <input type="text" class="form-control" id="hcode" name="hcode">
    </div>
    <label class="col-xl-2 col-form-label ui-require text-end">ประเภทหน่วยบริการ</label>
    <div class="col-xl-3">
      <select class="form-control col-lg-5">
        <option [ngValue]="undefined">ทั้งหมด</option>
        <option [ngValue]="undefined">หน่วยบริการ</option>
        <option [ngValue]="undefined">หน่วยงานของรัฐ</option>
        <option [ngValue]="undefined">หน่วยงานอื่นๆ</option>
      </select>
    </div>
  </div>
</div>
<div class="row mt-3 mb-3">
  <div class="col-12 text-end">
    <button type="button" class="btn btn-primary custom-button mx-1 btn-main confirm-btn" (click)="save()">
      {{ prefix }}ข้อมูล
    </button>
    <button type="button" class="btn btn-default custom-button mx-1 btn-main confirm-btn" (click)="activeModal.close()">
      ปิด
    </button>
  </div>
</div>
