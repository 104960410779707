import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  effect,
  HostListener,
  inject,
  OnChanges, OnInit,
  Renderer2,
  signal,
  SimpleChanges
} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router, RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";
import {AuthenService} from "../../security/authen.service";
import {CommonModule, NgStyle} from "@angular/common";
import {FeatherModule} from "angular-feather";
import {LoadingComponent} from "../../shared/commons/loader/loading.component";
import {notImplementedYet} from "../../app.constants";
import {FormsModule} from "@angular/forms";
import {SharedCommonModule} from "../../shared/commons/shared-common.module";
import {environment} from "../../../environments/environment";
import {UserData} from "../../../generated-model/model";

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterLink,
    RouterOutlet,
    NgStyle,
    FeatherModule,
    RouterLinkActive,
    SharedCommonModule,
    LoadingComponent
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent implements OnInit, AfterViewInit, AfterViewChecked, OnChanges {
  environment = environment
  principal?: (UserData & { name?: string });
  private http = inject(HttpClient);
  private router = inject(Router);
  authenService = inject(AuthenService);
  renderer = inject(Renderer2);
  toggleSidebar = signal(true);
  protected readonly notImplementedYet = notImplementedYet;
  selectedFontSize: '14px' | '16px' | '18px' = '14px';

  constructor() {
    effect(() => {
      console.log(`toggleSidebar is : ${this.toggleSidebar()}`);
      this.calculateLayoutRatio();
    });
  }

  ngOnInit(): void {
    this.authenService.user$()
      .subscribe((value: any) => {
        this.principal = value;
      });
  }

  ngAfterViewInit(): void {
    this.calculateLayoutRatio();
    setTimeout(() => {
      //this.checkActiveDropdownMenu();
    });
  }

  ngAfterViewChecked(): void {
    // this.checkActiveDropdownMenu();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes)
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    // 1024px is break point of large devices
    console.log('window:resize');
    this.toggleSidebar.set(event.target.innerWidth < 1024);
    this.calculateLayoutRatio();
  }

  calculateLayoutRatio() {
    // let sidebar = document.getElementById("sidebar");
    // let content = document.getElementById("content");
    // if (this.toggleSidebar()) {
    //   content.style.width = `calc(100% - 300px);`;
    // } else {
    //   content.style.width = '100%;'
    // }
  }

  logout() {
    this.authenService.logout();
    // this.http.post(`${environment.serverUrl}/logout`, {})
    //   .subscribe();
  }

  toHome() {
    this.router.navigate(["/master/landing"])
  }

  sidebarToggle() {
    this.toggleSidebar.set(!this.toggleSidebar());
  }

  setFontSizeTo(fontSize: string) {
    this.renderer.setAttribute(document.querySelector('body'), 'data-default-font-size', fontSize);
    // document.querySelectorAll('[id^=defaultFontSize]')
    //   .forEach((value, key) => {
    //     if (value.id.endsWith(fontSize)) {
    //       this.renderer.addClass(value, 'active');
    //     } else {
    //       this.renderer.removeClass(value, 'active');
    //     }
    //   });
  }

  private checkActiveDropdownMenu() {
    let list = document.querySelectorAll('[data-bs-toggle="collapse"]');
    list.forEach((toggler, key) => {
      let dataBsTarget = toggler.getAttribute('data-bs-target');
      let collapsedElement = document.querySelector(`${dataBsTarget}`);
      let childrenActiveList = document.querySelectorAll(`${dataBsTarget} a.active`);
      if (childrenActiveList.length) {
        // toggler.classList.add('active');
        toggler.classList.add('collapsed');
        collapsedElement.classList.add('show');
        toggler.setAttribute('aria-expanded', 'true');
      } else {
        // toggler.classList.remove('active');
        toggler.classList.remove('collapsed');
        collapsedElement.classList.remove('show');
        toggler.setAttribute('aria-expanded', 'false');
      }
    });
  }

  isEnable(item: string): boolean {
    return environment.enable.some(e => e === item)
  }
}
