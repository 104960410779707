<div class="modal-header">
  <strong class="text-primary bold fs-5 d-inline-block">{{prefix}} ข้อมูลจำนวนครั้งในการลงทะเบียน (Count Select)</strong>
</div>
<div class="modal-body">
  <div class="row mb-3">
    <div class="row mb-3">
      <label for="pid" class="col-xl-2 col-form-label ui-require text-end">เลขประจำตัวประชาชน</label>
      <div class="col-xl-3">
        <input type="text" class="form-control" id="pid" name="pid">
      </div>
      <div class="col-xl-3">
        <button type="button" class="btn btn-primary custom-button mx-1 btn-main">
          ค้นหา
        </button>
      </div>
      <div class="col-xl-3">
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <label for="firstName" class="col-xl-2 col-form-label ui-require text-end">ชื่อ</label>
    <div class="col-xl-3">
      <input type="text" class="form-control" id="firstName" name="firstName">
    </div>
    <label for="lastName" class="col-xl-2 col-form-label ui-require text-end">นามสกุล</label>
    <div class="col-xl-3">
      <input type="text" class="form-control" id="lastName" name="lastName">
    </div>
  </div>
  <div class="row mb-3">
    <label for="amount" class="col-xl-2 col-form-label ui-require text-end">จำนวนครั้งในการลงทะเบียนต่อปี</label>
    <div class="col-xl-3">
      <input type="text" class="form-control" id="amount" name="amount">
    </div>
  </div>
</div>
<div class="row mt-3 mb-3">
  <div class="col-12 text-end">
    <button type="button" class="btn btn-primary custom-button mx-1 btn-main confirm-btn" (click)="save()">
      {{prefix}}
    </button>
    <button type="button" class="btn btn-default custom-button mx-1 btn-main confirm-btn" (click)="activeModal.close()">
      ปิด
    </button>
  </div>
</div>
