import {Component, inject, OnInit, signal} from '@angular/core';
import {RelationResource} from "./relation-resource";
import {Page, RelationDto, RelationSearchParam, Used} from "../../../../generated-model/model";
import {DecimalPipe} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../../shared/not-found/not-found.component";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";
import {from} from "rxjs";
import {filter} from "rxjs/operators";
import {RelationModalComponent} from "./modal/relation-modal.component";

@Component({
  selector: 'app-relation',
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    NgbPagination,
    NotFoundComponent
  ],
  templateUrl: './relation.component.html',
  styleUrl: './relation.component.scss'
})
export class RelationComponent implements OnInit {

  pageData = signal<Page<RelationDto>>({size: 10});
  private relationService = inject(RelationResource);
  private modalService = inject(NgbModal);
  protected readonly Used = Used;
  searchParam: RelationSearchParam = {}

  ngOnInit(): void {
    this.search()
  }

  search(page: number = 1) {
    let param = IgnoreNullHttpParams.fromObject(
      {...this.searchParam})
      .set("page", page - 1)
      .set("size", this.pageData().size)
      .set('sort', 'id')
    this.relationService.search(param).subscribe({
        next: e => {
          this.pageData.set(e)
          console.log('pageData', this.pageData())
        }
      }
    )
  }


  addOrEditItem(prefix: string, item: RelationDto) {
    const modalRef = this.modalService.open(RelationModalComponent, {
      size: 'lg',
      centered: true
    });
    modalRef.componentInstance.item = signal({...item})
    modalRef.componentInstance.prefix = prefix
    from(modalRef.result).pipe(filter(e => !!e))
      .subscribe(e => {
        this.search(1)
      });

  }
}
