import {Component, ViewEncapsulation} from '@angular/core';
import {DepartmentNewDto} from "../../../../../../generated-model/model";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmDialogService} from "../../../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {ToastService} from "../../../../../shared/commons/toast/toast-service";
import {filter} from "rxjs/operators";
import {ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'app-department-new-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './department-new-modal.component.html',
  styleUrl: './department-new-modal.component.scss',
  encapsulation:ViewEncapsulation.None
})
export class DepartmentNewModalComponent {
  prefix = '';
  departmentNew: DepartmentNewDto = {}

  constructor(public activeModal: NgbActiveModal,
              private confirmDialogService: ConfirmDialogService,
              private toastService: ToastService,
  ) {
  }

  save() {
    this.confirmDialogService.open({content: 'ยืนยันการบันทึกข้อมูล'})
      .pipe(filter(e => !!e))
      .subscribe({
        next: e => {
        }
      });
  }
}
